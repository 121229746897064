import React from 'react'
import { useSearchParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

function ResetFiltersBtn({ignoreParams}) {
    const [searchParams, setSearchParams] = useSearchParams();

    // Check if there are any parameters to be ignored dynamically
    const hasOtherParams = Array.from(searchParams.keys()).some(
      (key) => !ignoreParams.includes(key)
    );

    const handleResetNavigation = () => {
        const retainedParams = {};
        // Retain only parameters specified in ignoreParams
        ignoreParams.forEach((param) => {
          const values = searchParams.getAll(param);
          if (values.length) {
            retainedParams[param] = values;
          }
        });
    
        // Update the URL with only the retained parameters
        setSearchParams(retainedParams);
      };


  return (
    <>
    {hasOtherParams && (
        <motion.button 
            whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}
            onClick={handleResetNavigation}
            className='text-sm italic px-2 text-blue-500 dark:text-blue-400 hover:text-blue-600 dark:hover:text-blue-500 cursor-pointer'
            >
                Reset Filters
        </motion.button>
    )}
  </>
  )
}

export default ResetFiltersBtn