import React from 'react'
import OptimizePartImage from '../../components/OptimizePartImage';
import moment from "moment"
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { Avatar } from '@mui/material';

function DowntimeTableRow({el}) {
    const isDark = useRecoilValue(darkModeState)

    const handleDateFormat = (timestamp) => {
        if(timestamp){
            var currentDate = new Date(timestamp)
            var currentHours = currentDate.getHours();
            var currentMinutes = currentDate.getMinutes();
            var currentAmPm = currentHours >= 12 ? 'pm' : 'am';
            currentHours = currentHours % 12;
            currentHours = currentHours ? currentHours : 12; // the hour '0' should be '12'
            currentMinutes = currentMinutes < 10 ? '0'+currentMinutes : currentMinutes;
            var currentStrTime = currentHours + ':' + currentMinutes + '' + currentAmPm;
            var shortcurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2)
            // var currentTime = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2) + " " + currentStrTime;
            // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            return shortcurrentDate + "  " + currentStrTime   ;
        }
    }

    const formatTime = (seconds) => {
        if(seconds){
          let formatted = moment.utc(seconds*1000).format('HH:mm:ss.SS')
          return formatted
        }else{
            let formatted = moment.utc(0).format('HH:mm:ss.SS')
            return formatted
        }
    }

    const badgeStyle = (severity) => { 
        if(severity === 'None'){
            return{
                backgroundColor: isDark  ? 'rgba(212, 212, 212,0.3)' : 'rgba(212, 212, 212,0.5)',
                color: isDark  ? '#d4d4d4' : '#737373',
              }
        }
        if(severity === 'Low'){
            return{
                backgroundColor: isDark  ? 'rgba(134, 239, 172,0.5)' : 'rgba(134, 239, 172,0.5)',
                color: isDark  ? '#86efac' : '#16a34a',
              }
        }
        if(severity === 'Medium'){
            return{
                backgroundColor: isDark  ? 'rgba(253, 230, 138,0.3)' : 'rgba(253, 230, 138,0.5)',
                color: isDark  ? '#fcd34d' : '#d97706',
              }
        }
        if(severity === 'High'){
            return{
                backgroundColor: isDark  ? 'rgba(252, 165, 165,0.3)' : 'rgba(252, 165, 165,0.3)',
                color: isDark  ? '#fca5a5' : '#ef4444',
              }
        }else{
            return{
                backgroundColor: isDark  ? 'rgba(252, 165, 165,0.3)' : 'rgba(252, 165, 165,0.3)',
                color: isDark  ? '#fca5a5' : '#ef4444',
              }
        }
        
      }


  return (
    <tr className='border-b-[0.01em] dark:border-slate-700 ' >
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3'>
            <div className='flex items-center'>
                <div className='w-10 mr-2'>
                    {el && Object.keys(el).length > 0 && el.machine_picture_url && el.machine_picture_url.length > 0 && 
                    <OptimizePartImage alt={el.machine_name} source={el.asset_custom_picture_url  ? el.asset_custom_picture_url : el.machine_picture_url} size='medium' />

                    }
                </div>
                <div className='flex flex-col'>
                    <span>{el.asset_custom_name  ? el.asset_custom_name :el.machine_name}</span>
                    <span className='text-gray-500 dark:text-gray-400'>{el.asset_custom_identifier  ? el.asset_custom_identifier :el.asset_uuid}</span>
                </div>
            </div>
        </td>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3'>
            <div className='flex flex-col'>
                <div>
                    {`${formatTime(el.downtime_duration_seconds)}`}
                </div>
                <div className='text-gray-500 dark:text-gray-400'>
                    {`(${handleDateFormat(el.utilization_start_timestamp)} -> ${handleDateFormat(el.utilization_end_timestamp)})`}
                </div>
            </div>
        </td>
        {/* <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3'>
            {handleDateFormat(el.utilization_end_timestamp)}
        </td>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 text-right'>
            {formatTime(el.downtime_duration_seconds)}
        </td> */}
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 '>
            {el.downtime_category_name}
        </td>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 '>
            {el.downtime_classified_by && el.downtime_classified_by[0] && (
                <div className='flex items-center'>
                <div className='mr-3'>
                    <Avatar
                        sx={{ 
                            bgcolor: el.downtime_classified_by[0].user_color? el.downtime_classified_by[0].user_color : '' , 
                            fontSize:'12px',
                            width: 32, 
                            height: 32,
                        }}
                        style={{
                            background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${el.downtime_classified_by[0].user_color? el.downtime_classified_by[0].user_color:''} 100%)`,
                        }}
                        alt={el.downtime_classified_by[0].user_first_name ? el.downtime_classified_by[0].user_first_name : '' }
                        src={el.downtime_classified_by[0].user_image_url ? el.downtime_classified_by[0].user_image_url : '/broken-image.jpg' }
                    />
                </div>
                <div className='flex flex-col '>
                    <span className='text-sm'>
                        {el.downtime_classified_by[0].user_first_name ? el.downtime_classified_by[0].user_first_name : '' } {' '} {el.downtime_classified_by[0].user_last_name ? el.downtime_classified_by[0].user_last_name : '' }
                    </span>
                    <span className='text-gray-500 dark:text-gray-400 text-xs'>
                    {el.downtime_classified_by[0].user_username_public ? el.downtime_classified_by[0].user_username_public : el.downtime_classified_by[0].user_username_public }
                    </span>

                </div>

            </div>
            )}
        </td>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 '>
            {el.utilization_downtime_note}
        </td>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 text-center'>
            <div className='py-0 text-sm rounded w-full px-2' style={badgeStyle(el.downtime_severity_name)}>{el.downtime_severity_name}</div>
        </td>

    </tr>
  )
}

export default DowntimeTableRow