import React, { useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon, PlusSmIcon } from "@heroicons/react/solid"
import MobileRightDrawer from '../components/MobileRightDrawer'
import { useLocation, useSearchParams } from 'react-router-dom'
import WorkOrderViewSelector from '../components/filterButtons/WorkOrderFilters/WorkOrderViewSelector'
import { motion, AnimatePresence } from 'framer-motion'
import WorkOrderTableView from './tableViewComponents/WorkOrderTableView'
import { globalSelectedOrganization } from '../atoms/orgStates'
import { getData, putData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import { useRecoilValue } from 'recoil'
import NewWorkOrderModal from '../components/modals/workOrderModals/NewWorkOrderModal'
import SelectedWorkOrderPage from './SelectedWorkOrderPage'
import WOCalendarView from './calendarViewComponents/WOCalendarView'
import WOToDoView from './WOToDoView'
import SearchTextFilter from '../components/filterButtons/SearchTextFilter'
import SortByWOFilterBtn from '../components/filterButtons/WorkOrderFilters/SortByWOFilterBtn'
import PriorityWOFilterBtn from '../components/filterButtons/WorkOrderFilters/PriorityWOFilterBtn'
import StatusWOFilterBtn from '../components/filterButtons/WorkOrderFilters/StatusWOFilterBtn'
import SearchQFilter from '../components/filterButtons/SearchQFilter'
import DateRangeFilterWOBtn from '../components/filterButtons/WorkOrderFilters/DateRangeFilterWOBtn'
import WOKanbanView from './kanbanViewComponents/WOKanbanView'
import AssignedWOFilterBtn from '../components/filterButtons/WorkOrderFilters/AssignedWOFilterBtn'
import ResetFiltersBtn from '../components/filterButtons/ResetFiltersBtn'
import { isDatePastDue } from '../utils/DateFormating'
import WOKpis from './WOKpis'
import GroupsFilterBtn from '../components/filterButtons/WorkOrderFilters/GroupsFilterBtn'
import WORoadmapView from './roadmapViewComponents/WORoadmapView'
import '../styles/globals.css'
import {Resizable} from 'react-resizable';
import RightDrawerSelectedWOPage from './RightDrawerSelectedWOPage'
import { darkModeState } from '../atoms/darkModeState'


function WorkOrdersPage({mqttMessage, userInfo}) {
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)
    const [currentView, setCurrentView] = useState(null)
    const [selectedWorkOrderUuid, setSelectedWorkOrderUuid] = useState('')
    const location = useLocation();
    const [allWorkOrdersList, setAllWorkOrdersList] = useState([])
    const [allWorkOrdersDoneList, setAllWorkOrdersDoneList] = useState([])
    const [workOrdersList, setWorkOrdersList] = useState([])
    const [workOrdersDoneList, setWorkOrdersDoneList] = useState([])
    const [isAddingColumn, setIsAddingColumn] = useState(false);
    const [isManagingColumns, setIsManagingColumns] = useState(false);
    const [filterAssignees, setFilterAssignees] = useState([]);
    const [kpiMetrics, setKpiMetrics] = useState([]);
    const [prioritiesList, setPrioritiesList] = useState([]);


    
    const [isOpen, setIsOpen] = useState(false); // State to control visibility
   

    
    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        let selected_workOrder_uuid = searchParams.get('selected_wo')
        setSelectedWorkOrderUuid(selected_workOrder_uuid)
        
        if(selected_workOrder_uuid && selected_workOrder_uuid.length > 0){
            setIsOpen(true)
        }else{
            setIsOpen(false)
        }


        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      },[location])

      useEffect(() => {
        getData(apiLink + '/v1/workorder_priorities' )
        .then(async response => {
            // JSON data parsed by response.json() call
            if(response.ok ){
            const data = await response.json();
                if(data && data.data){
                  // console.log('Priority',data.data)
                  setPrioritiesList(data.data)
                }
            }
        })
      },[])


    useEffect(() => {
        if(selectedOrganization){
            // let selectedWorkOrder = searchParams.get('selected_wo')
            // if(selectedWorkOrder) return
            getWorkOrders()
        }
    }, [selectedOrganization, location])

    useEffect(() => {

        if(filterAssignees && Object.keys(filterAssignees).length > 0){
            let filteredSections = []

            allWorkOrdersList.map(section => {
                let isMatching = filterAssignees.find((assignee) => assignee.workorder_section_id === section.workorder_section_id)
                if(isMatching && Object.keys(isMatching).length > 0){
                    filteredSections.push(section)
                }
            })
            setWorkOrdersList(filteredSections)


            //Done Work Order
            let filteredDoneSections = []
            allWorkOrdersDoneList.map(section => {
                let isMatching = filterAssignees.find((assignee) => assignee.workorder_section_id === section.workorder_section_id)
                if(isMatching && Object.keys(isMatching).length > 0){
                    filteredDoneSections.push(section)
                }
            })
            setWorkOrdersDoneList(filteredDoneSections)


        }else{
            setWorkOrdersList(allWorkOrdersList)
            setWorkOrdersDoneList(allWorkOrdersDoneList)
        }
    },[filterAssignees])

    const getWorkOrders = () => {

        if(selectedOrganization){
            // console.log('GET WORK ORDERS')
            let currentSearch = location.search
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/workorders' + currentSearch)
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            // console.log('Workorders JSON.stringify', JSON.stringify(data.data[0].workorders.slice(0,1)))
                            console.log('Workorders ', data.data)
                            setAllWorkOrdersList(data.data)
                            // setWorkOrdersList(data.data)
                            

                            let allWorkOrders = []
                            if(filterAssignees && Object.keys(filterAssignees).length > 0){
                                let filteredSections = []
                    
                                data.data.map(section => {
                                    let isMatching = filterAssignees.find((assignee) => assignee.workorder_section_id === section.workorder_section_id)
                                    if(isMatching && Object.keys(isMatching).length > 0){
                                        filteredSections.push(section)
                                    }
                                })
                                setWorkOrdersList(filteredSections)

                                filteredSections.map(section => {
                                    allWorkOrders.push(section.workorders)
                                })
                    
                            }else{
                                setWorkOrdersList(data.data)
                                data.data.map(section => {
                                    allWorkOrders.push(section.workorders)
                                })
                            }
                            
                            const combinedArray = allWorkOrders.flat();
                            const metrics = calculateMetrics(combinedArray);
                            setKpiMetrics(metrics)
                            // console.log('metrics', metrics)
                            
                        }else{
                            setAllWorkOrdersList([])
                            setWorkOrdersList([])
                            setKpiMetrics([])
                        }
                    }else{
                        setAllWorkOrdersList([])
                        setWorkOrdersList([])
                        setKpiMetrics([])
                    }
                })
            let doneSearch = '?show_done_only=true'
            if(currentSearch && currentSearch.length > 0){
                doneSearch = currentSearch + '&show_done_only=true'
            }
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/workorders' + doneSearch )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            // console.log('Workorders JSON.stringify', JSON.stringify(data.data[0].workorders.slice(0,3)))
                            // console.log('DONE Workorders ', data.data)
                            // setWorkOrdersDoneList(data.data)
                            setAllWorkOrdersDoneList(data.data)

                            if(filterAssignees && Object.keys(filterAssignees).length > 0){
                                let filteredSections = []
                    
                                data.data.map(section => {
                                    let isMatching = filterAssignees.find((assignee) => assignee.workorder_section_id === section.workorder_section_id)
                                    if(isMatching && Object.keys(isMatching).length > 0){
                                        filteredSections.push(section)
                                    }
                                })
                                setWorkOrdersDoneList(filteredSections)
                    
                            }else{
                                setWorkOrdersDoneList(data.data)
                            }
                            
                        }else{
                            setWorkOrdersDoneList([])
                            setAllWorkOrdersDoneList([])
                        }
                    }else{
                        setWorkOrdersDoneList([])
                        setAllWorkOrdersDoneList([])
                    }
                })
        }

    }


    const calculateMetrics = (workOrders) => {
        const metrics = {
          statuses: { Open: 0, "In Progress": 0, "On Hold": 0, Done: 0 },
          priorities: { None: 0, Low: 0, Medium: 0, High: 0 },
          overdue: { onTime: 0, overdue: 0 },
          repeating: { repeating: 0, repeatingOnTime: 0, repeatingOverdue: 0 },
          nonRepeating: {  nonrepeating: 0, nonrepeatingOnTime: 0, nonrepeatingOverdue: 0 },
        };
      
        workOrders.forEach((order) => {
            // console.log('order', order)
          // Status
          const status = order.workorder_status?.workorder_status_name;
          if (status && metrics.statuses[status] !== undefined) {
            metrics.statuses[status]++;
          }
      
          // Priority
          const priority = order.workorder_priority?.workorder_priority_name;
          if (priority && metrics.priorities[priority] !== undefined) {
            metrics.priorities[priority]++;
          } else if (!priority) {
            metrics.priorities.None++;
          }
      
          // Overdue
          if (order.workorder_due_timestamp) {
            if (!isDatePastDue(order.workorder_due_timestamp)) {
              metrics.overdue.onTime++;
            } else {
              metrics.overdue.overdue++;
            }
          }
      
          // Repeating and Non-Repeating
          if (order.workorder_recurrence_description) {
            metrics.repeating.repeating++;
            if (!isDatePastDue(order.workorder_due_timestamp)) {
                metrics.repeating.repeatingOnTime++;
            }else {
                metrics.repeating.repeatingOverdue++;
            }
          }else{
            metrics.nonRepeating.nonrepeating++;
            if (!isDatePastDue(order.workorder_due_timestamp)) {
                metrics.nonRepeating.nonrepeatingOnTime++;
            }else {
                metrics.nonRepeating.nonrepeatingOverdue++;
            }
          }
        });
      
        // Calculate percentages
        const totalDueDate = metrics.overdue.onTime + metrics.overdue.overdue;
        const onTimePercent = totalDueDate > 0 ? (metrics.overdue.onTime / totalDueDate) * 100 : 0;

        const totalRepeating = metrics.repeating.repeatingOnTime + metrics.repeating.repeatingOverdue;
        const repeatingPercent = totalRepeating > 0 ? (metrics.repeating.repeatingOnTime / totalRepeating) * 100 : 0;

        const totalNonRepeating = metrics.nonRepeating.nonrepeatingOnTime + metrics.nonRepeating.nonrepeatingOverdue;
        const nonRepeatingPercent = totalNonRepeating > 0 ? (metrics.nonRepeating.nonrepeatingOnTime / totalNonRepeating) * 100 : 0;

        // console.log('onTimePercent', onTimePercent)
        // console.log('repeatingPercent', repeatingPercent)
        // console.log('nonRepeatingPercent', nonRepeatingPercent)
      
        return {
          ...metrics,
          percentages: {
            onTimePercent: onTimePercent.toFixed(1),
            repeatingPercent: repeatingPercent.toFixed(1),
            nonRepeatingPercent: nonRepeatingPercent.toFixed(1),
          },
        };
      };


    



  return (
    <div className={`${currentView == 'calendar_view'? 'mt-4':'pt-4'} pl-5 pr-5  ${isMobile ? 'h-[calc(100vh-0.5rem)]' : 'h-[calc(100vh-3rem)]'} overflow-scroll scrollbar-hide`}>
        <AnimatePresence mode='wait'>
                <motion.div 
                    key={'WorkOrderContainer'}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                
                <div className={` flex flex-col lg:flex-row lg:items-center justify-between mb-2 lg:space-x-2`}>
                    <div className='flex space-x-2'>
                        <StatusWOFilterBtn />
                        <PriorityWOFilterBtn />
                        <GroupsFilterBtn />
                        {currentView && currentView != 'calendar_view' && (
                            <SortByWOFilterBtn />
                        )}
                        <DateRangeFilterWOBtn />
                        <AssignedWOFilterBtn setFilterAssignees={setFilterAssignees} />
                        <ResetFiltersBtn ignoreParams={[]} />
                    </div>
                    <div className='flex space-x-2 mt-2 ml-auto lg:mt-0'>
                        <WorkOrderViewSelector setCurrentView={setCurrentView} />
                        <NewWorkOrderModal btnType='standardBtn' getWorkOrders={getWorkOrders} />
                    </div>
                </div>
                <div className='flex'>
                    <SearchQFilter />
                </div>
                {/* <div className='flex flex-col'>
                    <div className='my-1'>
                        <WOKpis kpiMetrics={kpiMetrics} prioritiesList={prioritiesList} />
                    </div>
                    {currentView ? (
                        <div className='mt-1'>
                            {showView(currentView, isMobile, selectedOrganization, workOrdersList, workOrdersDoneList, setWorkOrdersList, getWorkOrders, userInfo, mqttMessage, prioritiesList)}
                        </div>
                    ):(
                        <div />
                    )}
                </div> */}
                     {/* Main container with flex */}
                    <div className="flex w-full space-x-2 mt-0.5">
                        {/* Main content area */}
                        <div className={`${currentView != 'todo_view' ? "h-[calc(100vh-11rem)]" : ""} p-0.5  message_window_scrollbar ${isDark ? 'scrollbar_dark' : 'scrollbar_light'} transition-all duration-300 ${currentView != 'todo_view' && isOpen ? "w-[calc(100%-640px)]" : "w-full"} overflow-x-auto`}>
                            {/* <button
                                onClick={() => setIsOpen(!isOpen)}
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                            >
                                {isOpen ? 'Close Drawer' : 'Open Drawer'}
                            </button> */}
                            
                        {/* <div className='my-1'>
                            <WOKpis kpiMetrics={kpiMetrics} prioritiesList={prioritiesList} />
                        </div> */}
                            {currentView ? (
                                <div className=''>
                                    {showView(currentView, isMobile, selectedOrganization, workOrdersList, workOrdersDoneList, setWorkOrdersList, getWorkOrders, userInfo, mqttMessage, prioritiesList)}
                                </div>
                            ):(
                                <div />
                            )}
                        </div>

                        {/* Drawer */}
                        {currentView != 'todo_view' && isOpen && (
                        <div className={`flex-shrink-0 pl-1 py-2 h-[calc(100vh-11rem)] overflow-y-scroll animate-slide-in border-l border-slate-200 dark:border-slate-700 `}>
                            <div className='w-[640px]'>
                                <RightDrawerSelectedWOPage mqttMessage={mqttMessage} userInfo={userInfo} />
                            </div>
                        </div>
                        )}
                    </div>
                </motion.div>
        </AnimatePresence>
    </div>
    // <div className={`${currentView == 'calendar_view'? 'mt-4':'pt-4'} pl-5 pr-5  ${isMobile ? 'h-[calc(100vh-0.5rem)]' : 'h-[calc(100vh-3rem)]'} overflow-scroll scrollbar-hide`}>
    //     <AnimatePresence mode='wait'>
    //         {currentView != 'todo_view' && selectedWorkOrderUuid && selectedWorkOrderUuid.length > 0 ? (
    //             <motion.div 
    //                 key={'SelectedWorkOrderContainer'}
    //                 initial={{ y: 10, opacity: 0 }}
    //                 animate={{ y: 0, opacity: 1 }}
    //                 exit={{ y: -10, opacity: 0 }}
    //                 transition={{ duration: 0.2 }}
    //             >
    //               <SelectedWorkOrderPage mqttMessage={mqttMessage} userInfo={userInfo} />
    //           </motion.div>
    //         ): (
    //             <motion.div 
    //                 key={'WorkOrderContainer'}
    //                 initial={{ y: 10, opacity: 0 }}
    //                 animate={{ y: 0, opacity: 1 }}
    //                 exit={{ y: -10, opacity: 0 }}
    //                 transition={{ duration: 0.2 }}
    //             >
    //             {/* <div className={`${isMobile ? 'flex':'flex md:hidden'} justify-end mr-4`}>
    //                 <MobileRightDrawer >
    //                     <div className='flex flex-col space-y-3'>
    //                     </div>

    //                 </MobileRightDrawer>
    //             </div> */}
    //             {/* Filter section */}
    //             <div className={`${isMobile && 'hidden'} flex flex-col lg:flex-row lg:items-center justify-between mb-2 lg:space-x-2`}>
    //                 <div className='flex space-x-2'>
    //                     <StatusWOFilterBtn />
    //                     <PriorityWOFilterBtn />
    //                     <GroupsFilterBtn />
    //                     {currentView && currentView != 'calendar_view' && (
    //                         <SortByWOFilterBtn />
    //                     )}
    //                     <DateRangeFilterWOBtn />
    //                     <AssignedWOFilterBtn setFilterAssignees={setFilterAssignees} />
    //                     <ResetFiltersBtn ignoreParams={[]} />
    //                 </div>
    //                 <div className='flex space-x-2 mt-2 ml-auto lg:mt-0'>
    //                     <WorkOrderViewSelector setCurrentView={setCurrentView} />
    //                     <NewWorkOrderModal btnType='standardBtn' getWorkOrders={getWorkOrders} />
    //                 </div>
    //             </div>
    //             <div className='flex'>
    //                 <SearchQFilter />
    //             </div>
    //             <div className='my-1'>
    //                 <WOKpis kpiMetrics={kpiMetrics} prioritiesList={prioritiesList} />
    //             </div>
    //             {currentView ? (
    //                 <div className='mt-1'>
    //                     {showView(currentView, isMobile, selectedOrganization, workOrdersList, workOrdersDoneList, setWorkOrdersList, getWorkOrders, userInfo, mqttMessage, prioritiesList)}
    //                 </div>
    //             ):(
    //                 <div />
    //             )}
    //             </motion.div>
    //         )}
    //     </AnimatePresence>
    // </div>
  )
}

// Add custom animation
const style = document.createElement('style');
style.textContent = `
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  .animate-slide-in {
    animation: slideIn 0.3s ease-out forwards;
  }
`;
document.head.appendChild(style);

export default WorkOrdersPage


const showView = (view, isMobile, selectedOrganization, workOrdersList, workOrdersDoneList, setWorkOrdersList, getWorkOrders, userInfo, mqttMessage, prioritiesList) =>{
    const allLinks = {
        todo_view: { content: <WOToDoView isMobile={isMobile} selectedOrganization={selectedOrganization} workOrdersList={workOrdersList} workOrdersDoneList={workOrdersDoneList} setWorkOrdersList={setWorkOrdersList} getWorkOrders={getWorkOrders} userInfo={userInfo} mqttMessage={mqttMessage} />},
        table_view: { content: <WORoadmapView isMobile={isMobile} selectedOrganization={selectedOrganization} workOrdersList={workOrdersList} setWorkOrdersList={setWorkOrdersList} getWorkOrders={getWorkOrders}  /> },
        // table_view: { content: <WorkOrderTableView isMobile={isMobile} selectedOrganization={selectedOrganization} workOrdersList={workOrdersList} setWorkOrdersList={setWorkOrdersList}  /> },
        calendar_view: { content: <WOCalendarView isMobile={isMobile} selectedOrganization={selectedOrganization} workOrdersList={workOrdersList} getWorkOrders={getWorkOrders} />},
        kanban_view: { content: <WOKanbanView isMobile={isMobile} selectedOrganization={selectedOrganization} workOrdersList={workOrdersList} getWorkOrders={getWorkOrders} workOrdersDoneList={workOrdersDoneList} prioritiesList={prioritiesList} />},
        // workload_view: { content: <div>Workload </div>},
    };
    

    return ( 
        <AnimatePresence mode='wait'>
        <motion.div key={view}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className='pb-10'
        >
            {allLinks[view].content} 
        </motion.div>
      </AnimatePresence>
    )  
}