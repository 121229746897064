
import { useEffect, useState } from "react";
import { DateRangePicker, defaultStaticRanges, defaultInputRanges } from "react-date-range";
import { motion, AnimatePresence } from "framer-motion";
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfYear,
    startOfMonth,
    endOfMonth,
    endOfYear,
    addMonths,
    addYears,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
    parseISO, 
    format
  } from "date-fns";
import { useLocation, useSearchParams } from "react-router-dom";
import { globalSelectedOrganizationTimezone } from "../../../atoms/orgStates";
import { useRecoilValue } from "recoil";

const today = new Date();// Get the current UTC time

const localOffsetMin = today.getTimezoneOffset(); // Get the offset in minutes from local computer
const localOffsetHrs = localOffsetMin/60 // convert it to hours
const formattedLocalOffsetHrs = localOffsetHrs.toFixed(0).toString().padStart(2, '0'); // Insert 0 in beginning if needed


function DateRangeFilterWOBtn() {
    const selectedOrganizationTimeZone = useRecoilValue(globalSelectedOrganizationTimezone);
    const [showCalendar, setShowCalendar] = useState(false)
    const [startTempDate, setStartTempDate] = useState(null)
    const [endTempDate, setEndTempDate] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isMobile, setIsMobile] = useState(false)


    const selectionRange = {
        startDate: startTempDate,
        endDate: endTempDate,
        key:'selectionRange',
        background:['#f43f5e']
        
    }

    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }

    },[])

    
    useEffect(() => {
        // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch){

            let fromDate = searchParams.get('from')
            let parsedFromDate = ''
            if(fromDate && startDate != fromDate){
                // const dateSplitStart = fromDate.split("T")
                // const dateStringFormattedStart = `${dateSplitStart[0]}T${formattedLocalOffsetHrs}:00:00.999Z`
                // parsedFromDate = parseISO(dateStringFormattedStart)
                // console.log('dateStringFormattedStart', dateStringFormattedStart)
                // console.log('Url parsedFromDateISO', parsedFromDate)
                // setStartDate(dateStringFormattedStart)
                // setStartTempDate(parsedFromDate)
                parsedFromDate = parseISO(fromDate)
                setStartDate(fromDate)
                setStartTempDate(parsedFromDate)

                // parsedFromDate = parseISO(fromDate)
                // setStartDate(fromDate)
            }

            let toDate = searchParams.get('to')
            let parsedToDate = ''
            if(toDate && endDate != toDate){
                // const dateSplitEnd = toDate.split("T")
                // const dateStringFormattedEnd = `${dateSplitEnd[0]}T${formattedLocalOffsetHrs}:00:00.999Z`
                // parsedToDate = parseISO(dateStringFormattedEnd)
                // setEndDate(dateStringFormattedEnd)
                // setEndTempDate(parsedToDate)
                parsedToDate = parseISO(toDate)
                setEndDate(toDate)
                setEndTempDate(parsedToDate)

                // parsedToDate = parseISO(toDate);
                // setEndTempDate(parsedToDate)
                // setEndDate(toDate)
            }
        }else{
            setStartTempDate(null)
            setEndTempDate(null)
            setStartDate(null)
            setEndDate(null)
        }

    }, [location])


    const handleOpenCalendar = () => {
        setShowCalendar(!showCalendar)
    }


    const closeCalendar = () => {
        setShowCalendar(false)
    }

  

      

    const handleSelect = (ranges) => {
        const newStartDate = new Date(ranges.selectionRange.startDate);
        const newEndDate = new Date(ranges.selectionRange.endDate);
        newStartDate.setHours(0, 0, 0, 0)
        newEndDate.setHours(0, 0, 0, 0) // Setting this to start of day because Mahmoud is doing it from his side
        // newEndDate.setHours(23, 59, 59, 999)
        setStartTempDate(newStartDate)
        setEndTempDate(newEndDate)
    }

    const formatDate = (date) => {
        if(date){
            var currentDate = new Date(date)
            var shortCurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString()
            return shortCurrentDate;

        }
    }


    const handleClear = () => {
        setShowCalendar(false)

        setStartTempDate(null)
        setEndTempDate(null)
        setStartDate(null)
        setEndDate(null)

        let select = searchParams.getAll('select')
        let departments = searchParams.getAll('departments')
        let parts = searchParams.getAll('parts')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let shift_by_min = searchParams.getAll('shift_by_min')
        let maximize = searchParams.getAll('maximize')
        let view = searchParams.getAll('view')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let users = searchParams.getAll('users')
        let text = searchParams.getAll('text')
        let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')
        let only_with_notes = searchParams.getAll('only_with_notes')
        let group_by_advanced = searchParams.getAll('group_by_advanced')
        let machines = searchParams.getAll('machines')
        let sort_by_advanced = searchParams.getAll('sort_by_advanced')
        let show_percentage = searchParams.getAll('show_percentage')
        let exclude_none_in_oee = searchParams.getAll('exclude_none_in_oee')
        let operations = searchParams.getAll('operations')
        let exclude_unknown_in_oee = searchParams.getAll('exclude_unknown_in_oee')
        let time_period = searchParams.getAll('time_period')
        let q = searchParams.getAll('q')
        let selected_wo = searchParams.getAll('selected_wo')
        let wo_status = searchParams.getAll('wo_status')
        let wo_priority = searchParams.getAll('wo_priority')
        let wo_group = searchParams.getAll('wo_group')
        

        setSearchParams({select, departments, parts, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, shift_by_min, maximize, view, url_token, operations_expanded, assets_expanded, 
            platform, is_god_view_enabled, users, text, downtime_severity_uuids, only_with_notes, group_by_advanced, machines, sort_by_advanced, show_percentage, time_period, exclude_none_in_oee, operations, exclude_unknown_in_oee, q, selected_wo, wo_status, wo_priority, wo_group})
    }


    const handleApplyDates = () => {
        
        setShowCalendar(false)
        
        let fromParameter = ''
        let toParameter = ''
        let dateStart = new Date(startTempDate);
        let dateStartObject  = dateStart.toISOString()
        // let dateStartObject  = dateStart.toISOString().slice(0, 10)
        // let startDateString = `${dateStartObject}T00:00:00${selectedOrganizationTimeZone.value}`
        // fromParameter = startDateString
        fromParameter = dateStartObject

        let dateEnd = new Date(endTempDate);
        let dateEndObject  = dateEnd.toISOString()
        // let dateEndObject  = dateEnd.toISOString().slice(0, 10)
        // let endDateString = `${dateEndObject}T00:00:00${selectedOrganizationTimeZone.value}`
        // toParameter = endDateString
        toParameter = dateEndObject

        setStartDate(startTempDate)
        setEndDate(endTempDate)
    
       
        let select = searchParams.getAll('select')
        let departments = searchParams.getAll('departments')
        let parts = searchParams.getAll('parts')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let shift_by_min = searchParams.getAll('shift_by_min')
        let maximize = searchParams.getAll('maximize')
        let view = searchParams.getAll('view')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let users = searchParams.getAll('users')
        let text = searchParams.getAll('text')
        let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')
        let only_with_notes = searchParams.getAll('only_with_notes')
        let group_by_advanced = searchParams.getAll('group_by_advanced')
        let machines = searchParams.getAll('machines')
        let sort_by_advanced = searchParams.getAll('sort_by_advanced')
        let show_percentage = searchParams.getAll('show_percentage')
        let exclude_none_in_oee = searchParams.getAll('exclude_none_in_oee')
        let operations = searchParams.getAll('operations')
        let exclude_unknown_in_oee = searchParams.getAll('exclude_unknown_in_oee')
        let time_period = searchParams.getAll('time_period')
        let q = searchParams.getAll('q')
        let selected_wo = searchParams.getAll('selected_wo')
        let wo_status = searchParams.getAll('wo_status')
        let wo_priority = searchParams.getAll('wo_priority')
        let wo_group = searchParams.getAll('wo_group')

        setSearchParams({select, departments, parts, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, shift_by_min, maximize, view, url_token, operations_expanded, assets_expanded, 
            platform, is_god_view_enabled, users, text, downtime_severity_uuids, only_with_notes, group_by_advanced, machines, sort_by_advanced, show_percentage, time_period, from: fromParameter, to: toParameter, exclude_none_in_oee, operations, exclude_unknown_in_oee,  q, selected_wo, wo_status, wo_priority, wo_group})
        
    }


  return (
    <div className={`relative  ${isMobile ? 'w-full':' w-fit md:max-w-[300px]'}`}>
        <button 
            // className={`w-full px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-[1px] border-slate-50 dark:border-slate-400 rounded shadow-iotflows-lg`} 
            className={`
                ${showCalendar ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none max-w-[250px] relative`}
            onClick={handleOpenCalendar}
        >
            {startDate && endDate ? (
                <div className='flex items-center'>
                    <motion.button  whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}   
                        className='bg-white w-3 h-3 rounded-full m-1'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClear();
                        }}  
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 rotate-45 h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                        </svg>
                    </motion.button>
                    <div className='rounded-full ml-1 text-gray-600 dark:text-gray-300'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </div>
                    <div className='pl-0.5 text-sm text-gray-600 dark:text-gray-300'>
                        {formatDate(startDate)} {`-`} {formatDate(endDate)}
                    </div>
                </div>
            ):(
                <div className='flex'>
                    <div className='bg-white w-3 h-3 rounded-full m-1'>
                        <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 rotate-0 h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className='pl-1 text-sm text-gray-600 dark:text-gray-300'>
                        Due Date
                    </div>
                </div>
            )}
        </button>
        {showCalendar && (
            <div className={`absolute z-20 left-0 top-10 flex flex-col bg-slate-700 rounded-xl  `}>
                <div className='flex justify-between bg-slate-700 px-2 py-1 rounded-t-xl'>
                    <motion.button  whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}   
                        onClick={handleClear} 
                        className='text-blue-600 dark:text-blue-400 hover:text-blue-300 px-2 py-0 rounded-md text-sm italic '>
                        Clear Dates
                    </motion.button>
                    <button 
                        onClick={closeCalendar} 
                        className='rounded-full hover:bg-slate-800 p-1'>
                        <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-300 ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className={`flex`}>
                    <div className={` border-slate-500`}>
                            <DateRangePicker
                                ranges={[selectionRange]}
                                rangeColors={['#3b82f6']}
                                onChange={handleSelect}
                                inputRanges={[]}
                                staticRanges={[
                                    {
                                        label: "Today",
                                        range: () => ({
                                          startDate: startOfDay(new Date()),
                                          endDate: endOfDay(new Date()),
                                        }),
                                        isSelected(range) {
                                            const definedRange = this.range();
                                            return (
                                              isSameDay(range.startDate, definedRange.startDate) &&
                                              isSameDay(range.endDate, definedRange.endDate)
                                            );
                                          }
                                      },
                                      {
                                        label: "Tomorrow",
                                        range: () => ({
                                          startDate: startOfDay(addDays(new Date(), 1)),
                                          endDate: endOfDay(addDays(new Date(), 1)),
                                        }),
                                        isSelected(range) {
                                            const definedRange = this.range();
                                            return (
                                              isSameDay(range.startDate, definedRange.startDate) &&
                                              isSameDay(range.endDate, definedRange.endDate)
                                            );
                                          }
                                      },
                                      {
                                        label: "Next 7 Days",
                                        range: () => ({
                                          startDate: startOfDay(new Date()),
                                          endDate: endOfDay(addDays(new Date(), 7)),
                                        }),
                                        isSelected(range) {
                                            const definedRange = this.range();
                                            return (
                                              isSameDay(range.startDate, definedRange.startDate) &&
                                              isSameDay(range.endDate, definedRange.endDate)
                                            );
                                          }
                                      },
                                      {
                                        label: "Next 2 Weeks",
                                        range: () => ({
                                          startDate: startOfDay(new Date()),
                                          endDate: endOfDay(addDays(new Date(), 14)),
                                        }),
                                        isSelected(range) {
                                            const definedRange = this.range();
                                            return (
                                              isSameDay(range.startDate, definedRange.startDate) &&
                                              isSameDay(range.endDate, definedRange.endDate)
                                            );
                                          }
                                      },
                                      {
                                        label: "Next 30 Days",
                                        range: () => ({
                                          startDate: startOfDay(new Date()),
                                          endDate: endOfDay(addDays(new Date(), 30)),
                                        }),
                                        isSelected(range) {
                                            const definedRange = this.range();
                                            return (
                                              isSameDay(range.startDate, definedRange.startDate) &&
                                              isSameDay(range.endDate, definedRange.endDate)
                                            );
                                          }
                                      },
                                      {
                                        label: "This Month",
                                        range: () => ({
                                          startDate: startOfMonth(new Date()),
                                          endDate: endOfMonth(new Date()),
                                        }),
                                        isSelected(range) {
                                            const definedRange = this.range();
                                            return (
                                              isSameDay(range.startDate, definedRange.startDate) &&
                                              isSameDay(range.endDate, definedRange.endDate)
                                            );
                                          }
                                      },
                                      {
                                        label: "Overdue",
                                        range: () => ({
                                          startDate:new Date(2019, 1, 5),
                                          endDate: endOfDay(addDays(new Date(), -1)),
                                        }),
                                        isSelected(range) {
                                            const definedRange = this.range();
                                            return (
                                              isSameDay(range.startDate, definedRange.startDate) &&
                                              isSameDay(range.endDate, definedRange.endDate)
                                            );
                                          }
                                      },
                                  ]}
                            />
                    </div>
                </div>
                <div>
                <button  
                    onClick={handleApplyDates} 
                    className='w-full text-base p-2 bg-slate-600 text-white font-medium rounded-b-xl hover:bg-slate-800' >
                        Apply
                </button>
                </div>
            </div>
        )}
    </div>
  )
}

export default DateRangeFilterWOBtn

