import React, { useEffect, useState, Fragment } from 'react'
import InfoSectionHeader from './InfoSectionHeader'
import { Avatar } from '@mui/material'
import { motion, AnimatePresence } from "framer-motion";
import ChatMembersList from './ChatMembersList';
import EditChat from './EditChat';
import AddUserToChatModal from '../../components/modals/chatModals/AddUserToChatModal';
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react'
import MediaPage from './MediaPage';
import FilesPage from './FilesPage';
import VoicePage from './VoicePage';
import DeleteChatConfirmationModal from '../../components/modals/messagingModals/DeleteChatConfirmationModal';
import LeaveChatConfirmationModal from '../../components/modals/messagingModals/LeaveChatConfirmationModal';

var subNav = [
    {
        name:'Members',
        url:'members'
    },
    {
        name:'Media',
        url:'media' 
    },
    // {
    //     name:'Saved',
    //     url:'saved'
    // },
    {
        name:'Files',
        url:'files'
    },
    // {
    //     name:'Links',
    //     url:'links'
    // },
    // {
    //     name:'Music',
    //     url:'music'
    // },
    {
        name:'Voice',
        url:'voice'
    },
    // {
    //     name:'Groups',
    //     url:'groups'
    // },
]

function InfoSection({participants, userInfo, chatInfo, setParticipants, getChatList, memberObjects, setMemberObjects}) {
    const [selectedSubNav, setSelectedSubNav] = useState('members')
    const [selectedTab, setSelectedTab] = useState(subNav[0])
    const [chatImage, setChatImage] = useState(null)
    const [chatName, setChatName] = useState(null)
    const [chatColor, setChatColor] = useState('')
    const [isEditingGroup, setIsEditingGroup] = useState(false)
    const [openAddUserToChatModal, setOpenAddUserToChatModal] = useState(false)
    const [isDeleteChatModalOpen, setIsDeleteChatModalOpen] = useState(false)
    const [isLeaveChatModalOpen, setIsLeaveChatModalOpen] = useState(false)

   

    useEffect(() => {
        // console.log('color', color)
        // console.log('INFO Header', chatInfo)
        if(chatInfo.chat_is_direct){

            setChatImage(chatInfo.direct_chat_image_url)
            setChatName(chatInfo.direct_chat_name)
            setChatColor(chatInfo.direct_chat_user_color)

            // if(participants &&  Object.keys(participants).length > 0){
            //     for (const key in participants) {
            //         // console.log('key', key)
            //         // console.log('userInfo.user_username', userInfo.user_username)
            //         if (key != userInfo.user_username) {
            //             let name = `${participants[key].user_first_name} ${participants[key].user_last_name}`
            //             setChatImage(participants[key].user_image_url)
            //             setChatName(name)
            //             setChatColor(participants[key].user_color)
            //           break;
            //         }
            //       }
            // }

        }else{
            setChatImage(chatInfo.chat_picture_url)
            setChatName(chatInfo.chat_name)
            setChatColor(chatInfo.chat_color)
        }

    },[chatInfo])

    useEffect(() =>{
        setSelectedSubNav(selectedTab.url)
    },[selectedTab])

    const handleOpenAddUserToChatModal = () => {
        setOpenAddUserToChatModal(false)
    }


    const handleCloseDeleteChatModal = () => {
        setIsDeleteChatModalOpen(false)
    }

    const handleCloseLeaveChatModal = () => {
        setIsLeaveChatModalOpen(false)
    }
    

  return (
    <div >
        <AnimatePresence mode='wait'>
        {!isEditingGroup ? (
            <motion.div
            key={'mainInfoPage'}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
            >
            <InfoSectionHeader setIsEditingGroup={setIsEditingGroup} chatInfo={chatInfo} />
            <div className='max-h-[calc(100vh-5rem)] overflow-y-scroll scrollbar-hide pb-10'>

                <div className='flex flex-col justify-center text-center mt-5'>
                    <div className='mx-auto'>
                        <Avatar
                            sx={{ width: 120, height: 120,bgcolor: chatColor, fontSize: 42}}
                            style={{
                                background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${chatColor} 100%)`,
                              }}
                            alt={chatName}
                            src={chatImage || '/brokenLink.png'}
                            >
                        </Avatar>
                    </div>
                    <div className='flex flex-col justify-center text-center mt-2'>
                        <span className='text-gray-600 dark:text-gray-300 font-medium'>{chatName}</span>
                    </div>

                </div>
                <div>
                    <div className='max-w-4xl mx-auto mt-3'>
                        <div className='flex space-x-2 mx-4 lg:mx-10 mb-4 '>
                            {!chatInfo.chat_is_direct && 
                                <button 
                                    onClick={() => setOpenAddUserToChatModal(true)}
                                    className='rounded-xl bg-slate-100 dark:bg-slate-800 flex flex-col text-blue-600 dark:text-blue-500 py-4 px-4 grow text-center'
                                >
                                    <div className='mx-auto'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                        </svg>
                                    </div>
                                    <div className='text-sm mx-auto'>
                                        Add
                                    </div>
                                </button>
                            }
                            {/* <div className='rounded-xl bg-slate-100 dark:bg-slate-800 flex flex-col text-blue-600 dark:text-blue-500 py-4 px-4 grow text-center'>
                                <div className='mx-auto'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                                    </svg>
                                </div>
                                <div className='text-sm'>
                                    Mute
                                </div>
                            </div> */}
                            <div className='grow relative flex'>
                                <Menu >
                                    <MenuButton 
                                        as="button" 
                                        className=' rounded-xl bg-slate-100 dark:bg-slate-800 flex flex-col text-blue-600 dark:text-blue-500 py-4 px-4 w-full text-center'
                                    >
                                        <div className='mx-auto'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                                            </svg>
                                        </div>
                                        <div className='text-sm mx-auto'>
                                            More
                                        </div>
                                    </MenuButton>

                                    <Transition
                                        as={Fragment}
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0"
                                    >
                                        <MenuItems className="absolute right-8 top-10 flex flex-col mt-1 w-[200px] bg-white dark:bg-slate-700 shadow-iotflows-lg rounded-lg outline-none z-50">
                                            <div className="px-1 py-1">
                                            {!chatInfo.chat_is_direct && (
                                                <MenuItem>
                                                        <button
                                                        onClick={() => setIsEditingGroup(true)}
                                                        className={` text-blue-600 dark:text-blue-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                                        data-[active]:bg-slate-50 data-[active]:dark:bg-slate-500 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                        </svg>
                                                        <span className='pl-1'>Edit</span>
                                                    </button>
                                                </MenuItem>
                                            )}
                                            {!chatInfo.chat_is_direct && (
                                                <MenuItem>
                                                    <button
                                                        className={`text-red-600 dark:text-red-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                                        data-[active]:bg-slate-50 data-[active]:dark:bg-slate-500 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                                        onClick={() => setIsLeaveChatModalOpen(true)}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                                                        </svg>
                                                        <span className='pl-1'>Leave</span>
                                                    </button>
                                                </MenuItem>
                                            )}
                                                {chatInfo.chat_is_direct && (
                                                    <MenuItem>
                                                        <button
                                                            className={`relative text-red-600 dark:text-red-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                                            data-[active]:bg-slate-50 data-[active]:dark:bg-slate-500 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                                            // onClick={handleDeleteChat}
                                                            onClick={() => setIsDeleteChatModalOpen(true)}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                            </svg>
                                                            <span className='pl-1'>Delete Chat</span>
                                                        </button>
                                                    </MenuItem>
                                                )}
                                                {(chatInfo && !chatInfo.chat_is_direct && userInfo && userInfo.user_username && participants && participants[userInfo.user_username] && participants[userInfo.user_username].chatroom_member_is_owner) && (
                                                    <MenuItem>
                                                        <button
                                                            className={`relative text-red-600 dark:text-red-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                                            data-[active]:bg-slate-50 data-[active]:dark:bg-slate-500 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                                            // onClick={handleDeleteChat}
                                                            onClick={() => setIsDeleteChatModalOpen(true)}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                            </svg>
                                                            <span className='pl-1'>Delete Chat</span>
                                                        </button>
                                                    </MenuItem>
                                                )}
                                            </div>
                                        </MenuItems>
                                    </Transition>
                                </Menu>
                            </div>
                            
                        </div>
                        <div className='mx-4 rounded-xl bg-slate-100 dark:bg-slate-800 mb-10'>
                            <nav >
                                <div className='flex overflow-x-scroll scrollbar-hide w-full justify-around text-center border-b-[0.01em] border-slate-200 dark:border-slate-600'>
                                {subNav.map((item) => (
                                    <div
                                    key={item.url}
                                    className='relative py-3 mx-3 text-center '
                                    onClick={() => setSelectedTab(item)}
                                    >
                                    <span className={`${item.url === selectedTab.url ? 'text-blue-600 dark:text-blue-500':' text-gray-600 dark:text-gray-100 hover:text-gray-900 dark:hover:text-gray-300'}  pb-1  outline-none text-base cursor-pointer bg-transparent whitespace-nowrap `}>{`${item.name}`}</span>
                                    {item === selectedTab ? (
                                        <motion.div className="bg-blue-600 dark:bg-blue-500 absolute bottom-0 left-0 right-0 h-[2px] rounded-t-full" layoutId="underline" />
                                    ) : null}
                                    </div>
                                ))}
                                </div>
                            </nav>
                            <div className=' '>
                            {/* <div className='max-h-[300px] overflow-y-scroll scrollbar-hide px-4 py-2'> */}
                                {selectedSubNav &&
                                    subLinkPage(selectedSubNav, participants, chatInfo, setParticipants, userInfo, memberObjects, setMemberObjects)
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </motion.div>
        ):(
            <motion.div
                key={'editInfoPage'}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
                <EditChat chatInfo={chatInfo} name={chatName} image={chatImage} setIsEditingGroup={setIsEditingGroup}  /> 
            </motion.div>
        )}
            

        </AnimatePresence>
        {openAddUserToChatModal && 
            <AddUserToChatModal btnType='hideBtn' chatInfo={chatInfo} remoteOpenAssetModal={openAddUserToChatModal} handleOpenAddUserToChatModal={handleOpenAddUserToChatModal} participants={participants} setParticipants={setParticipants} setMemberObjects={setMemberObjects} />
        }
        {isDeleteChatModalOpen &&
            <DeleteChatConfirmationModal remoteOpenModal={isDeleteChatModalOpen} handleCloseDeleteChatModal={handleCloseDeleteChatModal} chatInfo={chatInfo} getChatList={getChatList} chatName={chatName} title='chat' />
        }
        {isLeaveChatModalOpen &&
            <LeaveChatConfirmationModal remoteOpenModal={isLeaveChatModalOpen} handleCloseLeaveChatModal={handleCloseLeaveChatModal} chatInfo={chatInfo} getChatList={getChatList} chatName={chatName} userInfo={userInfo} title='chat' />
        }
    </div>
  )
}

export default InfoSection


const subLinkPage = (link, participants, chatInfo, setParticipants, userInfo, memberObjects, setMemberObjects) => {
    const allLinks = {
        members: { link: "members", content: <ChatMembersList participants={participants} chatInfo={chatInfo} setParticipants={setParticipants} userInfo={userInfo} memberObjects={memberObjects} setMemberObjects={setMemberObjects} /> },
        media: { link: "media", content: <MediaPage participants={participants} chatInfo={chatInfo} userInfo={userInfo}  />},
        // saved: { link: "saved", content: <div className='text-[128px]'>🥸🧀 🥬</div> },
        files: { link: "files", content: <FilesPage participants={participants} chatInfo={chatInfo} userInfo={userInfo}  /> },
        // links: { link: "links", content: <div className='text-[128px]'>🧀 🥸🥬</div> },
        // music: { link: "music", content: <div className='text-[128px]'>🥸🧀 🥬</div> },
        voice: { link: "voice", content: <VoicePage userInfo={userInfo} chatInfo={chatInfo}   />},
        // groups: { link: "groups", content: <div className='text-[128px]'>🧀 🥬</div> },
    };

      return (
        <AnimatePresence mode='wait'>
        <motion.div
        key={allLinks[link].link}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {allLinks[link].content}
        </motion.div>
      </AnimatePresence>
    )           
}