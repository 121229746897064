import React, { useEffect, useState } from 'react'
import { formatRelativeTime } from '../../utils/DateFormating';
import { AnimatePresence, motion } from 'framer-motion';
import { Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WOTodoListRow from './WOTodoListRow';

const subNav = [
    {
        name:'To Do',
        url:'todo_list'
    },
    // {
    //     name:'Done',
    //     url:'done_list' 
    // },
]

function WOTodoList({workOrdersList, selectedOrganization, selectedWorkOrderUuid, isMobile, getWorkOrders, workOrdersDoneList}) {
    const [selectedSubNav, setSelectedSubNav] = useState('todo_list')
    const [selectedTab, setSelectedTab] = useState(subNav[0])
    const [openSections, setOpenSections] = useState({
        assignedToMe: true,
        assignedToTeams: true,
        createdByMe: true,
        assignedToMeDone: true,
        assignedToTeamsDone: true,
        createdByMeDone: true,
    });
    const [assignedToMeList, setAssignedToMeList] = useState([])
    const [assignedToMeCount, setAssignedToMeCount] = useState(0)
    const [assignedToMyTeamsList, setAssignedToMyTeamsList] = useState([])
    const [assignedToMyTeamsCount, setAssignedToMyTeamsCount] = useState(0)
    const [createdByMeList, setCreatedByMeList] = useState([])
    const [createdByMeCount, setCreatedByMeCount] = useState(0)

    const [assignedToMeDoneList, setAssignedToMeDoneList] = useState([])
    const [assignedToMeDoneCount, setAssignedToMeDoneCount] = useState(0)
    const [assignedToMyTeamsDoneList, setAssignedToMyTeamsDoneList] = useState([])
    const [assignedToMyTeamsDoneCount, setAssignedToMyTeamsDoneCount] = useState(0)
    const [createdByMeDoneList, setCreatedByMeDoneList] = useState([])
    const [createdByMeDoneCount, setCreatedByMeDoneCount] = useState(0)
    
    useEffect(() => {
        setAssignedToMeList([])
        setAssignedToMeCount(0)
        setAssignedToMyTeamsList([])
        setAssignedToMyTeamsCount(0)
        setCreatedByMeList([])
        setCreatedByMeCount(0)
        if(workOrdersList && Object.keys(workOrdersList).length > 0){
            {workOrdersList.map((section) => {
                if (section.workorder_section == "Assigned to me") {
                    setAssignedToMeList(section.workorders)
                    setAssignedToMeCount(Object.keys(section.workorders).length)
                }
                if (section.workorder_section == "Assigned to my teams") {
                    setAssignedToMyTeamsList(section.workorders)
                    setAssignedToMyTeamsCount(Object.keys(section.workorders).length)
                }
                if (section.workorder_section == "Created by me") {
                    setCreatedByMeList(section.workorders)
                    setCreatedByMeCount(Object.keys(section.workorders).length)
                }
            })}
        }
    },[workOrdersList])

    useEffect(() => {
        setAssignedToMeDoneList([])
        setAssignedToMeDoneCount(0)
        setAssignedToMyTeamsDoneList([])
        setAssignedToMyTeamsDoneCount(0)
        setCreatedByMeDoneList([])
        setCreatedByMeDoneCount(0)
        if(workOrdersDoneList && Object.keys(workOrdersDoneList).length > 0){
            {workOrdersDoneList.map((section) => {
                if (section.workorder_section == "Assigned to me") {
                    setAssignedToMeDoneList(section.workorders)
                    setAssignedToMeDoneCount(Object.keys(section.workorders).length)
                }
                if (section.workorder_section == "Assigned to my teams") {
                    setAssignedToMyTeamsDoneList(section.workorders)
                    setAssignedToMyTeamsDoneCount(Object.keys(section.workorders).length)
                }
                if (section.workorder_section == "Created by me") {
                    setCreatedByMeDoneList(section.workorders)
                    setCreatedByMeDoneCount(Object.keys(section.workorders).length)
                }
            })}
        }
    },[workOrdersDoneList])

    const toggleSection = (section) => {
        setOpenSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const SectionHeader = ({ title, isOpen, onToggle, count }) => (
        <div 
            className="flex items-center justify-between w-full px-3 py-2.5 mb-1 bg-slate-100  dark:bg-slate-800 text-blue-500 dark:text-blue-300 rounded-t-2xl rounded-b-md cursor-pointer "
            onClick={onToggle}
        >
            <h3 className="text-sm font-medium ">{`${title} ${count && count > 0 ? `(${count})`: ''}`}</h3>
            <svg
                className={`w-5 h-5 ml-2 transform transition-transform duration-200 ${
                    isOpen ? "rotate-180" : "rotate-0"
                }`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06-.02L10 10.94l3.71-3.75a.75.75 0 111.08 1.04l-4.25 4.25a.75.75 0 01-1.08 0L5.23 8.27a.75.75 0 01-.02-1.06z"
                    clipRule="evenodd"
                />
            </svg>
        </div>
    );
    
    
  return (
    // <div className='flex flex-col space-y-1 p-2 border-[1px] border-slate-100 dark:border-slate-800 h-screen scrollbar-hide overflow-y-scroll rounded-lg '>
    <div className={`flex flex-col border-r-[1px] border-slate-200 dark:border-slate-800 ${isMobile ? 'h-[calc(100vh-0.5rem)]' : 'h-[calc(100vh-11.7rem)]'} `}>
        {/* <div className='w-full'>
            <nav className="w-full">
                <div
                    className="grid grid-cols-1 w-full border-b-[0.01em] border-gray-300 dark:border-gray-600 relative"
                    role="tablist"
                >
                    {subNav.map((item) => (
                        <div
                            key={item.url}
                            className="relative flex justify-center items-center cursor-pointer pt-1 pb-1.5"
                            role="tab"
                            aria-selected={item === selectedTab}
                            onClick={() => {
                                setSelectedTab(item);
                                setSelectedSubNav(item.url);
                            }}
                        >
                            {item === selectedTab && (
                                <motion.div
                                    className="absolute inset-0 bg-blue-500/10 rounded-t-xl"
                                    layoutId="background"
                                />
                            )}
                                <span
                                    className={`
                                        ${item.url === selectedTab.url ? 'font-medium' : 'font-base'}
                                        text-gray-600 dark:text-gray-100 
                                        hover:text-gray-900 dark:hover:text-gray-300 
                                        outline-none text-base cursor-pointer 
                                        bg-transparent whitespace-nowrap
                                        text-center z-10
                                    `}
                                >
                                    {item.name}
                                </span>
                                {item === selectedTab && (
                                    <motion.div
                                        className="bg-blue-600 absolute bottom-0 left-0 right-0 h-[2px] rounded-t-full"
                                        layoutId="workorderUnderline"
                                    />
                                )}
                        </div>
                    ))}
                </div>
            </nav>
        </div> */}
        {selectedSubNav && (
            <AnimatePresence mode='wait'>
                <div className='p-1 flex-grow scrollbar-hide overflow-y-scroll '>
                    {selectedSubNav && selectedSubNav == 'done_list' ? (
                            <motion.div
                                key='done_list_container'
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                            >
                                <div className="w-full ">
                                {assignedToMeDoneList && Object.keys(assignedToMeDoneList).length > 0 && (
                                    <div>
                                        <SectionHeader 
                                            title="Assigned to Me" 
                                            isOpen={openSections.assignedToMeDone}
                                            onToggle={() => toggleSection('assignedToMeDone')}
                                            count={assignedToMeDoneCount}
                                        />
                                        <AnimatePresence>
                                                {openSections.assignedToMeDone && (
                                                    <motion.div
                                                        initial={{ height: 0, opacity: 0 }}
                                                        animate={{ height: "auto", opacity: 1 }}
                                                        exit={{ height: 0, opacity: 0 }}
                                                        transition={{ duration: 0.2 }}
                                                        className="overflow-hidden"
                                                    >
                                                        <div className="mb-1 space-y-1">
                                                            {assignedToMeDoneList.map((workOrder) => (
                                                                    <WOTodoListRow
                                                                        key={workOrder.workorder_uuid}
                                                                        workOrder={workOrder}
                                                                        isMobile={isMobile}
                                                                        selectedOrganization={selectedOrganization}
                                                                        selectedWorkOrderUuid={selectedWorkOrderUuid}
                                                                        getWorkOrders={getWorkOrders}
                                                                    />
                                                                ))}
                                                        </div>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                    </div>
                                )}
                                {assignedToMyTeamsDoneList && Object.keys(assignedToMyTeamsDoneList).length > 0 && (
                                    <div className='mt-2'>
                                        <SectionHeader 
                                            title="Assigned to My Teams" 
                                            isOpen={openSections.assignedToTeamsDone}
                                            onToggle={() => toggleSection('assignedToTeamsDone')}
                                            count={assignedToMyTeamsDoneCount}
                                        />
                                        {openSections.assignedToTeamsDone && (
                                            <motion.div
                                                initial={{ height: 0, opacity: 0 }}
                                                animate={{ height: "auto", opacity: 1 }}
                                                exit={{ height: 0, opacity: 0 }}
                                                transition={{ duration: 0.2 }}
                                                className="overflow-hidden"
                                            >
                                            <div className="mb-1 space-y-1">
                                                {assignedToMyTeamsDoneList.map((workOrder) => (
                                                        <WOTodoListRow
                                                            key={workOrder.workorder_uuid}
                                                            workOrder={workOrder}
                                                            isMobile={isMobile}
                                                            selectedOrganization={selectedOrganization}
                                                            selectedWorkOrderUuid={selectedWorkOrderUuid}
                                                            getWorkOrders={getWorkOrders}
                                                        />
                                                    ))}

                                            </div>
                                            </motion.div>
                                        )}
                                    </div>
                                )}
                                {createdByMeDoneList && Object.keys(createdByMeDoneList).length > 0 && (
                                    <div className='mt-2'>
                                        <SectionHeader 
                                            title="Created by Me" 
                                            isOpen={openSections.createdByMeDone}
                                            onToggle={() => toggleSection('createdByMeDone')}
                                            count={createdByMeDoneCount}
                                        />
                                        {openSections.createdByMeDone && (
                                            <motion.div
                                                initial={{ height: 0, opacity: 0 }}
                                                animate={{ height: "auto", opacity: 1 }}
                                                exit={{ height: 0, opacity: 0 }}
                                                transition={{ duration: 0.2 }}
                                                className="overflow-hidden"
                                            >
                                            <div className="mb-1 space-y-1">
                                                {createdByMeDoneList.map((workOrder) => (
                                                        <WOTodoListRow
                                                            key={workOrder.workorder_uuid}
                                                            workOrder={workOrder}
                                                            isMobile={isMobile}
                                                            selectedOrganization={selectedOrganization}
                                                            selectedWorkOrderUuid={selectedWorkOrderUuid}
                                                            getWorkOrders={getWorkOrders}
                                                        />
                                                    ))}

                                            </div>
                                            </motion.div>
                                        )}
                                    </div>
                                )}
                            </div>
                            </motion.div>

                    ):(
                        <motion.div
                            key='todo_list_container'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                        >
                            <div className="w-full ">
                            {assignedToMeList && Object.keys(assignedToMeList).length > 0 && (
                                <div>
                                    <SectionHeader 
                                        title="Assigned to Me" 
                                        isOpen={openSections.assignedToMe}
                                        onToggle={() => toggleSection('assignedToMe')}
                                        count={assignedToMeCount}
                                    />
                                       <AnimatePresence>
                                            {openSections.assignedToMe && (
                                                <motion.div
                                                    initial={{ height: 0, opacity: 0 }}
                                                    animate={{ height: "auto", opacity: 1 }}
                                                    exit={{ height: 0, opacity: 0 }}
                                                    transition={{ duration: 0.2 }}
                                                    className="overflow-hidden"
                                                >
                                                    <div className="mb-1 space-y-1">
                                                        {assignedToMeList.map((workOrder) => (
                                                                <WOTodoListRow
                                                                    key={workOrder.workorder_uuid}
                                                                    workOrder={workOrder}
                                                                    isMobile={isMobile}
                                                                    selectedOrganization={selectedOrganization}
                                                                    selectedWorkOrderUuid={selectedWorkOrderUuid}
                                                                    getWorkOrders={getWorkOrders}
                                                                />
                                                            ))}

                                                    </div>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                </div>
                                )}
                                {assignedToMyTeamsList && Object.keys(assignedToMyTeamsList).length > 0 && (
                                    <div className='mt-2'>
                                    <SectionHeader 
                                        title="Assigned to My Teams" 
                                        isOpen={openSections.assignedToTeams}
                                        onToggle={() => toggleSection('assignedToTeams')}
                                        count={assignedToMyTeamsCount}
                                    />
                                    {openSections.assignedToTeams && (
                                        <motion.div
                                            initial={{ height: 0, opacity: 0 }}
                                            animate={{ height: "auto", opacity: 1 }}
                                            exit={{ height: 0, opacity: 0 }}
                                            transition={{ duration: 0.2 }}
                                            className="overflow-hidden"
                                        >
                                        <div className="mb-1 space-y-1">
                                            {assignedToMyTeamsList.map((workOrder) => (
                                                    <WOTodoListRow
                                                        key={workOrder.workorder_uuid}
                                                        workOrder={workOrder}
                                                        isMobile={isMobile}
                                                        selectedOrganization={selectedOrganization}
                                                        selectedWorkOrderUuid={selectedWorkOrderUuid}
                                                        getWorkOrders={getWorkOrders}
                                                    />
                                                ))}

                                        </div>
                                        </motion.div>
                                    )}
                                </div>
                                )}
                                {createdByMeList && Object.keys(createdByMeList).length > 0 && (
                                    <div className='mt-2'>
                                    <SectionHeader 
                                        title="Created by Me" 
                                        isOpen={openSections.createdByMe}
                                        onToggle={() => toggleSection('createdByMe')}
                                        count={createdByMeCount}
                                    />
                                    {openSections.createdByMe && (
                                        <motion.div
                                            initial={{ height: 0, opacity: 0 }}
                                            animate={{ height: "auto", opacity: 1 }}
                                            exit={{ height: 0, opacity: 0 }}
                                            transition={{ duration: 0.2 }}
                                            className="overflow-hidden"
                                        >
                                         <div className="mb-1 space-y-1">
                                            {createdByMeList.map((workOrder) => (
                                                    <WOTodoListRow
                                                        key={workOrder.workorder_uuid}
                                                        workOrder={workOrder}
                                                        isMobile={isMobile}
                                                        selectedOrganization={selectedOrganization}
                                                        selectedWorkOrderUuid={selectedWorkOrderUuid}
                                                        getWorkOrders={getWorkOrders}
                                                    />
                                                ))}

                                        </div>
                                        </motion.div>
                                    )}
                                </div>
                                )}
                            </div>
                            {/* {workOrdersList.map((section) => (
                            <div key={section.workorder_section_id} className="flex flex-col">
                                <div className="py-1.5 relative mb-1">
                                    <div className='relative'>
                                    <div className={`absolute top-1/2 left-0 right-0 border-t-2 border-gray-200 dark:border-slate-700  border-dashed`}></div>
                                    <div className={`text-xs py-0 px-3 w-fit rounded-full text-gray-800 dark:text-white bg-gray-200 dark:bg-slate-700  ml-4 relative z-10`}>
                                        {section.workorder_section}
                                    </div>
                                    </div>
                                </div>
                                <div className='mb-2 space-y-1'>
                                    {section && section.workorders && Object.keys(section.workorders).length > 0 && section.workorders.map(workOrder => (
                                        <WOTodoListRow key={workOrder.workorder_uuid} workOrder={workOrder} isMobile={isMobile} selectedOrganization={selectedOrganization} selectedWorkOrderUuid={selectedWorkOrderUuid} getWorkOrders={getWorkOrders} />
                                    ))}

                                </div>
                            </div>
                            ))} */}
                        

                        </motion.div>

                    )}

                </div>
            </AnimatePresence>
         )
            // subLinkPage(selectedSubNav)
        }
    </div>
  )
}

export default WOTodoList