import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { globalSelectedOrganization, globalSelectedOrganizationTimezone } from '../../atoms/orgStates';
import StackedBarChartLargeParts from '../../components/charts/PartsHistoricalReportsCharts/StackedBarChartLargeParts';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { getData } from '../../ApiCalls/DataApis';
import PartsHistoricalReportTableRow from './PartsHistoricalReportTableRow'
import StackedBarChartLargeBlue from '../../components/charts/StackedBarChartLargeBlue'
import DateRangeFilterAdvancedBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/DateRangeFilterAdvancedBtn'
import ShiftMinutesFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/ShiftMinutesFilterBtn'
import PartOperationFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/PartOperationFilterBtn'
import { LinearProgress, Switch } from '@mui/material'
import ShiftSelectionFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/ShiftSelectionFilterBtn';
import { useLocation, useSearchParams } from 'react-router-dom';
import { LightTooltip } from '../../styles/Tooltip';
import MobileRightDrawer from '../../components/MobileRightDrawer';
import ResetFiltersBtn from '../../components/filterButtons/ResetFiltersBtn';

function PartsHistoricalReportPage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const selectedOrganizationTimeZone = useRecoilValue(globalSelectedOrganizationTimezone);
    const [opsList, setOpsList] = useState([])
    const [historicalOpsList, setHistoricalOpsList] = useState([])
    const [mainAllData, setMainAllData] = useState([])
    const [loadingDetailedData, setLoadingDetailedData] = useState(false)
    const [loadingMainChartData, setLoadingMainChartData] = useState(false)
    const [dateCategories, setDateCategories] = useState([])
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [toggleShowScrap, setToggleShowScrap] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
       
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }

    },[])


    useEffect(() => {
        if(selectedOrganization){
            getDetailedOperationsData()
        }
    }, [selectedOrganization, location])

    
    const getDetailedOperationsData = () => {
        let currentSearch = location.search
        if(selectedOrganization && currentSearch){
            let fromDate = searchParams.get('from')
            let toDate = searchParams.get('to')
            let period = searchParams.get('time_period')
            // forcing to have a date in the url to pull date, this is because of how long it takes the 6 weeks to load
            if(period != null && toDate != null && fromDate != null && !loadingDetailedData){
                setDateCategories([])
                setOpsList([])
                setMainAllData([])
                setLoadingDetailedData(true)
                setLoadingMainChartData(true)

                // I'm just using this to fill out the test data REMOVE LATER
                // New API (2 Layer) that removed parts and just shows operations and machines
                
                getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/historical_production' + currentSearch )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            console.log('Main LISt ', data)
                            setDateCategories(data.data.categories)
                            setOpsList(data.data.operations)
                            setLoadingDetailedData(false)


                            // const [goodChartData, badChartData] = processData(data);
                            // const allData = processData(data);
                            setMainAllData(data)
                            setLoadingMainChartData(false)

                        }
                    }
                })
            
            }

        }

    }

    function processData(data) {
        let allData = [];
        if(data && data.data && data.data.operations && Object.keys(data.data.operations).length > 0){
            data.data.operations.map(operation => {
                // goodSeriesData.push({
                //     name: operation.operation_name,
                //     data: operation.data_good,
                //     pointPadding: 0,
                //     stack:'good'
                // });
                // badSeriesData.push({
                //     name: `Scrap ${operation.operation_name}`,
                //     data: operation.data_bad,
                //     pointPadding: 0,
                //     stack:'bad'
                // });
                allData.push({
                    name: operation.operation_name,
                    data: operation.data_good,
                    pointPadding: 0,
                    stack:'Good'
                },{
                    name: `Scrap ${operation.operation_name}`,
                    data: operation.data_bad,
                    pointPadding: 0,
                    color: 'rgba(220, 38, 38, 0.5)',
                    stack:'Bad'
                })
            });
            
        }
        return allData ;
        // return [goodSeriesData,badSeriesData] ;
      }

    function formatTime(date) {
        let event = new Date(date)
        let currentTime = event.getTime();

        return currentTime
      }

    const handleShowScrap = (e) => {
        setToggleShowScrap(e.target.checked)
    }


    const downloadCSV = (data) => {
        if(Object.keys(data).length === 0) return

        let csvString = ``
        // csvString += '\r\n'
        csvString += 'Timestamp,categories,part_name,operation_name,operation_description,asset_identifier,asset_name,total_operation_good_count,total_operation_scrap_count,asset_good_count,asset_scrap_count '
        csvString += '\r\n'
        if(data.data.periods && Object.keys(data.data.periods).length > 0 && data.data.operations && Object.keys(data.data.operations).length > 0){

            data.data.periods.map((period, period_id) => {
                
                data.data.operations.map((operation, op_index) => {
                    
                    let op_description = operation.operation_description === null ? '':operation.operation_description

                    if(operation.assets && Object.keys(operation.assets).length > 0 ){
                        operation.assets.map((asset,asset_index) => {
    
                            csvString += period + ',' + data.data.categories[period_id] + ',' + operation.part_name + ',' + operation.operation_name + ',' + op_description + ',' 
                            
                            // insert asset_identifier
                            if(asset.asset_custom_identifier){
                                csvString +=  asset.asset_custom_identifier + ',' 
                            }else {
                                csvString +=  asset.asset_uuid + ',' 
                            }
                            // insert asset_name
                            if(asset.asset_custom_name){
                                csvString +=  asset.asset_custom_name + ',' 
                            }else {
                                csvString += asset.machine_name + ',' 
                            }
    
                            // insert total_operation_good_count,total_operation_scrap_count,asset_good_count,asset_scrap_count 
                            let goodIndex = operation.data_good[period_id] === null ? 0 : operation.data_good[period_id]
                            csvString += goodIndex + ',' 
                            let badIndex = operation.data_bad[period_id] === null ? 0 : operation.data_bad[period_id]
                            csvString += badIndex + ',' 
    
                            let assetGoodIndex = asset.data_good[period_id] === null ? 0 : asset.data_good[period_id]
                            csvString += assetGoodIndex + ',' 
                            let assetBadIndex = asset.data_bad[period_id] === null ? 0 : asset.data_bad[period_id]
                            csvString += assetBadIndex + ',' 
                            csvString += '\r\n'
                        })
                    }
    
                })
            })
            

        }

        
        
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Production_Historical_Report.csv');
        document.body.appendChild(link);
        link.click();
      };


  return (
    <div className='mt-4 h-screen '>
        {isMobile ? (
            <div className='flex justify-end'>
                <MobileRightDrawer >
                    <div className='flex flex-col space-y-3'>
                        <DateRangeFilterAdvancedBtn /> 
                        <PartOperationFilterBtn select='historical_report' />
                        <ShiftSelectionFilterBtn select='historical_report' />
                        <div className=''>
                            <LightTooltip title='Show scrap will display all scrap data inputted from users for the specific date range selected'>
                                <div className='flex items-center'>
                                    <p className='text-gray-600 dark:text-gray-400 text-sm'>Show Scrap</p>
                                    <Switch
                                        size="small"
                                        color="secondary"
                                        checked={toggleShowScrap}
                                        onChange={handleShowScrap}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </div>
                            </LightTooltip>
                        </div>
                        <button 
                            className='text-gray-600 dark:text-gray-300 flex text-sm items-center space-x-1 border-[0em] border-gray-600 dark:border-gray-300 rounded-lg hover:text-gray-500 dark:hover:text-gray-400'
                            onClick={() => downloadCSV(mainAllData)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                            </svg>

                            <span className='leading-3'>CSV</span>
                        </button>
                    </div>
                </MobileRightDrawer>
            </div>
        ):(
            <div className='flex w-full '>
                <div className='flex space-x-2 items-center'>
                    <PartOperationFilterBtn select='historical_report' />
                    <ShiftSelectionFilterBtn select='historical_report' />
                    <ResetFiltersBtn ignoreParams={['select', 'platform', 'time_period', 'from', 'to']} />
                    {/* <ShiftMinutesFilterBtn select='historical_report' /> */}
                </div>
                <div className='ml-auto flex items-center'>
                    <div className='mr-1'>
                        <LightTooltip title='Show scrap will display all scrap data inputted from users for the specific date range selected'>
                            <div className='flex items-center mt-2 sm:mt-0 ml-auto'>
                                <p className='text-gray-600 dark:text-gray-400 text-sm truncate'>Show Scrap</p>
                                <Switch
                                    size="small"
                                    color="secondary"
                                    checked={toggleShowScrap}
                                    onChange={handleShowScrap}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                        </LightTooltip>
                    </div>
                    <button 
                        className='text-gray-600 dark:text-gray-300 mr-1 flex text-sm items-center space-x-1 border-[0em] border-gray-600 dark:border-gray-300 rounded-lg px-3 hover:text-gray-500 dark:hover:text-gray-400'
                        onClick={() => downloadCSV(mainAllData)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>

                        <span className='leading-3'>CSV</span>
                    </button>
                    <DateRangeFilterAdvancedBtn /> 
                </div>
            </div>
        )}
        <div className='py-1'>
            <p className='text-gray-600 dark:text-gray-300 italic text-sm font-light'>{`This report was ran on the organizations time zone of ${selectedOrganizationTimeZone.name}, dates and times may vary from your local time zone.`}</p>
        </div>
        <div className='mt-2'>
            <div className='rounded-2xl bg-white dark:bg-slate-800 w-full shadow-iotflows-lg overflow-hidden'>
            {loadingMainChartData &&
                    <div>
                        {/* <LinearProgress color="primary" sx={{height:2}}/> */}
                        <div className='mt-2 flex-flex-col rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg p-4'>
                        <div className="animate-pulse flex space-x-4">
                            <div className="flex-1 space-y-4 py-1">
                            <div className="h-6 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            <div className="h-6 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            <div className="space-y-4">
                                <div className="grid grid-cols-3 gap-4">
                                <div className="h-6 bg-slate-300 dark:bg-slate-700 rounded-xl col-span-2"></div>
                                <div className="h-6 bg-slate-300 dark:bg-slate-700 rounded-xl col-span-1"></div>
                                </div>
                                <div className="h-6 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                }
                {mainAllData && Object.keys(mainAllData).length > 0 && 
                    <StackedBarChartLargeParts toggleShowScrap={toggleShowScrap} dateCategories={dateCategories} mainAllData={mainAllData} />
                }

            </div>
        </div>
        {/* Operations tables */}
        <div className='flex flex-col pb-10 mt-2'>
            <div className='rounded-full'>
                {loadingDetailedData &&
                    <div>
                        {/* <LinearProgress color="primary" sx={{height:2}}/> */}
                        <div className='mt-2 flex-flex-col rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg p-4'>
                        <div className="animate-pulse flex space-x-4">
                            <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-14 w-14"></div>
                            <div className="flex-1 space-y-6 py-1">
                            <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            <div className="space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded-xl col-span-2"></div>
                                <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded-xl col-span-1"></div>
                                </div>
                                <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                }
                {!loadingDetailedData && !opsList &&
                    <div>
                        {/* <LinearProgress color="primary" sx={{height:2}}/> */}
                        <div className='mt-2 flex-flex-col rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg p-4'>
                            <h6 className='text-gray-600 dark:text-gray-300 text-2lx text-center text-light'>
                                { `(;-;)`}
                            </h6>
                            <h6 className='text-gray-600 dark:text-gray-300 text-center text-light'>
                                No part production data
                            </h6>
                            <h6 className='text-gray-600 dark:text-gray-300 text-center text-light'>
                                is available for selected time period.
                            </h6>
                        </div>
                    </div>
                }
            </div>
                {!loadingDetailedData && opsList && Object.keys(opsList).length > 0 &&
                    opsList.map((operation,index) => (
                        <PartsHistoricalReportTableRow toggleShowScrap={toggleShowScrap} operation={operation} key={operation.operation_uuid} dateCategories={dateCategories} />
                    ))
                }
        </div>
    </div>
  )
}

export default PartsHistoricalReportPage