import React, { useEffect, useState } from 'react'
import StatusButtons from './components/StatusButtons';
import DescriptionImagesComponent from './components/DescriptionImagesComponent';
import DueDateComponent from './components/DueDateComponent';
import PriorityComponent from './components/PriorityComponent';
import SelectedGroupComponent from './components/SelectedGroupComponent';
import EstimatedTimeComponent from './components/EstimatedTimeComponent';
import AssetComponent from './components/AssetComponent';
import AssigneesComponent from './components/AssigneesComponent';
import SelectedRecurrenceComponent from './components/SelectedRecurrenceComponent';
import FilesComponent from './components/FilesComponent';
import { getData, putData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';

function RightDrawerSelectedWODetailsPage({selectedOrganization, selectedWorkOrder, getSelectedWorkOrder}) {
    const [isEditingWOTitle, setIsEditingWOTitle] = useState(false)
    const [woTitle, setWOTitle] = useState('')
    const [loadingTitle, setLoadingTitle] = useState(false);

    useEffect(() => {
        if(selectedWorkOrder && selectedWorkOrder.workorder_uuid){
            setWOTitle(selectedWorkOrder.workorder_title)
        }
    }, [selectedWorkOrder])



    const handleTitleUpdate = (event) => {
        const newTitle = event.target.value;
    
        // If no value, reset to original title
        if (!newTitle) {
            setWOTitle(selectedWorkOrder.workorder_title);
            setIsEditingWOTitle(false);
            return;
        }

        if (loadingTitle) return;
    
        try {
            setLoadingTitle(true);
            setIsEditingWOTitle(false);
    
            putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid, 
            {'workorder_title': event.target.value })
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    // toast.success('Title Change')
                    setLoadingTitle(false)
                    setWOTitle(event.target.value)
                }
                else{
                    setWOTitle(selectedWorkOrder.workorder_title)
                    setLoadingTitle(false)
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })

        } catch (e) {
            setWOTitle(selectedWorkOrder.workorder_title);
            toast.error(e.toString());
        }
    };

    const handleDownloadFile = async (message) => {
        // console.log('message', message)
        // console.log('file_uuid', message.file_uuid)
        try {
            getData(apiLink + '/v1/files/' + message.file_uuid + '/download_request' )
            .then(async response => {
                const data = await response.json();
                if(response.ok ){
                    try{
                        if(data && data.data && data.data.signed_url){
                            
                            // console.log('data', data.data.signed_url)
                            let url = data.data.signed_url
                            const response = await fetch(url);
                            const blob = await response.blob();
                            const downloadUrl = URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = downloadUrl;
                            link.download = message.file_name || 'download'; // Set the download filename
                            link.click();
                            window.URL.revokeObjectURL(downloadUrl); // Clean up the object URL
                            
                        }
                    }catch (error) {
                        toast.error('Error downloading file:', error);
                    } 
                }else{
                    toast.error( data.message);
                }
            })
        } catch (error) {
          toast.error('Error downloading file:', error);
        } 
    };


  return (
    <div className='mt-4'>
        {selectedWorkOrder && selectedWorkOrder.workorder_uuid && 
            (
                <div className='flex flex-col px-2'>
                    <div className='flex w-full border-b-[0.01em] border-slate-300 dark:border-slate-700 pb-3'>
                        <div className='flex flex-col w-full'>
                                <div className='relative'>
                                    <input
                                        type="text"
                                        value={woTitle}
                                        onChange={(e) => setWOTitle(e.target.value)}
                                        // onBlur={handleTitleBlur}
                                        // onKeyDown={handleKeyPressTitle}
                                        onBlur={handleTitleUpdate}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === 'Tab') {
                                                handleTitleUpdate(e);
                                            }
                                        }}
                                        className={`py-0 pr-3 pl-1 rounded-md text-2xl placeholder:text-gray-400 dark:placeholder:text-gray-600 font-medium
                                            ${isEditingWOTitle 
                                                ? 'w-full bg-transparent border shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800' 
                                                : 'bg-transparent w-full text-gray-600 dark:text-gray-300 truncate border-none focus:ring-0 cursor-pointer'}
                                        `}
                                        placeholder={"Work order title"}
                                        onClick={() => setIsEditingWOTitle(true)}
                                        readOnly={!isEditingWOTitle}
                                        autoFocus={isEditingWOTitle}
                                    />
                                    {loadingTitle && 
                                            <div >
                                                <svg aria-hidden="true" className={`absolute top-1/4 left-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                                </svg>
                                            </div>
                                            }

                                </div>
                        </div>
                    </div>
                    <div className='w-full border-b-[0.01em] border-slate-300 dark:border-slate-700 py-4 '>
                        <StatusButtons selectedWorkOrder={selectedWorkOrder} />
                    </div>
                    <div className='w-full border-b-[0.01em] border-slate-300 dark:border-slate-700 py-4'>
                        <DescriptionImagesComponent selectedWorkOrder={selectedWorkOrder} handleDownloadFile={handleDownloadFile} getSelectedWorkOrder={getSelectedWorkOrder} />
                    </div>
                    <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] py-4 border-b-[0.01em] border-slate-300 dark:border-slate-700'>
                        <div className='flex mx-1 my-6 justify-center '>
                            <DueDateComponent selectedWorkOrder={selectedWorkOrder} getSelectedWorkOrder={getSelectedWorkOrder} />
                        </div>
                        {/* <div className='px-10'>
                            <StartDateComponent selectedWorkOrder={selectedWorkOrder} getSelectedWorkOrder={getSelectedWorkOrder} />
                        </div> */}
                        <div className='flex mx-1 my-6 justify-center overflow-x-auto border-l-[0.01em] border-slate-300 dark:border-slate-700 px-2'>
                            <PriorityComponent selectedWorkOrder={selectedWorkOrder} />
                        </div>
                        <div className='flex  mx-1 my-6 justify-center '>
                            <SelectedGroupComponent selectedWorkOrder={selectedWorkOrder} getSelectedWorkOrder={getSelectedWorkOrder}  />
                        </div>
                        <div className='flex  mx-1 my-6 justify-center border-l-[0.01em] border-slate-300 dark:border-slate-700'>
                            <EstimatedTimeComponent selectedWorkOrder={selectedWorkOrder}  />
                        </div>
                        <div className='flex justify-center mx-1 my-6 '>
                            <div className='flex flex-col'>
                                <div className='flex justify-center text-gray-800 dark:text-white mb-2 font-medium'>
                                    <span>Work Order ID</span>
                                </div>
                                <div className={`flex justify-center text-gray-600 dark:text-gray-300 text-lg truncate`}>
                                    #{selectedWorkOrder.workorder_number}
                                </div>
                            </div>
                        </div>
                        <div className='mx-1 my-6 border-l-[0.01em]  border-slate-300 dark:border-slate-700'>
                            <AssigneesComponent selectedWorkOrder={selectedWorkOrder} selectedOrganization={selectedOrganization} />
                        </div>
                        <div className='flex justify-center mx-1 my-6 '>
                            <AssetComponent selectedWorkOrder={selectedWorkOrder} />
                        </div>
                        <div className='mx-1 my-6 border-l-[0.01em] border-slate-300 dark:border-slate-700'>
                            <div/>
                        </div>
                    </div>
                    {selectedWorkOrder && selectedWorkOrder.workorder_uuid &&selectedWorkOrder.workorder_due_timestamp && (
                        <div className='flex space-x-8 divide-x-[0.01em] divide-slate-300 dark:divide-slate-600 py-4  border-b-[0.01em] border-slate-300 dark:border-slate-700'>
                            <div className='pr-10 w-full max-w-[800px]'>
                                <SelectedRecurrenceComponent selectedWorkOrder={selectedWorkOrder} getSelectedWorkOrder={getSelectedWorkOrder} />
                            </div>

                        </div>
                    )}
                    <div className='w-full border-b-[0.01em] border-slate-300 dark:border-slate-700 py-4'>
                        <FilesComponent selectedWorkOrder={selectedWorkOrder} handleDownloadFile={handleDownloadFile} getSelectedWorkOrder={getSelectedWorkOrder} />
                    </div>

                </div>
            )
        }
    </div>
  )
}

export default RightDrawerSelectedWODetailsPage

