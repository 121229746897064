import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle, Listbox, ListboxButton,ListboxOptions, ListboxOption } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import "../../styles/work-order-calendar.css";
import NewWorkOrderModal from '../../components/modals/workOrderModals/NewWorkOrderModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { isDatePastDue } from '../../utils/DateFormating';
import { statusOptions, statusSmOptions } from '../statusOptions';
import { putData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';




function WOCalendarView({isMobile, selectedOrganization, workOrdersList, getWorkOrders}) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const isDark = useRecoilValue(darkModeState);
    const calendarRef = useRef(null);
    const [currentView, setCurrentView] = useState(() => {
        return localStorage.getItem('woCalendarViewIoTFlows') || 'dayGridMonth';
      });
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDateInfo, setSelectedDateInfo] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isNewWOModalOpen, setIsNewWOModalOpen] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState([]);

    
    

    useEffect(() => {
        if(workOrdersList && Object.keys(workOrdersList).length > 0){
            let allWorkOrders =[]
            workOrdersList.map(workOrderSection => {
                // console.log('workOrderSection', workOrderSection)
                if(workOrderSection.workorders && Object.keys(workOrderSection.workorders).length > 0){
                    workOrderSection.workorders.map(currentWorkOrder => {
                        let isAllDay = true
                        
                        let startDate = new Date();
                        startDate.setHours(0, 0, 0, 0);

                        let endDate = new Date();
                        endDate.setDate(endDate.getDate() + 1);
                        endDate.setHours(0, 0, 0, 0);


                        let utcDate = new Date();  
                        if(currentWorkOrder.workorder_due_timestamp){
                            utcDate = new Date(currentWorkOrder.workorder_due_timestamp);
                            isAllDay = false
                            // console.log('utcDate', utcDate)
                            let offset = utcDate.getTimezoneOffset(); 
                            endDate = new Date(utcDate.getTime() - offset * 60000);

                            let duration = 60
                            if(currentWorkOrder.workorder_duration_min){
                                duration = currentWorkOrder.workorder_duration_min
                            }
                            startDate = new Date(endDate.getTime() - duration * 60000  )

                            if(duration === 1440){
                                isAllDay = true
                            }
                            
                        }


                        

                        allWorkOrders.push({
                            id: currentWorkOrder.workorder_uuid,
                            title: currentWorkOrder.workorder_title,
                            start: startDate,
                            end: endDate,
                            allDay: isAllDay,
                            extendedProps: {
                            // Add additional workorder details you might want to display or use
                                description: currentWorkOrder.workorder_description,
                                priority: currentWorkOrder.workorder_priority,
                                status: currentWorkOrder.workorder_status,
                                group: currentWorkOrder.workorder_group,
                                assignees: currentWorkOrder.assignees_username,
                                recurrenceDescription: currentWorkOrder.workorder_recurrence_description,
                                workorderNumber: currentWorkOrder.workorder_number,
                                hasRead: currentWorkOrder.has_read
                            }
                        })
                    })
                }
            })
            setEvents(allWorkOrders)
        }

    }, [workOrdersList])

      
    const handleViewChange = (view) => {
        // Get the current view type
        const viewType = view.view.type;
        
        // Update state
        setCurrentView(viewType);
        
        // Save to local storage
        localStorage.setItem('woCalendarViewIoTFlows', viewType);
    };

    // Event color mapping
    const getEventColor = (status) => {
        let status_uuid = ''
        if(status && status.workorder_status_uuid){
            status_uuid = status.workorder_status_uuid
        }
        switch (status_uuid) {
            // case 'Open': return 'bg-blue-100 dark:bg-blue-900'; 
            case 'WORKORDER_STATUS_OPEN': return 'bg-blue-600 text-white border-blue-600'; 
            case 'WORKORDER_STATUS_IN_PROGRESS': return 'text-blue-950 bg-blue-400 dark:bg-blue-200 border-blue-200'; 
            case 'WORKORDER_STATUS_ON_HOLD': return 'text-amber-950 bg-amber-400 dark:bg-amber-200 border-amber-200'; 
            case 'WORKORDER_STATUS_CLOSED': return 'text-green-900 bg-green-400 border-green-400 dark:text-white dark:bg-green-700 dark:border-green-700'; 
            default: return 'bg-blue-600 text-white';
        }
    };

    
    const eventContent = (eventInfo) => {
        const event = eventInfo.event;
        const status = event.extendedProps.status;
        const calendarApi = calendarRef.current.getApi();
        const currentView = calendarApi.view.type;

        const matchingStatus = statusOptions.find(
            (current_status) => current_status.workorder_status_uuid === status.workorder_status_uuid
          );
        
        return (
        <div 
            className={`rounded overflow-hidden w-full ${currentView == 'dayGridMonth' ? 'border-[1px]':'border-[1px]'}  border-gray-100 dark:border-gray-700/90 bg-gray-50 hover:bg-gray-200/80 dark:bg-gray-800 hover:dark:bg-gray-900 shadow-sm`}
            style={{
                display: "flex",
                height: "100%", 
              }}
        >
            <div className={` w-1.5 rounded-l ${getEventColor(status)}`} />
            <div className="text-sm truncate w-full flex pl-1 pr-2 py-1 text-gray-600 dark:text-gray-100">
                {matchingStatus && matchingStatus.icon &&
                    <div className='relative '>
                        <div className={`pr-1 z-10`}>
                            {matchingStatus.icon}
                        </div>
                        {!event.extendedProps.hasRead && 
                            <span className="absolute top-[-1px] right-[1px] z-0">
                                <span className="flex rounded-full h-1.5 w-1.5 bg-sky-400"></span>
                            </span>
                        }
                        {/* {event.allDay && 
                            <span className="absolute top-0 right-[1px] z-0">
                                <span className="flex rounded-full h-1.5 w-1.5 bg-sky-400"></span>
                            </span>
                        } */}
                    </div>
                }
                <span className='truncate '>{`#${event.extendedProps.workorderNumber} ${event.title}`}</span> 
                {isDatePastDue(event.end) ? (
                    <div className={`text-red-500 ml-auto pt-[2px] rounded-full`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </div>

                ):(
                    <div />
                )}
            </div>
        </div>
        );
    };

    const handleEventResize = (info) => {
        // The event that was resized
        const event = info.event;

        // Original event details before resizing
        const oldEvent = info.oldEvent;
        
        if(!event.id) return

        // Optional: Revert the resize if it doesn't meet certain conditions
        // For example, preventing events from being resized to be too short
        // if (event.end - event.start < 30 * 60 * 1000) { // 30 minutes minimum
        //   info.revert();
        //   console.log('Resize reverted: Event too short');
        // }

        // console.log('Event Resized:', {
        //     eventId: event.id,
        //     newStart: event.start,
        //     newEnd: event.end,
        //     oldStart: oldEvent.start,
        //     oldEnd: oldEvent.end,
        //     duration: event.end - event.start
        //   });

        try{
            const end_date = new Date(event.end);
            // const start_date = new Date(event.start);
            let due_date = end_date.toISOString().split('T')[0];
            let due_date_time = end_date.toTimeString().split(' ')[0];
            // console.log('due_date', due_date)
            // console.log('due_date_time', due_date_time)

            // Calculate the difference in milliseconds
            const differenceInMilliseconds = event.end - event.start
            // Convert the difference to minutes
            const differenceInMinutes = Math.floor(differenceInMilliseconds / 1000 / 60);
            // console.log("Duration in minutes:", differenceInMinutes);

            // const updatedEvents = events.map(event => 
            //     event.id === event.id 
            //         ? {
            //             ...event,
            //             start: event.start,
            //             end: event.end
            //         }
            //         : event
            //     );
            // setEvents(updatedEvents);


            putData(apiLink + '/v1/workorders/' + event.id  , 
                {
                    "workorder_due_date": due_date,
                    "workorder_due_time": due_date_time,
                    "workorder_duration_min": differenceInMinutes,
                })
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        if(typeof getSelectedWorkOrder === 'function') {
                            getWorkOrders();
                        }
                        if(data && data.data && data.data[0] ){
                            toast.success(`${event.title} updated`)
                        }else{
                            toast.error('Resize reverted: Issues updating work order')
                             info.revert();
                        }
                    }
                    else{
                        if(typeof getSelectedWorkOrder === 'function') {
                            getWorkOrders();
                        }
                        info.revert();
                        
                        try{
                            toast.error(data.message)
                        }catch (e){
                            toast.error(e)
                        }
                    }
                })

        }catch(e){
            toast.error(e)
        }
      };

    // Drag and drop handler
    const handleEventDrop = (dropInfo) => {
       
        // console.log('dropInfo', dropInfo)
        // console.log('dropInfo event', dropInfo.event)
        // console.log('Start', dropInfo.event.start)
        // console.log('End', dropInfo.event.end)
        // console.log('ID', dropInfo.event.id)
        // console.log('allDay', dropInfo.event.allDay)
        // console.log('event', dropInfo.oldEvent)
        
        

        if(!dropInfo.event.id) return

        try{
            let end_date = new Date()
            if(dropInfo.event.end){
                end_date = new Date(dropInfo.event.end);
            }else{
                // In case the end date is empty, I'm going to use the start date and add one hour
                if(dropInfo.event.start){
                    end_date = new Date(dropInfo.event.start);
                    end_date.setHours(end_date.getHours() + 1);
                }
            }
            const start_date = new Date(dropInfo.event.start);
            let due_date = end_date.toISOString().split('T')[0];
            let due_date_time = end_date.toTimeString().split(' ')[0];
            // console.log('due_date', due_date)
            // console.log('due_date_time', due_date_time)

            // Calculate the difference in milliseconds
            const differenceInMilliseconds = end_date - start_date;
            // Convert the difference to minutes
            const differenceInMinutes = Math.floor(differenceInMilliseconds / 1000 / 60);
            // console.log("Duration in minutes:", differenceInMinutes);

            // console.log('due_date', due_date)
            // console.log('due_date_time', due_date_time)
            // console.log('differenceInMinutes', differenceInMinutes)

            // console.log('Event Drop:', {
            //     eventId: dropInfo.event.id,
            //     newStart: dropInfo.event.start,
            //     newEnd: dropInfo.event.end,
            //     oldStart: dropInfo.oldEvent.start,
            //     oldEnd: dropInfo.oldEvent.end,
            //     allDay: dropInfo.event.allDay,
            //     duration: dropInfo.event.end - dropInfo.event.start
            //   });




            putData(apiLink + '/v1/workorders/' + dropInfo.event.id , 
                {
                    "workorder_due_date": due_date,
                    "workorder_due_time": due_date_time,
                    "workorder_duration_min": differenceInMinutes,
                })
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        if(typeof getSelectedWorkOrder === 'function') {
                            getWorkOrders();
                        }
                        if(data && data.data && data.data[0] ){
                            toast.success(`${dropInfo.event.title} updated`)
                        }else{
                            toast.error('Issues updating work order')
                            dropInfo.revert();
                        }
                    }
                    else{
                        if(typeof getSelectedWorkOrder === 'function') {
                            getWorkOrders();
                        }
                        dropInfo.revert();
                        
                        try{
                            toast.error(data.message)
                        }catch (e){
                            toast.error(e)
                        }
                    }
                })

        }catch(e){
            toast.error(e)
        }



    };

    // Add new event
    const handleDateSelect = (selectInfo) => {
        // console.log('selectInfo', selectInfo)
        // console.log('SON.stringify', JSON.stringify(selectInfo))

        let startTime = new Date(selectInfo.start);
        let endTime = new Date(selectInfo.end);
        let durationInMillis = endTime - startTime; // Calculate the difference in milliseconds
        let durationInMinutes = durationInMillis / (1000 * 60); // Convert the duration to minutes
        
        const options = {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
          };
        let formattedTime = new Intl.DateTimeFormat('en-US', options).format(endTime);

        // This is when a user clicks on a date in Month mode, I'm resetting the times to 60 mins and start at 9 am
        if(durationInMinutes === 1440){
            durationInMinutes = 60
            formattedTime = '9:00 AM'
        }

        let newEvent = {
            dueDate: selectInfo.endStr,
            duration: durationInMinutes,
            dueDateTime: formattedTime,
        }

        setSelectedDateInfo(newEvent)
        setIsNewWOModalOpen(true)
        
        // if (title) {
        // const testEvent = {
        //     id: `${events.length + 1}`,
        //     title,
        //     start: selectInfo.startStr,
        //     end: selectInfo.endStr,
        //     allDay: selectInfo.allDay,
        //     extendedProps: {
        //     status: 'pending',
        //     client: client || 'Unassigned',
        //     description: ''
        //     }
        // };


        // setEvents([...events, newEvent]);
        // }
    };

    // Event click handler
    const handleEventClick = (clickInfo) => {
        setSelectedEvent(clickInfo.event);
        if(clickInfo.event.extendedProps && clickInfo.event.extendedProps.status){
            let currentStatus = clickInfo.event.extendedProps.status
            let status = statusOptions.find((status) => status.workorder_status_uuid === currentStatus.workorder_status_uuid)
            setSelectedStatus(status)
        }
        setIsDialogOpen(true);
    };

    const handleCloseModal = () => {
        setIsNewWOModalOpen(false)
    }

    const handleCloseInfoModal = () => {
        setIsDialogOpen(false);
        setSelectedEvent(null);
        setSelectedStatus([])
          
    }

    
    const handleStatusChange = (newStatus) => {
        setSelectedStatus(newStatus)
        if(!loadingStatus){
            setLoadingStatus(true)
            putData(apiLink + '/v1/workorders/' + selectedEvent.id , 
            {'workorder_status_uuid': newStatus.workorder_status_uuid})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingStatus(false)
                    getWorkOrders()
                    handleCloseInfoModal()
                }
                else{
                    let status = statusOptions.find((status) => status.id === selectedEvent.extendedProps.status.workorder_status_id)
                    setSelectedStatus(status)
                    setLoadingStatus(false)
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })
        }
      };

    // const handleNavigation = (uuid) => {
    //     if(isMobile){
    //         navigate(`/${selectedOrganization}/work-orders?selected_wo=${uuid}&platform=mobile`)
    //     }else{
    //         navigate(`/${selectedOrganization}/work-orders?selected_wo=${uuid}`)
    //     }
    // }
    const handleNavigation = (uuid) => {
        

        let wo_status = searchParams.getAll('wo_status')
        let wo_priority = searchParams.getAll('wo_priority')
        let sort_by = searchParams.getAll('sort_by')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let q = searchParams.getAll('q')
        
        if(isMobile){
            // navigate(`/${selectedOrganization}/work-orders?selected_wo=${workOrder.workorder_uuid}&platform=mobile`)
            setSearchParams({wo_status,wo_priority, sort_by, from, to, q, selected_wo: uuid, platform: 'mobile'})
        }else{
            // navigate(`/${selectedOrganization}/work-orders?selected_wo=${workOrder.workorder_uuid}`)
            setSearchParams({wo_status,wo_priority, sort_by, from, to, q, selected_wo: uuid})
        }

    }
  

  return (
    <div className={`calendar-container  flex flex-col p-0 shadow-iotflows-lg bg-white dark:bg-transparent text-gray-800 dark:text-gray-300 ${isDark ? 'dark':'light'} rounded-md`}>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={currentView}
        nowIndicator={true}
        nowIndicatorRefresh={300000} // Refresh every 5 minutes (in milliseconds)
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        // viewDidMount={({ view }) => {
        //     // Additional optional tracking
        //     console.log('Current view mounted:', view.type);
        //   }}
        views={{
            dayGridMonth: { type: 'dayGrid', duration: { months: 1 } },
            timeGridWeek: { type: 'timeGrid', duration: { weeks: 1 } },
            timeGridDay: { type: 'timeGrid', duration: { days: 1 } }
          }}
          // Optional: Callback when view changes
        datesSet={(arg) => {
        handleViewChange(arg);
        }}
        editable={true}
        selectable={true}
        selectMirror={true}
        // dayMaxEvents={true}
        dayMaxEvents={5} // Limit to 3 events per day
        events={events}
        eventContent={eventContent}
        select={handleDateSelect}
        eventClick={handleEventClick}
        eventDrop={handleEventDrop}
        eventResize={handleEventResize}
        eventResizableFromStart={true} // Allow resizing from both start and end
        height="auto"
        moreLinkContent={(args) => `+${args.num} more`}
      />

      {isNewWOModalOpen && selectedDateInfo && Object.keys(selectedDateInfo).length > 0 && (
        <NewWorkOrderModal remoteOpenModal={isNewWOModalOpen}  btnType='hideBtn' getWorkOrders={getWorkOrders} selectedDateInfo={selectedDateInfo} handleCloseModal={handleCloseModal} />
      )}

      

      {/* Headless UI Dialog */}
      <Transition appear show={isDialogOpen} as={Fragment}>
        <Dialog 
          as="div" 
          className="relative z-10" 
          onClose={handleCloseInfoModal}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-md transform overflow-hidden rounded-2xl ${isDark?'bg-slate-800':'bg-white'} p-6 text-left align-middle shadow-xl transition-all`}>
                  {selectedEvent && (
                    <>
                      <DialogTitle
                        as="h3"
                        className={`text-lg font-medium leading-6  ${isDark ? 'text-white':'text-gray-900'}`}
                      >
                        {selectedEvent.title}
                      </DialogTitle>
                      <div className={`mt-1 text-sm ${isDark ? 'text-gray-400':'text-gray-500'} cursor-pointer`} >
                        {selectedEvent.extendedProps.description ?(
                            <p>
                            {selectedEvent.extendedProps.description}
                            </p>
                        ):(
                            <div/>
                        )}
                      </div>
                      <div className={`mt-4 space-y-2 text-sm ${isDark ? 'text-gray-300':'text-gray-500'}`}>
                      <div className='flex items-center mb-2.5'>
                          <span className="font-semibold mr-2">Status:</span>{' '}
                          {selectedStatus && Object.keys(selectedStatus).length > 0 ? (
                            <Listbox value={selectedStatus} onChange={handleStatusChange}>
                                {({ open }) => (
                                <div className=" ">
                                    <ListboxButton
                                    className={`flex w-fit items-center py-0.5 pl-2 pr-2 border-0 rounded-full relative ${selectedStatus.color}`}
                                    >
                                    <span className='mr-1'>{selectedStatus.icon}</span>
                                    <span className='truncate text-sm'>{selectedStatus.workorder_status_name}</span>
                                    <ChevronDownIcon
                                        className={`w-5 h-5 ml-1 ${selectedStatus.workorder_status_name == 'Open' ? 'text-white':'text-gray-500'}  transition-transform duration-300 ${
                                        open ? 'rotate-180' : 'rotate-0'
                                        }`}
                                    />
                                    {loadingStatus &&
                                        <div >
                                            <svg aria-hidden="true" className={`absolute top-1 right-1/3 ml-8 w-5 h-5 animate-spin ${isDark ? 'fill-white text-gray-500':'fill-blue-600 text-gray-300'} `} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                            </svg>
                                        </div>
                                    }
                                    </ListboxButton>
                                    <ListboxOptions className={`absolute inset-x-0 max-w-[200px] mx-auto  shadow-iotflows-lg  ${isDark?'bg-slate-700':'bg-white'} z-50  rounded-xl space-y-1.5 shadow-lg mt-1 p-2`}>
                                    {statusOptions.map((status) => (
                                        <ListboxOption
                                        key={status.workorder_status_name}
                                        value={status}
                                        className={({ active }) =>
                                            `cursor-pointer flex items-center m-1 py-0.5 pl-2 pr-4 border-0 rounded-full transition-transform duration-200  ${isDark?`${status.dark_color}`:`${status.color}`}  ${
                                            active ? '-translate-y-0.5 ' : ''
                                            }`
                                        }
                                        >
                                        <div className='mr-1'>
                                            {status.icon}
                                        </div>
                                        <span className='text-sm'>
                                            {status.workorder_status_name}
                                        </span>
                                        </ListboxOption>
                                    ))}
                                    </ListboxOptions>
                                </div>
                                )}
                            </Listbox>
                          ):(
                            <span>{selectedEvent.extendedProps.status.workorder_status_name || 'Pending'}</span>
                          )}
                        </div>
                        {selectedEvent.allDay ? (
                            <div/>
                        ):(
                            <div className='flex mb-2'>
                                <span className="font-semibold pr-2">Due:</span>
                                <span className={`${isDatePastDue(selectedEvent.end?.toLocaleString()) && ` ${isDark ? 'text-red-300':'text-red-600'}`}`}>
                                    {selectedEvent.end?.toLocaleString()}
                                </span>
                            </div>

                        )}
                        {selectedEvent.extendedProps.recurrenceDescription ?(
                            <div className='flex mb-2'>
                                <span className="font-semibold pr-2">Repeat:</span>
                                <span className={`${isDark ? 'text-gray-300':'text-gray-600'}`}>
                                    {selectedEvent.extendedProps.recurrenceDescription}
                                </span>
                            </div>
                        ):(
                            <div/>
                        )}
                        <div className='flex '>
                          <span className="font-semibold">Priority:</span>
                          {selectedEvent.extendedProps.priority && Object.keys(selectedEvent.extendedProps.priority).length && (
                            <div className='flex ml-2 space-x-1 items-center'>
                                <div  className={`${selectedEvent.extendedProps.priority.workorder_priority_icon_color ? selectedEvent.extendedProps.priority.workorder_priority_icon_color : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960"  fill="currentColor" >
                                        <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-160q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Z"/>
                                    </svg>
                                </div>
                                <span className={`text-sm ${isDark ? 'text-gray-300':'text-gray-600'}`}>
                                    {selectedEvent.extendedProps.priority.workorder_priority_name}
                                </span>
                            </div>
                          )}
                        </div>
                        <div className='flex '>
                          <span className="font-semibold">Group:</span>
                          {selectedEvent.extendedProps.group && Object.keys(selectedEvent.extendedProps.group).length && (
                            <div className='flex ml-2 space-x-1 items-center'>
                                <span className={`text-sm `}
                                    style={{color: isDark ? selectedEvent.extendedProps.group.group_dark_color_hex : selectedEvent.extendedProps.group.group_light_color_hex}}
                                >
                                    {selectedEvent.extendedProps.group.group_name}
                                </span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="mt-12">
                        <div className={`flex  ${isDark?' border-gray-700':'border-gray-200'}`}>
                            <button
                                type="button"
                                className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                onClick={() => setIsDialogOpen(false)}
                            >
                                Close
                            </button>
                            <button 
                                className={`group text-sm flex font-medium items-center ml-auto  py-2 ${isDark?' text-blue-400 hover:text-blue-500':'text-blue-500 hover:text-blue-700'} border-transparent border`} 
                                onClick={() => handleNavigation(selectedEvent.id)}
                            >
                                <span className='pb-0.5'>Go to Work Order</span>
                                <svg className="relative ml-1.5 stroke-2 fill-[none] stroke-current" width="10" height="10" viewBox="0 0 10 10" aria-hidden="true">
                                    <g fillRule="evenodd"> 
                                        <path className="opacity-0 transition-all group-hover:opacity-100" d="M0 5h7"></path>
                                        <path className="transition-all group-hover:translate-x-1" d="M1 1l4 4-4 4"></path>
                                    </g>
                                </svg>
                            </button>
                            </div>
                      </div>
                    </>
                  )}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default WOCalendarView