import { CalendarIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ClockIcon } from '@heroicons/react/solid';
import { Avatar, Badge, Button, Card } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Resizable } from 'react-resizable';
import WorkOrderTableView from '../tableViewComponents/WorkOrderTableView';
import RoadmapTableRow from './RoadmapTableRow';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilState } from 'recoil';
import { useLocation, useSearchParams } from 'react-router-dom';

const GanttChart = ({isMobile, selectedOrganization, workOrdersList, getWorkOrders}) => {
    const scrollContainerRef = useRef(null);
    const [isDark, setIsDark] = useRecoilState(darkModeState)
    const [viewMode, setViewMode] = useState('daily'); // 'daily' or 'monthly'
    const scrollStep = 200;
    const [leftColumnWidth, setLeftColumnWidth] = useState(() => {
        const storedWidth = localStorage.getItem('roadmapLeftColumnWidth');
        return storedWidth ? parseInt(storedWidth, 10) : 800;
      });
    const [tasks, setTasks] = useState([])
    const [selectedWorkOrderUuid, setSelectedWorkOrderUuid] = useState('')
    const [expandedTasks, setExpandedTasks] = useState(new Set([])); // Set([1, 3]) the 1 and 3 is suppose to be the workorder_uuid
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()


    const minWidth = 400;
    const maxWidth = 2000;
    const columnWidth = {
        'daily': 40,
        'monthly': 10,
        'yearly': 5
      }[viewMode];

      useEffect(() => {
        let selected_workOrder_uuid = searchParams.get('selected_wo')
        setSelectedWorkOrderUuid(selected_workOrder_uuid)
        
    },[location])

      useEffect(() => {
        let selected_workOrder_uuid = searchParams.get('selected_wo')
        
        if(selected_workOrder_uuid && selected_workOrder_uuid.length > 0){
            setLeftColumnWidth(400)
        }else{
            const storedWidth = localStorage.getItem('roadmapLeftColumnWidth');
            if(storedWidth){
                console.log('parseInt(storedWidth, 10)', parseInt(storedWidth, 10))
                setLeftColumnWidth(parseInt(storedWidth, 10))
            }else{
                setLeftColumnWidth(800)
            }
            // storedWidth ? setLeftColumnWidth(storedWidth) : setLeftColumnWidth(800);
                
        }
      },[location])

      useEffect(() => {
          const storedWidth = localStorage.getItem('roadmapLeftColumnWidth');
          if(storedWidth == 'NaN'){
              localStorage.setItem('roadmapLeftColumnWidth', '800');
            }
        if(workOrdersList && Object.keys(workOrdersList).length > 0){
            let tempTasks = []
            {workOrdersList.map((section) => (
                section && section.workorders && Object.keys(section.workorders).length > 0 && section.workorders.map((currentWorkOrder, index) => {

                    let currentTask = currentWorkOrder
                    currentTask.startDate = null
                    currentTask.endDate = null
                    currentTask.level = 0
                    if(currentWorkOrder.workorder_uuid === "wo_226173fc5a9562e4c7b493fc56f26be6"){ //Testing Chat UUID
                        // currentTask.children = index === 0 ? [section.workorders[index + 1].workorder_uuid] : null
                        currentTask.children = ["wo_3fb4b10ef3fcc668d01863d97604e6bb"] // WO "Orange color status for initial downtime"
                    }
                    if(currentWorkOrder.workorder_uuid === "wo_3fb4b10ef3fcc668d01863d97604e6bb"){ // UUID for Orange color status for initial downtime"
                        currentTask.parentId = "wo_226173fc5a9562e4c7b493fc56f26be6"
                        currentTask.level = 1
                        currentTask.children = ["wo_ca317d5defd78479bfc780e3639f8848"] // Assigning DO 2024 Taxes
                    }
                    if(currentWorkOrder.workorder_uuid === "wo_ca317d5defd78479bfc780e3639f8848"){ // UUID for DO 2024 Taxes
                        currentTask.parentId = "wo_3fb4b10ef3fcc668d01863d97604e6bb"
                        currentTask.level = 2
                    }

                    

                    let utcDate = new Date();  
                    if(currentWorkOrder.workorder_due_timestamp){
                        utcDate = new Date(currentWorkOrder.workorder_due_timestamp);
                        let offset = utcDate.getTimezoneOffset(); 
                        currentTask.endDate = new Date(utcDate.getTime() - offset * 60000);
                        
                        let duration = 60
                        if(currentWorkOrder.workorder_duration_min){
                            duration = currentWorkOrder.workorder_duration_min
                        }
                        currentTask.startDate = new Date(currentTask.endDate.getTime() - duration * 60000  )

                    }

                    tempTasks.push(currentTask)
                })
            ))}
            setTasks(tempTasks)
            // setExpandedTasks(new Set(tempTasks.filter(task => task.children && task.children.length > 0).map(task => task.workorder_uuid)));
        }else{
            setTasks([])
        }

      },[workOrdersList])

  // Sample data with parent-child relationships
  const temp_taskssss = [
    {
      id: 1,
      title: "Product Launch",
      status: "In Progress",
      priority: "High",
      assignees: [
        { name: "Alice", image: "/api/placeholder/32/32" },
        { name: "Bob", image: "/api/placeholder/32/32" }
      ],
      startDate: new Date(2024, 10, 1),
      endDate: new Date(2025, 2, 15),
      children: [2, 3],
      level: 0
    },
    {
      id: 2,
      title: "Design System",
      status: "In Progress",
      priority: "High",
      assignees: [
        { name: "Charlie", image: "/api/placeholder/32/32" }
      ],
      startDate: new Date(2025, 1, 1),
      endDate: new Date(2025, 1, 15),
      parentId: 1,
      level: 1
    },
    {
      id: 3,
      title: "Development",
      status: "Planned",
      priority: "Medium",
      assignees: [
        { name: "David", image: "/api/placeholder/32/32" }
      ],
      startDate: new Date(2025, 1, 15),
      endDate: new Date(2025, 2, 15),
      parentId: 1,
      children: [4],
      level: 1
    },
    {
      id: 4,
      title: "Backend API",
      status: "Planned",
      priority: "High",
      assignees: [
        { name: "Eve", image: "/api/placeholder/32/32" }
      ],
      startDate: new Date(2025, 1, 20),
      endDate: new Date(2025, 2, 10),
      parentId: 3,
      level: 2
    },
    {
      id: 5,
      title: "Work Order",
      status: "Planned",
      priority: "Medium",
      assignees: [
        { name: "David", image: "/api/placeholder/32/32" }
      ],
      startDate: new Date(2025, 5, 15),
      endDate: new Date(2025, 7, 15),
      children: [6,7],
      level: 0
    },
    {
      id: 6,
      title: "Task 1",
      status: "Planned",
      priority: "High",
      assignees: [
        { name: "Eve", image: "/api/placeholder/32/32" }
      ],
      startDate: new Date(2025, 5, 20),
      endDate: new Date(2025, 6, 10),
      parentId: 5,
      level: 1
    },
    {
      id: 7,
      title: "Task 2",
      status: "Planned",
      priority: "High",
      assignees: [
        { name: "Eve", image: "/api/placeholder/32/32" }
      ],
      startDate: new Date(2025, 6, 20),
      endDate: new Date(2025, 7, 10),
      parentId: 5,
      level: 1
    }
  ];

  

  const toggleTask = (taskId) => {
    // console.log('taskId', taskId, typeof taskId); // Log the value and type of taskId
    const newExpanded = new Set(expandedTasks);
    // console.log('newExpanded', newExpanded); // Log the current state of expandedTasks
    if (newExpanded.has(taskId)) {
      newExpanded.delete(taskId);
    } else {
      newExpanded.add(taskId);
    }
    setExpandedTasks(newExpanded);
  };

  const startDate = useMemo(() => {
    if (tasks.length === 0) return null; // Handle empty tasks array
    const validStartDates = tasks.map(task => task.startDate).filter(date => date instanceof Date && !isNaN(date));
    if (validStartDates.length === 0) return null; // Handle case where no valid start dates exist
    const minDate = new Date(Math.min(...validStartDates));
    minDate.setDate(minDate.getDate() - 7); // Subtract 1 week (7 days) from the startDate
    return minDate;
  }, [tasks]);
  
  const endDate = useMemo(() => {
    if (tasks.length === 0) return null; // Handle empty tasks array
    const validEndDates = tasks.map(task => task.endDate).filter(date => date instanceof Date && !isNaN(date));
    if (validEndDates.length === 0) return null; // Handle case where no valid end dates exist
    const maxDate = new Date(Math.max(...validEndDates));
    maxDate.setMonth(maxDate.getMonth() + 1); // Add 1 month to the endDate
    return maxDate;
  }, [tasks]);
//   const startDate = useMemo(() => {
//     if (tasks.length === 0) return null; // Handle empty tasks array
//     const validStartDates = tasks.map(task => task.startDate).filter(date => date instanceof Date && !isNaN(date));
//     if (validStartDates.length === 0) return null; // Handle case where no valid start dates exist
//     return new Date(Math.min(...validStartDates));
//   }, [tasks]);
  
//   const endDate = useMemo(() => {
//     if (tasks.length === 0) return null; // Handle empty tasks array
//     const validEndDates = tasks.map(task => task.endDate).filter(date => date instanceof Date && !isNaN(date));
//     if (validEndDates.length === 0) return null; // Handle case where no valid end dates exist
//     return new Date(Math.max(...validEndDates));
//   }, [tasks]);
  
  
  const totalDays = useMemo(() => {
    if (!startDate || !endDate) return 0; // Handle invalid dates
    return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
  }, [startDate, endDate]);

    // const startDate = new Date(Math.min(...tasks.map(task => task.startDate)));
    // const endDate = new Date(Math.max(...tasks.map(task => task.endDate)));
    // const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));


    const timelineDates = useMemo(() => {
        if (startDate && endDate) {
            const dates = [];
            const current = new Date(startDate);
        
            while (current <= endDate) {
            if (viewMode === 'daily') {
                dates.push(new Date(current));
                current.setDate(current.getDate() + 1);
            } else if (viewMode === 'monthly') {
                dates.push(new Date(current));
                current.setMonth(current.getMonth() + 1);
                current.setDate(1);
            } else if (viewMode === 'yearly') {
                dates.push(new Date(current));
                current.setFullYear(current.getFullYear() + 1);
                current.setMonth(0);
                current.setDate(1);
            }
            }
        
            return dates;
        }
        return []; // Return an empty array if startDate or endDate is invalid
        }, [startDate, endDate, viewMode]);
//  const timelineDates = useMemo(() => {
//     if (startDate && endDate) {
//       const dates = [];
//       const current = new Date(startDate);
  
//       while (current <= endDate) {
//         if (viewMode === 'daily') {
//           dates.push(new Date(current));
//           current.setDate(current.getDate() + 1);
//         } else if (viewMode === 'monthly') {
//           dates.push(new Date(current));
//           current.setMonth(current.getMonth() + 1);
//           current.setDate(1);
//         } else if (viewMode === 'yearly') {
//           dates.push(new Date(current));
//           current.setFullYear(current.getFullYear() + 1);
//           current.setMonth(0);
//           current.setDate(1);
//         }
//       }
  
//       return dates;
//     }
//     return []; // Return an empty array if startDate or endDate is invalid
//   }, [startDate, endDate, viewMode]);

  const formatDate = (date) => ({
    day: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()],
    date: date.getDate(),
    month: [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ][date.getMonth()],
    year: date.getFullYear()
  });

  

  const getTaskPosition = (task) => {
    const timelineStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const taskStartDays = Math.ceil((task.startDate - timelineStart) / (1000 * 60 * 60 * 24));
    const taskEndDays = Math.ceil((task.endDate - timelineStart) / (1000 * 60 * 60 * 24));
    const taskDuration = taskEndDays - taskStartDays;
      
      
    

    if (viewMode === 'monthly') {
        const taskStartMonths = (task.startDate.getFullYear() - timelineStart.getFullYear()) * 12 + 
                                (task.startDate.getMonth() - timelineStart.getMonth());
        const taskEndMonths = (task.endDate.getFullYear() - timelineStart.getFullYear()) * 12 + 
                              (task.endDate.getMonth() - timelineStart.getMonth());
        const taskDurationMonths = taskEndMonths - taskStartMonths + 1;
        
        
        const daysInMonth = getDaysInMonth(task.startDate.getFullYear(), task.startDate.getMonth());
        const startDayFraction = task.startDate.getDate() / daysInMonth;
        const endDayFraction = task.endDate.getDate() / daysInMonth;
        
        
        let monthWidth = (taskDurationMonths - 1 + endDayFraction - startDayFraction) * (columnWidth * 30)
        if(monthWidth == 0){
            monthWidth = 5
        }
        return {
            left: `${(taskStartMonths + startDayFraction) * (columnWidth * 30)}px`,
            width: `${monthWidth}px`
            // width: `${(taskDurationMonths - 1 + endDayFraction - startDayFraction) * (columnWidth * 30)}px`
        };
    } else if (viewMode === 'yearly') {
        const taskStartYears = task.startDate.getFullYear() - timelineStart.getFullYear();
        const taskEndYears = task.endDate.getFullYear() - timelineStart.getFullYear();
        const taskDurationYears = taskEndYears - taskStartYears + 1;

        const daysInYear = getDaysInYear(task.startDate.getFullYear());
        const startDayFraction = (task.startDate - new Date(task.startDate.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24) / daysInYear;
        const endDayFraction = (task.endDate - new Date(task.endDate.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24) / daysInYear;

        // if(task.workorder_uuid === "wo_e96b57d47d244db3b08a3bb084eac508"){
        //     console.log('workorder_title', task.workorder_title)
        //     console.log('taskDurationYears', taskDurationYears)
        //     console.log('startDayFraction', startDayFraction)
        //     console.log('endDayFraction', endDayFraction)
        //     console.log('(taskDurationYears - 1 + endDayFraction - startDayFraction) * (columnWidth * 365)', (taskDurationYears - 1 + endDayFraction - startDayFraction) * (columnWidth * 365))
        // }

        return {
            left: `${(taskStartYears + startDayFraction) * (columnWidth * 365)}px`,
            width: `${(taskDurationYears - 1 + endDayFraction - startDayFraction) * (columnWidth * 365)}px`
        };
    }


    if(taskDuration == 0){
        return {
            left: `${taskStartDays * columnWidth}px`,
            width: `${0.3 * columnWidth}px`
        };
    }else{
        return {
            left: `${taskStartDays * columnWidth}px`,
            width: `${taskDuration * columnWidth}px`
        };
    }

    };


const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
const getDaysInYear = (year) => (new Date(year, 11, 31) - new Date(year, 0, 0)) / (1000 * 60 * 60 * 24);

const getCurrentDayMarkerPosition = () => {
    if(timelineDates){
        const today = new Date();
        if (viewMode === 'daily') {
            return timelineDates.findIndex(date => date.toDateString() === today.toDateString()) * columnWidth;
        } else if (viewMode === 'monthly') {
            const timelineStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            const monthsFromStart = (today.getFullYear() - timelineStart.getFullYear()) * 12 + 
                                    (today.getMonth() - timelineStart.getMonth());
            const daysInMonth = getDaysInMonth(today.getFullYear(), today.getMonth());
            const dayFraction = today.getDate() / daysInMonth;
            return (monthsFromStart + dayFraction) * (columnWidth * 30);
        } else if (viewMode === 'yearly') {
            const timelineStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            const yearsFromStart = today.getFullYear() - timelineStart.getFullYear();
            const daysInYear = getDaysInYear(today.getFullYear());
            const dayFraction = (today - new Date(today.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24) / daysInYear;
            return (yearsFromStart + dayFraction) * (columnWidth * 365);
        }
        return -1;
    }
};


const renderTimelineHeader = () => {
    if (timelineDates) {
      const headerContent = {
        daily: () => (
          <div className="flex flex-col">
            <div className="flex">
              {timelineDates.reduce((acc, date, index) => {
                const formatted = formatDate(date);
                const currentMonth = `${formatted.month} ${formatted.year}`;
  
                // If this is the first date or the month has changed
                if (index === 0 || currentMonth !== formatDate(timelineDates[index - 1]).month + ' ' + formatDate(timelineDates[index - 1]).year) {
                  // Find how many days are in this month
                  const monthDays = timelineDates.slice(index).findIndex(
                    (d, idx) =>
                      idx > 0 &&
                      (formatDate(d).month + ' ' + formatDate(d).year) !== currentMonth
                  );
  
                  const daysInMonth = monthDays === -1 ? timelineDates.length - index : monthDays;
  
                  acc.push(
                    <div
                      key={`month-${index}`}
                      className="flex-none text-xs py-1 text-center font-medium text-gray-600 dark:text-gray-100 bg-gray-100 dark:bg-gray-800 border-r border-slate-300 dark:border-slate-600"
                      style={{ width: `${columnWidth * daysInMonth}px` }}
                    >
                      {currentMonth}
                    </div>
                  );
                }
                return acc;
              }, [])}
            </div>
            <div className="flex ">
              {timelineDates.map((date, index) => {
                const formatted = formatDate(date);
                return (
                  <div
                    key={index}
                    className="flex-none text-xs text-center border-r border-b border-slate-300 dark:border-slate-600"
                    style={{ width: `${columnWidth}px` }}
                  >
                    <div className="text-gray-500 dark:text-gray-400">{formatted.day}</div>
                    <div className='text-gray-600 dark:text-gray-300'>{formatted.date}</div>
                  </div>
                );
              })}
            </div>
          </div>
        ),
        monthly: () => (
            <div className="flex">
              {timelineDates.map((date, index) => {
                const formatted = formatDate(date);
                const daysInMonth = getDaysInMonth(date.getFullYear(), date.getMonth());
                
                return (
                  <div
                    key={index}
                    className="flex-none text-base py-4 text-center text-gray-600 dark:text-gray-300 font-medium border-[0.01em] border-l-0 border-slate-300 dark:border-slate-600"
                    style={{ width: `${columnWidth * daysInMonth}px` }}
                  >
                    {`${formatted.month} ${formatted.year}`}
                  </div>
                );
              })}
            </div>
          ),
        yearly: () => {
            // Helper function to get days in a month
            const getDaysInMonth = (year, month) => {
              return new Date(year, month + 1, 0).getDate();
            };
    
            // Group timelineDates by year
            const groupedByYear = timelineDates.reduce((acc, date) => {
              const year = date.getFullYear();
              if (!acc[year]) {
                acc[year] = new Set(); // Use a Set to store unique months
              }
              acc[year].add(date.getMonth());
              return acc;
            }, {});
    
            return (
              <div className="flex flex-col">
                {/* Render year headers */}
                <div className="flex">
                  {Object.keys(groupedByYear).map((year) => {
                    // Calculate total days in the year
                    const totalDays = Array.from({ length: 12 }, (_, month) => 
                      getDaysInMonth(parseInt(year), month)
                    ).reduce((sum, days) => sum + days, 0);
    
                    return (
                      <div
                        key={year}
                        className="flex-none text-base text-center text-gray-600 dark:text-gray-100 bg-gray-100 dark:bg-gray-800 border-r border-slate-300 dark:border-slate-600 font-medium py-0.5"
                        style={{ width: `${columnWidth * totalDays}px` }}
                      >
                        {year}
                      </div>
                    );
                  })}
                </div>
                {/* Render month headers below each year */}
                <div className="flex">
                  {Object.keys(groupedByYear).map((year) => {
                    const months = Array.from({ length: 12 }, (_, i) => i);
                    return months.map((monthIndex) => {
                      const daysInMonth = getDaysInMonth(parseInt(year), monthIndex);
                      const monthName = new Date(parseInt(year), monthIndex).toLocaleString('default', { month: 'short' });
                      
                      return (
                        <div
                          key={`${year}-${monthIndex}`}
                          className="flex-none text-sm text-center text-gray-600 dark:text-gray-300 border-r border-b border-slate-300 dark:border-slate-600 py-1"
                          style={{ width: `${columnWidth * daysInMonth}px` }}
                        >
                          {`${monthName} '${year.slice(-2)}`}
                        </div>
                      );
                    });
                  })}
                </div>
              </div>
            );
          },
      };
  
      const markerPosition = getCurrentDayMarkerPosition();
      
  
      return (
        <div className="relative">
          {headerContent[viewMode]()}
          {markerPosition >= 0 && (
            <div
              className="absolute top-0 h-full w-[2px] bg-violet-400 dark:bg-violet-900 rounded-full z-10"
              style={{ left: `${markerPosition}px` }}
            />
          )}
        </div>
      );
    }
  };

  const renderTimelineGrid = () => {
    if (timelineDates) {
      const gridContent = {
        daily: () => (
          <div className="flex absolute top-0 left-0 w-full h-full">
            {timelineDates.map((date, index) => (
              <div
                key={index}
                className="flex-none border-r border-slate-100 dark:border-slate-700/30"
                style={{ width: `${columnWidth}px` }}
              />
            ))}
          </div>
        ),
        monthly: () => (
          <div className="flex absolute top-0 left-0 w-full h-full">
            {timelineDates.map((date, index) => {
              const daysInMonth = getDaysInMonth(date.getFullYear(), date.getMonth());
              return (
                <div
                  key={index}
                  className="flex-none border-r border-slate-100 dark:border-slate-700/60"
                  style={{ width: `${columnWidth * daysInMonth}px` }}
                />
              );
            })}
          </div>
        ),
        yearly: () => {
          // Group timelineDates by year and then by month
          const groupedByYear = timelineDates.reduce((acc, date) => {
            const year = date.getFullYear();
            if (!acc[year]) {
              acc[year] = new Set(); // Use a Set to store unique months
            }
            acc[year].add(date.getMonth());
            return acc;
          }, {});
  
          return (
            <div className="flex absolute top-0 left-0 w-full h-full">
              {Object.keys(groupedByYear).map((year) => {
                const months = Array.from({ length: 12 }, (_, i) => i); // All 12 months
                return months.map((monthIndex) => {
                  const daysInMonth = getDaysInMonth(parseInt(year), monthIndex);
                  return (
                    <div
                      key={`${year}-${monthIndex}`}
                      className="flex-none border-r border-slate-100 dark:border-slate-700/80"
                      style={{ width: `${columnWidth * daysInMonth}px` }}
                    />
                  );
                });
              })}
            </div>
          );
        },
      };
  
      return gridContent[viewMode]();
    }
  };


  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'completed': return 'bg-green-500';
      case 'in progress': return 'bg-blue-500';
      case 'planned': return 'bg-gray-500';
      default: return 'bg-gray-500';
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority.toLowerCase()) {
      case 'high': return 'bg-red-500';
      case 'medium': return 'bg-yellow-500';
      case 'low': return 'bg-green-500';
      default: return 'bg-gray-500';
    }
  };

  const isVisible = (task) => {
    if (task.level === 0) return true;
    const parent = tasks.find(t => t.workorder_uuid === task.parentId);
    return parent && expandedTasks.has(parent.workorder_uuid) && (parent.level === 0 || isVisible(parent));
  };

  const visibleTasks = tasks.filter(isVisible);

  const handleResize = (event, { size }) => {
    const newWidth = Math.max(minWidth, Math.min(maxWidth, size.width));
    setLeftColumnWidth(newWidth);
    // localStorage.setItem('newWidth', newWidth);
    localStorage.setItem('roadmapLeftColumnWidth', newWidth.toString());

  };

  const scrollToToday = () => {
        const today = new Date();
        let scrollPosition = -1;

        if (viewMode === 'daily') {
            const todayIndex = timelineDates.findIndex(date => date.toDateString() === today.toDateString());
            scrollPosition = todayIndex * columnWidth;
        } else if (viewMode === 'monthly') {
            const todayIndex = timelineDates.findIndex(date => 
                date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
            );
            scrollPosition = todayIndex * (columnWidth * 30);
        } else if (viewMode === 'yearly') {
            const todayIndex = timelineDates.findIndex(date => 
                date.getFullYear() === today.getFullYear()
            );
            scrollPosition = todayIndex * (columnWidth * 365);
        }

        if (scrollPosition !== -1 && scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({
                left: scrollPosition,
                behavior: "smooth"
            });
        }
    };

    const navToChat = (workOrder) => {
        let wo_status = searchParams.getAll('wo_status')
        let wo_priority = searchParams.getAll('wo_priority')
        let sort_by = searchParams.getAll('sort_by')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let q = searchParams.getAll('q')
        
        setSearchParams({wo_status,wo_priority, sort_by, from, to, q, selected_wo: workOrder.workorder_uuid})
   }


  return (
    <div className=" w-full  shadow-iotflows-lg pb-4 rounded-2xl">
        <div className='flex justify-end p-2'>
            <div className="flex space-x-1 mr-2">
                <button 
                    className="bg-slate-200/80 dark:bg-slate-700 hover:bg-slate-300/80 dark:hover:bg-slate-600 p-1 rounded  text-gray-500 dark:text-gray-300"
                    onClick={() => {
                    if (scrollContainerRef.current) {
                        scrollContainerRef.current.scrollBy({ left: -scrollStep, behavior: "smooth" });
                    }
                    }}
                >
                    <ChevronLeftIcon className='w-5 h-5 ' />
                </button>
                <button 
                    className="bg-slate-200/80 dark:bg-slate-700 hover:bg-slate-300/80 dark:hover:bg-slate-600 py-1 px-2 rounded text-gray-600 dark:text-gray-300 text-sm"
                    onClick={scrollToToday}
                >
                    Today
                </button>
                <button 
                    className="bg-slate-200/80 dark:bg-slate-700 hover:bg-slate-300/80 dark:hover:bg-slate-600 p-1 rounded  text-gray-500 dark:text-gray-300"
                    onClick={() => {
                    if (scrollContainerRef.current) {
                        scrollContainerRef.current.scrollBy({ left: scrollStep, behavior: "smooth" });
                    }
                    }}
                >
                    <ChevronRightIcon className='w-5 h-5 ' />
                </button>
            </div>
            <div className="relative inline-block min-w-[60px]">
                <select
                    value={viewMode}
                    onChange={(e) => setViewMode(e.target.value)}
                    className="form-select appearance-none block w-full pl-3 pr-8 py-1 text-sm font-normal text-gray-600 dark:text-gray-300 bg-slate-200/80 dark:bg-slate-700 hover:bg-slate-300/80 dark:hover:bg-slate-600 bg-clip-padding bg-no-repeat border-0 rounded transition ease-in-out m-0 focus:ring-0 focus:outline-none"
                >
                    <option value="daily">Day</option>
                    <option value="monthly">Month</option>
                    <option value="yearly">Year</option>
                </select>
            </div>

        </div>
      <div style={{display: 'grid', gridTemplateColumns: `${leftColumnWidth}px auto`}} >
        <Resizable
            width={leftColumnWidth}
            height={200} // I could add 100% since its not a number, put 200 to see what happens
            // minWidth={minWidth}
            // maxWidth={maxWidth}
            // axis='x'
            onResize={handleResize}
        >
            <div className={`pr-0.5`}>
                <div className={`overflow-x-auto message_window_scrollbar ${isDark ? 'scrollbar_dark' : 'scrollbar_light'}`}>
                        <div 
                            className="font-medium text-gray-500 dark:text-gray-400 text-sm "
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'minmax(400px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(170px, 1fr)  minmax(130px, 1fr) minmax(175px, 1fr) minmax(300px, 1fr) minmax(175px, 1fr) minmax(125px, 1fr) minmax(125px, 1fr) ',
                                gap: '0px',
                            }}
                        >
                            <div className={`pb-2 pt-7 px-2 border-b border-slate-200 dark:border-slate-700 truncate pl-6`}>Title</div>
                            <div className={`pb-2 pt-7 px-2 border-b border-slate-200 dark:border-slate-700 truncate text-center`}>Status</div>
                            <div className={`pb-2 pt-7 px-2 border-b border-slate-200 dark:border-slate-700 truncate`}>Group</div>
                            <div className={`pb-2 pt-7 px-2 border-b border-slate-200 dark:border-slate-700 truncate`}>Due Date</div>
                            <div className={`pb-2 pt-7 px-2 border-b border-slate-200 dark:border-slate-700 truncate`}>Assigned To</div>
                            <div className={`pb-2 pt-7 px-2 border-b border-slate-200 dark:border-slate-700 truncate`}>Asset</div>
                            <div className={`pb-2 pt-7 px-2 border-b border-slate-200 dark:border-slate-700 truncate`}>Recurrence</div>
                            <div className={`pb-2 pt-7 px-2 border-b border-slate-200 dark:border-slate-700 truncate `}>Created By</div>
                            <div className={`pb-2 pt-7 px-2 border-b border-slate-200 dark:border-slate-700 truncate text-center`}>Created On</div>
                            <div className={`pb-2 pt-7 px-2 border-b border-slate-200 dark:border-slate-700 truncate text-center`}>Updated On</div>
                        </div>
                        <div className="">
                            {visibleTasks.map(task => (
                                <RoadmapTableRow key={task.workorder_uuid} task={task} toggleTask={toggleTask} expandedTasks={expandedTasks} isDark={isDark} getWorkOrders={getWorkOrders} selectedWorkOrderUuid={selectedWorkOrderUuid} />
                            
                            ))}
                        </div>
                </div>      
            </div>
        </Resizable>
        {tasks && Object.keys(tasks).length > 0 && timelineDates && Object.keys(timelineDates).length > 0 && (
        <div ref={scrollContainerRef} className={`overflow-x-auto border-l border-b border-slate-200 dark:border-slate-700 message_window_scrollbar ${isDark ? 'scrollbar_dark' : 'scrollbar_light'}`}>
            {renderTimelineHeader()}
            <div className="relative">
                {renderTimelineGrid()}
                <div className="absolute top-0 h-full w-[2px] bg-violet-400 dark:bg-violet-900 rounded-full z-10" style={{ left: `${getCurrentDayMarkerPosition()}px` }} />
                {visibleTasks.map((task) => (
                <div key={`gantt-${task.workorder_uuid}`} className="relative mt-0" style={{ height: '56px' }}>
                    <div
                    className={`absolute h-6 top-1/4 rounded bg-gray-200 dark:bg-gray-800 cursor-pointer`}
                    style={getTaskPosition(task)}
                    onClick={() => navToChat(task)} 
                    >
                    <div className="text-xs text-gray-600 dark:text-white p-1 whitespace-nowrap">
                        {task.workorder_title}
                    </div>
                    </div>
                </div>
                ))}
            </div>
            </div>
        )}
      </div>
    </div>
  );
};

export default GanttChart;

