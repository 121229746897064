import { useEffect } from 'react'
import { useState } from 'react'
import { Popover, Transition, PopoverPanel, PopoverButton } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'
import { motion } from "framer-motion"
import { useLocation, useSearchParams } from 'react-router-dom'
import { getData } from '../../../ApiCalls/DataApis'
import { apiLink } from '../../../ApiCalls/ApisVariables'

// const priorities = [
//     {
//         workorder_priority_uuid: 'pri_none',
//         workorder_priority_name: 'None',
//     },
//     {
//         workorder_priority_uuid: 'sev_low',
//         workorder_priority_name: 'Low',
//     },
//     {
//         workorder_priority_uuid: 'sev_medium',
//         workorder_priority_name: 'Medium',
//     },
//     {
//         workorder_priority_uuid: 'sev_high',
//         workorder_priority_name: 'High',
//     },
// ]
function PriorityWOFilterBtn() {
   const [allPriorities, setAllPriorities] = useState([])
   const [selectedPriority, setSelectedPriority] = useState([])
   const [checkedPriorityState, setCheckedPriorityState] = useState([])
   const [searchParams, setSearchParams] = useSearchParams()
   const location = useLocation();
   

   useEffect(() => {
        getData(apiLink + '/v1/workorder_priorities' )
        .then(async response => {
            // JSON data parsed by response.json() call
            if(response.ok ){
            const data = await response.json();
                if(data && data.data){
                // console.log('data.data', data.data)
                setAllPriorities(data.data)
                }
            }
        })
   },[])



   useEffect(() => {
    if(allPriorities && Object.keys(allPriorities).length > 0){
        // Gettting search parameters from URL
        let currentSearch = location.search
            if(currentSearch){
                // Pulling all parts search parameters into an array
                let searchPriorities = searchParams.getAll('wo_priority')
    
                let updatedCheckedState = allPriorities.map((item, index) =>
                    searchPriorities.includes(item.workorder_priority_uuid) ? true : false
                );
    
                let selectedSearchedDepartments = [] 
                updatedCheckedState.map((checked,index) =>
                    checked ? selectedSearchedDepartments.push(allPriorities[index]) : null
                )
                setSelectedPriority(selectedSearchedDepartments)
                setCheckedPriorityState(updatedCheckedState)
            }else {
                setCheckedPriorityState(new Array(allPriorities.length).fill(false))
                setSelectedPriority([])
            }
    }

    }, [location, allPriorities])




   const handleChangePriorityState = (position) => {
        let updatedCheckedState = checkedPriorityState.map((item, index) =>
        index === position ? !item : item
        );
        setCheckedPriorityState(updatedCheckedState)
        
    }

    const handleClear = () => {
        let select = searchParams.getAll('select')
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machines = searchParams.getAll('machines') 
        let machine_types = searchParams.getAll('machine_types') 
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let maximize = searchParams.getAll('maximize')
        let view = searchParams.getAll('view')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let only_with_notes = searchParams.getAll('only_with_notes')
        let time_period = searchParams.getAll('time_period')
        let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')

        let q = searchParams.getAll('q')
        let selected_wo = searchParams.getAll('selected_wo')
        let wo_status = searchParams.getAll('wo_status')
        let wo_group = searchParams.getAll('wo_group')
        
        setSearchParams({
            select, parts, departments, machines, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, maximize, 
            view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled,from, to,  downtime_severity_uuids, only_with_notes, time_period, q, selected_wo, wo_status, wo_group
        })

    }

    const handleApplyFilter = () => {
        // putting all selected parts into the button for visualization
        let tempDepartment = []
        checkedPriorityState.map((state, index) =>{
            if(state){
                tempDepartment.push(allPriorities[index]) 
            }
        })
        setSelectedPriority(tempDepartment)

        // putting each part ID into an array to push it to the URL
        let parameters = []
        tempDepartment.map(dep => {
            parameters.push(dep.workorder_priority_uuid)
        })
        let select = searchParams.getAll('select')
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machines = searchParams.getAll('machines') 
        let machine_types = searchParams.getAll('machine_types') 
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let maximize = searchParams.getAll('maximize')
        let view = searchParams.getAll('view')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let only_with_notes = searchParams.getAll('only_with_notes')
        let time_period = searchParams.getAll('time_period')
        let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')
        
        let q = searchParams.getAll('q')
        let selected_wo = searchParams.getAll('selected_wo')
        let wo_status = searchParams.getAll('wo_status')
        let wo_group = searchParams.getAll('wo_group')
        
        setSearchParams({
            select, parts, departments, machines, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, maximize, 
            view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled,from, to,  downtime_severity_uuids, only_with_notes, time_period, q, selected_wo, wo_status, wo_priority: parameters, wo_group
        })
    }

  return (
    <div >
        <Popover className="relative">
        {({ open, close }) => (
          <>
            <PopoverButton
              className={`
                ${open ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none max-w-[250px] `}
            >
                <motion.button  whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.90 }}   
                    className='bg-white w-3 h-3 rounded-full m-1'
                    onClick={(e) => {
                        if(Object.keys(selectedPriority).length > 0){
                            e.stopPropagation();
                            handleClear();
                        }
                    }}  
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 ${Object.keys(selectedPriority).length > 0? ' rotate-45 ':'rotate-0'}  h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                </motion.button>
                <div className='pl-1 text-sm text-gray-600 dark:text-gray-300'>
                    Priority
                </div>
                {Object.keys(selectedPriority).length > 0 && Object.keys(selectedPriority).length < 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    {selectedPriority.map((filtered,index) => (
                        <div key={filtered.workorder_priority_uuid} className='flex '>
                        {index > 0 && <div className='text-blue-600 dark:text-blue-400 text-xs'>,</div>}
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{filtered.workorder_priority_name}</div>
                        </div>
                    ))}
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
                {Object.keys(selectedPriority).length >= 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    <div className='flex '>
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedPriority[0].workorder_priority_name} and {Object.keys(selectedPriority).length - 1} more</div>
                    </div>
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
            </PopoverButton> 
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute left-0 z-20 mt-3 w-[175px] transform ">
                <div className=" overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                        <div className='text-sm font-bold text-gray-900 dark:text-gray-300'>Filter by Priority</div>
                        {allPriorities.map((currentPriority, index)=>(
                            <div key={currentPriority.workorder_priority_uuid} className="flex items-center my-3">
                                <input id={`priority-checkbox-${index}`} type="checkbox" checked={checkedPriorityState[index]} onChange={() => handleChangePriorityState(index)}  className="checkboxBtn"/>
                                <div  className={`ml-2 ${currentPriority.workorder_priority_icon_color ? currentPriority.workorder_priority_icon_color : ''}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960"  fill="currentColor" >
                                        <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-160q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Z"/>
                                    </svg>
                                </div>
                                <label htmlFor={`priority-checkbox-${currentPriority.workorder_priority_uuid}`} className="ml-1 text-xs font-medium text-gray-600 dark:text-gray-400">{currentPriority.workorder_priority_name}</label>
                            </div>
                        ))}
                        

                        <motion.button  
                            whileHover={{ scale: 1.02 }} 
                            whileTap={{ scale: 0.98 }} 
                            onClick={ () => {
                                handleApplyFilter()
                                close()
                            }} 
                            className='w-full text-sm p-1 bg-blue-600 text-white rounded-md' >
                                Apply
                        </motion.button>
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default PriorityWOFilterBtn