import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { formatCalendarTimeWithExactTime, formatRelativeTime, isDatePastDue } from '../../utils/DateFormating'
import { statusOptions } from '../statusOptions';
import { putData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import toast from 'react-hot-toast';
import { Avatar, AvatarGroup } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

function RoadmapTableRow({task, toggleTask, expandedTasks, isDark, getWorkOrders, selectedWorkOrderUuid}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [hasRead, setHasRead] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState(
        statusOptions.find((status) => status.id === task.workorder_workorder_status_id)
      );

    useEffect(()=> {
        if(task && Object.keys(task).length > 0){
            let status = statusOptions.find((status) => status.id === task.workorder_workorder_status_id)
            setSelectedStatus(status)
            setHasRead(task.has_read)
        }

    },[task])

   

    const handleStatusChange = (newStatus) => {
        setSelectedStatus(newStatus)
        if(!loadingStatus){
            setLoadingStatus(true)
            putData(apiLink + '/v1/workorders/' + task.workorder_uuid , 
            {'workorder_status_uuid': newStatus.workorder_status_uuid})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingStatus(false)
                    getWorkOrders()
                }
                else{
                    let status = statusOptions.find((status) => status.id === task.workorder_workorder_status_id)
                    setSelectedStatus(status)
                    setLoadingStatus(false)
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })
        }
    };


    const navToChat = (workOrder) => {
        let wo_status = searchParams.getAll('wo_status')
        let wo_priority = searchParams.getAll('wo_priority')
        let sort_by = searchParams.getAll('sort_by')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let q = searchParams.getAll('q')
        
        setSearchParams({wo_status,wo_priority, sort_by, from, to, q, selected_wo: workOrder.workorder_uuid})
   }

  return (
    <div 
        className={`flex text-sm text-gray-600 dark:text-gray-300 `}
        style={{
            display: 'grid',
            gridTemplateColumns: 'minmax(400px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(170px, 1fr)  minmax(130px, 1fr) minmax(175px, 1fr) minmax(300px, 1fr) minmax(175px, 1fr) minmax(125px, 1fr) minmax(125px, 1fr) ',
            gap: '0px',
            minHeight: '56px',
            }}
    >
        <div style={{ paddingLeft: `${task.level * 20}px` }} className={`border-b-[0.01em] border-slate-200 dark:border-slate-700 font-medium flex items-center px-2 ${selectedWorkOrderUuid == task.workorder_uuid ? 'bg-slate-100 dark:bg-slate-800': ''}`}>
            <div className='flex justify-center w-7'>
                {task.children ? (
                    <button
                        className={` rounded-full text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700`}
                        onClick={() => toggleTask(task.workorder_uuid)}
                    >
                        <ChevronRightIcon className={`w-5 h-5 transform transition-transform duration-200 ${expandedTasks.has(task.workorder_uuid) ? "rotate-90" : "rotate-0"}`} />
                    </button>
                    ):(
                        <div />
                    )}
            </div>
            <div 
                className="flex-1 items-center truncate text-gray-600 dark:text-gray-300 cursor-pointer"
                onClick={() => navToChat(task)} 
            >
                {task.workorder_title}
            </div>
        </div>
        <div className={`border-b-[0.01em] border-slate-200 dark:border-slate-700 flex items-center justify-center relative px-4 ${selectedWorkOrderUuid == task.workorder_uuid ? 'bg-slate-100 dark:bg-slate-800': ''}`}>
            <Listbox value={selectedStatus} onChange={handleStatusChange}>
                {({ open }) => (
                <div className=" ">
                    <ListboxButton
                    className={`flex w-fit items-center py-1 pl-2 pr-2 border-0 rounded-full relative ${selectedStatus.color}`}
                    >
                    <span className='mr-1'>{selectedStatus.icon}</span>
                    <span className='truncate'>{selectedStatus.workorder_status_name}</span>
                    <ChevronDownIcon
                        className={`w-5 h-5 ml-1 ${selectedStatus.workorder_status_name == 'Open' ? 'text-white':'text-gray-500'}  transition-transform duration-300 ${
                        open ? 'rotate-180' : 'rotate-0'
                        }`}
                    />
                    {loadingStatus &&
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                    </ListboxButton>
                    <ListboxOptions className="absolute inset-x-0 max-w-[200px] mx-auto bg-white shadow-iotflows-lg dark:bg-slate-700 z-50  rounded-xl space-y-3 shadow-lg mt-1 p-2">
                    {/* className='absolute top-10 inset-x-0 mx-0 md:mx-0 lg:mx-0 2xl:mx-10 bg-white shadow-iotflows-lg dark:bg-slate-700/95 z-50 px-2 py-4 rounded-xl space-y-3' */}
                    {statusOptions.map((status) => (
                        <ListboxOption
                        key={status.workorder_status_name}
                        value={status}
                        className={({ active }) =>
                            `cursor-pointer flex items-center m-1 py-1 pl-2 pr-4 border-0 rounded-full transition-transform duration-200 ${status.color} ${
                            active ? '-translate-y-0.5 ' : ''
                            }`
                        }
                        >
                        <div className='mr-1'>
                            {status.icon}
                        </div>
                        {status.workorder_status_name}
                        </ListboxOption>
                    ))}
                    </ListboxOptions>
                </div>
                )}
            </Listbox>
        </div>
        <div 
            className={`border-b-[0.01em] border-slate-200 dark:border-slate-700 flex items-center px-2 cursor-pointer ${selectedWorkOrderUuid == task.workorder_uuid ? 'bg-slate-100 dark:bg-slate-800': ''}`}
            onClick={() => navToChat(task)} 
        >
            {task.workorder_group && Object.keys(task.workorder_group).length > 0 &&
                <div className='pl-1 pr-4 truncate'>
                    <span 
                        className='text-center font-semibold '
                        style={{color: isDark ? task.workorder_group.group_dark_color_hex : task.workorder_group.group_light_color_hex}}
                    >
                        {task.workorder_group.group_name}
                    </span>
                </div>
            }
        </div>
        <div 
            className={`border-b-[0.01em] border-slate-200 dark:border-slate-700 flex items-center px-2 truncate cursor-pointer ${selectedWorkOrderUuid == task.workorder_uuid ? 'bg-slate-100 dark:bg-slate-800': ''} ${isDatePastDue(task.workorder_due_timestamp) ? 'text-red-600 dark:text-red-200':'text-gray-600 dark:text-gray-200'}`}
            onClick={() => navToChat(task)} 
        >
            {formatCalendarTimeWithExactTime(task.workorder_due_timestamp)}
        </div>
        <div 
            className={`border-b-[0.01em] border-slate-200 dark:border-slate-700 flex items-center px-2 cursor-pointer ${selectedWorkOrderUuid == task.workorder_uuid ? 'bg-slate-100 dark:bg-slate-800': ''}`}
            onClick={() => navToChat(task)} 
        >
            <div className='pl-2 pr-4'>
                {AssignedCell(task)}
            </div>
        </div>
        <div 
            className={`border-b-[0.01em] border-slate-200 dark:border-slate-700 flex items-center px-2 cursor-pointer ${selectedWorkOrderUuid == task.workorder_uuid ? 'bg-slate-100 dark:bg-slate-800': ''}`} style={{minHeight: '37px'}}
            onClick={() => navToChat(task)} 
        >
            {(task.workorder_asset && Object.keys(task.workorder_asset).length > 0) ? (
                <div className='flex items-center'>
                    <div className='mr-3'>
                        <Avatar
                            sx={{ 
                                // bgcolor: task.user_color? member.user_color : '' , 
                                fontSize:'12px',
                                width: 32, 
                                height: 32,
                            }}
                            // style={{
                            //     background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${member.user_color? member.user_color:''} 100%)`,
                            // }}
                            alt={task.workorder_asset.asset_custom_name ? task.workorder_asset.asset_custom_name : task.workorder_asset.machine_name }
                            src={task.workorder_asset.asset_custom_picture_url ? task.workorder_asset.asset_custom_picture_url : task.workorder_asset.machine_picture_url }
                        />
                    </div>
                    <div className='flex flex-col truncate'>
                        <span className='text-sm'>
                            {task.workorder_asset.asset_custom_name ? task.workorder_asset.asset_custom_name : task.workorder_asset.machine_name }
                        </span>
                        <span className='text-gray-500 dark:text-gray-400 text-xs truncate'>
                        {task.workorder_asset.asset_custom_identifier ? task.workorder_asset.asset_custom_identifier : task.workorder_asset.machine_identifier }
                        </span>

                    </div>

                </div>
            ):(
                <div />
            )}
        </div>
        <div 
            className={`border-b-[0.01em] border-slate-200 dark:border-slate-700 flex items-center px-2 overflow-x-auto cursor-pointer  ${selectedWorkOrderUuid == task.workorder_uuid ? 'bg-slate-100 dark:bg-slate-800': ''}`}
            onClick={() => navToChat(task)} 
        >
            {task.workorder_recurrence_description ? (
                <div className='px-4 py-1 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-full w-fit truncate'>
                    {task.workorder_recurrence_description}
                </div>
            ):( 
                <div/>
            )}
        </div>
        <div 
            className={`border-b-[0.01em] border-slate-200 dark:border-slate-700 flex items-center px-2 cursor-pointer ${selectedWorkOrderUuid == task.workorder_uuid ? 'bg-slate-100 dark:bg-slate-800': ''}`}
            onClick={() => navToChat(task)} 
        >
            {(task.created_by_user && Object.keys(task.created_by_user).length > 0) && (
                <div className='flex items-center'>
                    <div className='mr-3'>
                        <Avatar
                            sx={{ 
                                bgcolor: task.created_by_user.user_color? task.created_by_user.user_color : '' , 
                                fontSize:'12px',
                                width: 32, 
                                height: 32,
                            }}
                            style={{
                                background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${task.created_by_user.user_color? task.created_by_user.user_color:''} 100%)`,
                            }}
                            alt={task.created_by_user.user_first_name ? task.created_by_user.user_first_name : '' }
                            src={task.created_by_user.user_image_url ? task.created_by_user.user_image_url : '/broken-image.jpg' }
                        />
                    </div>
                    <div className='flex flex-col '>
                        <span className='text-sm'>
                            {task.created_by_user.user_first_name ? task.created_by_user.user_first_name : '' } {' '} {task.created_by_user.user_last_name ? task.created_by_user.user_last_name : '' }
                        </span>
                        <span className='text-gray-500 dark:text-gray-400 text-xs'>
                        {task.created_by_user.user_username_public ? task.created_by_user.user_username_public : task.created_by_user.user_username_public }
                        </span>

                    </div>

                </div>
            )}
        </div>
        <div 
            className={`border-b-[0.01em] border-slate-200 dark:border-slate-700 flex justify-center items-center px-2 text-center cursor-pointer ${selectedWorkOrderUuid == task.workorder_uuid ? 'bg-slate-100 dark:bg-slate-800': ''}`}
            onClick={() => navToChat(task)} 
        >
            {formatRelativeTime(task.workorder_created_at)}
        </div>
        <div 
            className={`border-b-[0.01em] border-slate-200 dark:border-slate-700 flex justify-center items-center px-2 text-center cursor-pointer ${selectedWorkOrderUuid == task.workorder_uuid ? 'bg-slate-100 dark:bg-slate-800': ''}`}
            onClick={() => navToChat(task)} 
        >
            {formatRelativeTime(task.workorder_updated_at)}
        </div>
    </div>
  )
}

export default RoadmapTableRow


const AssignedCell = (order) => {

    let membersList = []
    if(order){
        if(order.assigned_to_teams && Object.keys(order.assigned_to_teams).length > 0){
            order.assigned_to_teams.map(team => {
                membersList.push({
                    user_color: team.user_color ? team.user_color:'',
                    name: team.team_name ? team.team_name:'',
                    image_url: '/broken-image.jpg',
                })
            })
        }
        if(order.assigned_to_users && Object.keys(order.assigned_to_users).length > 0){
            order.assigned_to_users.map(user => {
                membersList.push({
                    user_color: user.user_color ? user.user_color:'',
                    name: user.user_first_name ? user.user_first_name:'',
                    image_url: user.user_image_url ? user.user_image_url : '/broken-image.jpg',
                })
            })
        }
            return(
                <div className='flex w-[90px]'>
                    <AvatarGroup max={4} spacing={5} sx={{ '& .MuiAvatar-root': { border: '0px solid rgba(203, 213, 225, 0.1)', width:28, height:28, fontSize:'16px' } }} >
                        {membersList.map((member,index) =>(
                            <Avatar
                                key={`avatar-group-${index}`}
                                sx={{ 
                                    bgcolor: member.user_color? member.user_color : '' , 
                                    fontSize:'12px',
                                    width: 28, 
                                    height: 28,
                                }}
                                style={{
                                    background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${member.user_color? member.user_color:''} 100%)`,
                                }}
                                alt={member.name}
                                src={member.image_url ? member.image_url :'/broken-image.jpg'}
                            />
                        ))}
                    </AvatarGroup>
    
                </div>
            )

        
    }

}

