import { Dialog, Transition, TransitionChild, DialogTitle, DialogPanel } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import TagInput from '../../forms/TagInput';
import DepartmentInput from '../../forms/DepartmentInput';
import GeneralFormInput from '../../forms/GeneralFormInput';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { getData, postData, putData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { PencilIcon } from "@heroicons/react/solid"
import toast from 'react-hot-toast';
import ImageZoomCrop from '../ImageZoomCrop';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { SearchIcon } from "@heroicons/react/outline"


function AddUserToChatModal({btnType,chatInfo, remoteOpenAssetModal, handleOpenAddUserToChatModal, participants, isUserAuthorized, setParticipants, setMemberObjects}) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [loading, setLoading] = useState(false)
    const [selectedMembers, setSelectedMembers] = useState({})
    const [teamsList, setTeamsList] = useState([])
    const [usersList, setUsersList] = useState([])
    const [filteredTeamsList, setFilteredTeamsList] = useState([])
    const [filteredMemberList, setFilteredMemberList] = useState([])
    const [searchMemberText, setSearchMemberText] = useState('')
    const [userCount, setUserCount] = useState(null)
    const [selectedUserCount, setSelectedUserCount] = useState(0)
    const [isHighlighted, setIsHighlighted] = useState(null);
    const [userDoesNotExist, setUserDoesNotExist] = useState(false)
    const [isCreatingGroup, setIsCreatingGroup] = useState(false)
    let location = useLocation();
    const [groupImage, setGroupImage] = useState(null)
    const [groupName, setGroupName] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const [activeParticipants, setActiveParticipants] = useState([])
    
    useEffect(() => {
      if(participants && Object.keys(participants).length > 0){
        

        const filteredObject = Object.keys(participants)
        .filter(key => participants[key].chatroom_member_has_left === false) // Check nested property
        .reduce((acc, key) => {
          // console.log('key', key)
          // console.log('acc', acc)
          participants[key].organizations.map(org => {
            let k = `${key}:${org.organization_handle}`
            // console.log('k', k)
            acc[k] = participants[key];
          })
          // acc[key] = participants[key];
          return acc;
        }, {});

        // console.log('filteredObject', filteredObject)
        setActiveParticipants(filteredObject)
      }

    }, [participants])

    useEffect(() => {
        let currentSearch = location.search
            if(currentSearch && selectedOrganization){
                updatedMemberList()
            }
      },[selectedOrganization, location])

    const updatedMemberList = () => {
        try {

            if(selectedOrganization ){
              // getData(apiLink + '/v1/organizations/' + selectedOrganization + '/user_contacts')
              // .then(async response => {
              //   if(response.ok ){
              //     const data = await response.json();
              //     if(data && data.data){
              //         console.log('v1 user_contacts',data.data)
              //       // let count = Object.keys(data.data).length
              //       // if(count){
              //       //     setUserCount(count)
              //       // }
              //         // setUsersList(data.data)
              //     }
              //   }
              // }) 
              getData(apiLink + '/v2/organizations/' + selectedOrganization + '/user_contacts')
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  // console.log('V2 user_contacts',data.data)
                  if(data && data.data && data.data[0]){
                      setTeamsList(data.data[0].teams)
                      setUsersList(data.data[0].users)
                  }
                }
              })
            }
          } catch (error) {
            console.log(error.message)
          }
    }

    useEffect(() => {
        if(searchMemberText  && searchMemberText.length > 0){
          const filteredTeams = teamsList.filter(member => {
            if(member.team_organization_handle.toLowerCase().includes(searchMemberText.toLowerCase()) || member.team_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.team_handle.toLowerCase().includes(searchMemberText.toLowerCase())){
              return member
            }
          })
          // console.log('filteredTeams', filteredTeams)
          setFilteredTeamsList(filteredTeams)
    
        }else{
          setFilteredTeamsList([])
        }


        if(searchMemberText  && searchMemberText.length > 0){
          // console.log('usersList', usersList)
          const filteredUsers = usersList.filter(member => {
            let fullName = member.user_first_name + ' ' + member.user_last_name
            if(member.user_organization_handle.toLowerCase().includes(searchMemberText.toLowerCase()) || fullName.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_first_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_last_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_username_public.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_email.toLowerCase().includes(searchMemberText.toLowerCase())){
              return member
            }
          })
          setFilteredMemberList(filteredUsers)
    
        }else{
          setFilteredMemberList([])
        //   setFilteredAllIoTFlowsMemberList([])
        }
      },[searchMemberText])

      const handleAddUser = (e, user, team) => {
        setSearchMemberText('')
    
        let itemId = ''
    
        if(user && Object.keys(user).length > 0 ){
          itemId = user.user_organization_handle
        }else if(team && Object.keys(team).length > 0){
          itemId = team.team_organization_handle
        }else return
    
        const newSelectedItems = {...selectedMembers}; // Copy the existing array
    
    
        
        const index = Object.keys(newSelectedItems).indexOf(itemId);
        if (index === -1) {
          if(user && Object.keys(user).length > 0 ){
            newSelectedItems[itemId] = user;
          }else if(team && Object.keys(team).length > 0){
            newSelectedItems[itemId] = team;
          }
            // Item not found, add it to the array
            // newSelectedItems.push(itemId);
        } else {
            // Item found, remove it from the array
            // newSelectedItems.splice(index, 1);
            delete newSelectedItems[itemId]
        }
        let count = Object.keys(newSelectedItems).length
        setSelectedMembers(newSelectedItems);
      }

      // const handleAddUser = (e, user) => {
      //   setSearchMemberText('')

      //   const itemId = user.user_organization_handle
      //   const newSelectedItems = {...selectedMembers}; // Copy the existing array

        
      //   const index = Object.keys(newSelectedItems).indexOf(itemId);
      //   if (index === -1) {
      //       // Item not found, add it to the array
      //       newSelectedItems[itemId] = user;
      //       // newSelectedItems.push(itemId);
      //   } else {
      //       // Item found, remove it from the array
      //       // newSelectedItems.splice(index, 1);
      //       delete newSelectedItems[itemId]
      //   }
      //   let count = Object.keys(newSelectedItems).length
      //   setSelectedUserCount(count)
      //   setSelectedMembers(newSelectedItems);

      // }


      const handleRemoveUser = (e) => {
        const key = e.key;
        if (key === 'Enter' || key === ',' || key === 'Tab') {
            getData(apiLink + '/v1/handles/' + searchMemberText + '/exists' )
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  if(data && data.data && data.data.exists){

                    const itemId = searchMemberText
                    const newSelectedItems = {...selectedMembers}; // Copy the existing array

                    
                    const index = Object.keys(newSelectedItems).indexOf(itemId);
                    if (index === -1) {
                        // Item not found, add it to the array
                        newSelectedItems[itemId] = {
                            user_image_url: '',
                            user_organization_handle: itemId,
                            user_first_name:''
                        };
                        // newSelectedItems.push(itemId);
                    } else {
                        // Item found, remove it from the array
                        // newSelectedItems.splice(index, 1);
                        // delete newSelectedItems[itemId]
                        toast('User already added')
                    }
                    let count = Object.keys(newSelectedItems).length
                    setSelectedUserCount(count)
                    setSelectedMembers(newSelectedItems);
                    setSearchMemberText('')
                    setUserDoesNotExist(false)


                  }else{
                    setUserDoesNotExist(true)
                  }
                  
                }
              })
        }else if ((key === 'Backspace' || key === 'Delete') && !e.ctrlKey && !e.metaKey) {
            if(searchMemberText.length === 0){

                if (isHighlighted) {
                   // Call delete function with component reference
                //    let newMembers = selectedMembers.filter((element) => element !== isHighlighted);
                //    setSelectedMembers(newMembers)

                   let newSelectedItems = {...selectedMembers}; // Copy the existing array
                    delete newSelectedItems[isHighlighted]

                    setSelectedMembers(newSelectedItems)
                    setIsHighlighted(null); // Reset highlight if unrelated key is pressed
                    let count = Object.keys(newSelectedItems).length
                    setSelectedUserCount(count)
                } else {
                    const keys = Object.keys(selectedMembers);
                    const lastKey = keys[keys.length - 1];
                //   const lastValue = selectedMembers[selectedMembers.length - 1];
                    setIsHighlighted(lastKey);
                  
                }
                e.preventDefault(); // Prevent default backspace/delete behavior
              } else {
                setIsHighlighted(null); // Reset highlight if unrelated key is pressed
              }
        }
      };

    const removeMember = (member) => {
        if(member){
            let newSelectedItems = {...selectedMembers}; // Copy the existing array
            delete newSelectedItems[member]

            setSelectedMembers(newSelectedItems)
            let count = Object.keys(newSelectedItems).length
            setSelectedUserCount(count)
        }
    }

    const handleDoesUserExist = (e) => {
        const key = e.key;
        if (key === 'Enter' || key === ',' || key === 'Tab') {
            // console.log('e.target.value', e.target.value); // Replace with your desired action
            // console.log('searchMemberText:', searchMemberText); // Replace with your desired action
            getData(apiLink + '/v1/handles/' + searchMemberText + '/exists' )
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  if(data && data.data && data.data.exists){

                    const itemId = searchMemberText
                    const newSelectedItems = {...selectedMembers}; // Copy the existing array

                    
                    const index = Object.keys(newSelectedItems).indexOf(itemId);
                    if (index === -1) {
                        // Item not found, add it to the array
                        newSelectedItems[itemId] = newSelectedItems[itemId] = {
                            user_image_url: '',
                            user_organization_handle: itemId,
                            user_first_name:''
                        };;
                        // newSelectedItems.push(itemId);
                    } else {
                        // User Already added
                        toast('User Already Added')
                    }
                    let count = Object.keys(newSelectedItems).length
                    setSelectedUserCount(count)
                    setSelectedMembers(newSelectedItems);
                    setSearchMemberText('')
                    setUserDoesNotExist(false)

                  }else{
                    setUserDoesNotExist(true)
                  }
                  
                }
              })
        }
    }

    const handleSearchMemberText = (e) => {
        setSearchMemberText(e.target.value.replace(/,/g, ''))
        if(userDoesNotExist){
            setUserDoesNotExist(false)
        }

    }

    const handleCreateGroup = () => {
        if(selectedMembers && Object.keys(selectedMembers).length > 0){
            setIsCreatingGroup(true)
        }else{
            toast.error('Select users')
        }
    }


    useEffect(() => {
      if(remoteOpenAssetModal){
        setIsOpen(true)
      }
    },[remoteOpenAssetModal])


    

    function closeModal() {
      setIsOpen(false)
      if(handleOpenAddUserToChatModal){
        handleOpenAddUserToChatModal()
      }
    }

    function openModal() {
      setIsOpen(true)
    }



  const submitAddUserToGroup = () => {
    try{
        if(!loading){
            setLoading(true)
            let membersArray = Object.keys(selectedMembers)
            // console.log('membersArray', membersArray)
            // postData(apiLink + '/v1/chats/' + chatInfo.chat_uuid + '/members', 
            postData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + '/members', 
            {'chatroom_members_to_add': membersArray})
              .then(async response => {
                const data = await response.json();
                // console.log('data', data)
                // console.log('response', response)
                if(response.ok){
                    if(data && data.data){
                        setParticipants(data.data.all_users)
                        setMemberObjects(data.data)
                        setSelectedMembers({})
                        setLoading(false)
                        closeModal()
                    }
                }else{
                  setLoading(false)
                  try{
                    toast.error(data.message)
                  }catch (e){
                    toast.error(e.message)
                  }
                }
              })
        }
    }catch (e){
        toast.error(e.message)
        setLoading(false)
    }
  }

  

  const memberAlreadyInGroup = (username) => {

    const isInGroup = username in activeParticipants;

    return isInGroup

  }


  return (
    <>
      <div className="flex items-center justify-center">
      {btnType ==='standardBtn' &&
        <motion.button  
          whileHover={{ scale:isUserAuthorized ? 1.05 : 1 }} 
            whileTap={{ scale:isUserAuthorized? 0.95 : 1 }}  
            className='btnMain disabled:opacity-50' 
            onClick={openModal}
            disabled={!isUserAuthorized}
          >
            <PencilIcon className='btnMainIcon' />
            <span className='btnMainText pl-0.5'>Add User</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button 
          whileHover={{ scale:isUserAuthorized ? 1.05 : 1 }} 
          whileTap={{ scale:isUserAuthorized? 0.95 : 1 }}  
          className='flex w-20 text-sm items-baseline ml-2 disabled:opacity-50' onClick={openModal} 
          disabled={!isUserAuthorized}
        >
          <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
          <span className=' text-blue-600'>Add</span>
      </motion.button>
      }
      {btnType ==='inlineIconBtn' &&
        <button 
          className={`${isDark?'text-blue-400 hover:text-blue-200 disabled:text-gray-500':'text-blue-500 hover:text-blue-900 disabled:text-gray-300'} flex ml-auto `} 
          onClick={openModal}
          disabled={!isUserAuthorized}
        >
          <PencilIcon className={`w-5 h-5`}/>
        </button>
      }
      {btnType ==='hideBtn' &&
        <div className='w-0 h-0'>{''}</div>
      }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-2xl transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <DialogTitle
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    Add Members
                  </DialogTitle>
                  <AnimatePresence mode='wait'>
                    <motion.div
                            key='selectMembersDiv'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className='relative'
                        >
                        <div className={`sticky top-9 pb-1 px-4 mb-2 ${isDark? 'bg-slate-800 border-slate-700':'bg-slate-100 border-slate-200'} z-10 border-b-[0.01em]`}>
                            {selectedMembers && Object.keys(selectedMembers).length > 0 ? (
                                <div className='rounded-full w-full py-2'>
                                    <div className={`flex flex-wrap ${isDark? 'bg-slate-700/50':'bg-slate-100'} rounded-xl px-2 py-1`}>
                                        {Object.keys(selectedMembers).map((member) => (
                                            <div key={`${member}-selectedMembers`} className={`${member === isHighlighted ? 'bg-blue-600/40':'bg-blue-600/80'} flex text-white rounded-full pr-1 pl-2 py-0 mr-1 my-0.5 items-center font-light`}>
                                                <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 20, height: 20, }}  src={selectedMembers[member].user_image_url} />
                                                <span className='px-2'>{member}</span>
                                                <button 
                                                onClick={() => removeMember(member)}
                                                className={`bg-white/50 ${isDark? 'text-slate-700/50':'text-slate-100'} rounded-full h-4 p-0.5`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className="w-3 h-3">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        ))} 
                                        <input className={`grow py-0 min-w-[150px] bg-transparent text-gray-600 border-0 border-gray-300 
                                                        ${isDark? 'focus:ring-gray-500 text-white placeholder:text-gray-400':'focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 '}
                                                        focus:ring-[0px] text-sm text-ellipsis `}
                                            value={searchMemberText} 
                                            onChange={handleSearchMemberText}
                                            onKeyDown={handleRemoveUser}
                                            type='text' 
                                            />
                                    </div>

                                </div>
                            ):(
                                <div className='relative rounded-full w-full py-2'>
                                    <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                                        <SearchIcon className='h-4 w-4 text-gray-400 ' />
                                    </div>
                                    <input className={`border-0 focus:ring-[0px] block w-full pl-8 text-sm rounded-lg text-ellipsis py-2
                                            ${isDark? 'border-gray-300 bg-gray-100 focus:ring-gray-500 text-gray-300 placeholder:text-gray-400 hover:bg-slate-700/80 focus:bg-slate-700/80 bg-slate-700/50 focus:ring-gray-500':'text-gray-600 focus:bg-slate-100 focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 '} `}
                                        value={searchMemberText} 
                                        onChange={handleSearchMemberText}
                                        onKeyDown={handleDoesUserExist}
                                        type='text' 
                                        placeholder="Who would you like to add?" 
                                        />
                                </div>
                            )
                            }
                        </div>
                        <div className='pb-4 px-4'>
                                {/* Search bar for searching members in org and in all of IoTFlows */}
                            {searchMemberText && searchMemberText.length > 0 && userDoesNotExist &&
                            // No member found section
                            <div className='text-center'>
                                <div className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} py-2 w-full text-center`}>
                                    <span>No matches found for {searchMemberText}</span>
                                </div>
                                </div>
                            }
                            <div className='overflow-y-scroll scrollbar-hide h-[500px] '>
                                <AnimatePresence mode='wait'>
                                    {searchMemberText === '' && teamsList && Object.keys(teamsList).length > 0 &&
                                        <motion.div
                                            key='allTeamMembersInOrg'
                                            initial={{ y: 10, opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            exit={{ y: -10, opacity: 0 }}
                                            transition={{ duration: 0.2 }}
                                        > 
                                        <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2`}>
                                          Teams
                                        </div>
                                        {/* List of members */}
                                            <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                            {teamsList.map((currentTeam, index) => (
                                                <button 
                                                    key={`${currentTeam.team_organization_handle}-usersList`} 
                                                    className={`flex px-2 py-2  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                                    onClick={(e) => handleAddUser(e, '',currentTeam)}
                                                    disabled={memberAlreadyInGroup(`${currentTeam.team_handle}:${currentTeam.organization_handle}`)}
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        checked={Object.keys(selectedMembers).includes(currentTeam.team_organization_handle)} 
                                                        onChange={(e) => handleAddUser(e, '', currentTeam)}  
                                                        className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"
                                                        disabled={memberAlreadyInGroup(`${currentTeam.team_handle}:${currentTeam.organization_handle}`)}
                                                    />
                                                    <div className={`relative`}>
                                                        <Avatar 
                                                          sx={{  
                                                            bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                            width: 32, 
                                                            height: 32, 
                                                          filter: memberAlreadyInGroup(`${currentTeam.team_handle}:${currentTeam.organization_handle}`)? 'grayscale(100%)':'grayscale(0)'}}  
                                                          src={currentTeam.team_image_url || 'brokenLink.jpg'} />
                                                        {currentTeam.is_external &&
                                                            <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                                                              <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                                                  <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                                                              </svg>
                                                          </div>
                                                        }
                                                    </div>
                                                    <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate ${memberAlreadyInGroup(`${currentTeam.team_handle}:${currentTeam.organization_handle}`) && 'opacity-50' }`} >{currentTeam.team_name}</h1>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} ${memberAlreadyInGroup(`${currentTeam.team_handle}:${currentTeam.organization_handle}`) && 'opacity-50' } truncate italic`}>{currentTeam.team_organization_handle}</h1>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    
                                                </button>
                                            ))}

                                            </div>
                                        </motion.div>
                                    }
                                    {searchMemberText === '' && usersList && Object.keys(usersList).length > 0 &&
                                        <motion.div
                                            key='allMembersInOrg'
                                            initial={{ y: 10, opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            exit={{ y: -10, opacity: 0 }}
                                            transition={{ duration: 0.2 }}
                                        > 
                                        <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2`}>
                                          Users
                                        </div>
                                        {/* List of members */}
                                            <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                            {usersList.map((member, index) => (
                                                <button 
                                                    key={`${member.user_organization_handle}-usersList`} 
                                                    className={`flex px-2 py-2  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                                    onClick={(e) => handleAddUser(e, member, '')}
                                                    disabled={memberAlreadyInGroup(`${member.user_username}:${member.organization_handle}`)}
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        checked={Object.keys(selectedMembers).includes(member.user_organization_handle)} 
                                                        onChange={(e) => handleAddUser(e, member, '')}  
                                                        className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"
                                                        disabled={memberAlreadyInGroup(`${member.user_username}:${member.organization_handle}`)}
                                                    />
                                                    <div className={`relative`}>
                                                        <Avatar 
                                                          sx={{  
                                                            bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                            width: 32, 
                                                            height: 32, 
                                                          filter: memberAlreadyInGroup(`${member.user_username}:${member.organization_handle}`)? 'grayscale(100%)':'grayscale(0)'}}  
                                                          src={member.user_image_url} />
                                                        {member.is_external &&
                                                            <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                                                              <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                                                  <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                                                              </svg>
                                                          </div>
                                                        }
                                                    </div>
                                                    <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate ${memberAlreadyInGroup(`${member.user_username}:${member.organization_handle}`) && 'opacity-50' }`} >{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                                                
                                                            </div>
                                                            {/* <span className={`pl-3 pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic `}>{member.user_organization_handle}</span> */}

                                                        </div>
                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} ${memberAlreadyInGroup(`${member.user_username}:${member.organization_handle}`) && 'opacity-50' } truncate italic`}>{member.user_organization_handle}</h1>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    
                                                </button>
                                            ))}

                                            </div>
                                        </motion.div>
                                    }
                                    {searchMemberText && searchMemberText.length > 0  && filteredTeamsList && Object.keys(filteredTeamsList).length > 0 &&
                                        <motion.div
                                            key='filteredTeamListSection'
                                            initial={{ y: 10, opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            exit={{ y: -10, opacity: 0 }}
                                            transition={{ duration: 0.2 }}
                                        > 
                                          <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2`}>
                                            Teams
                                          </div>
                                            <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                                {filteredTeamsList.map((currentTeam, index) => (
                                                  <button 
                                                    key={`${currentTeam.team_organization_handle}-filteredTeamsList`} 
                                                    className={`flex px-2 py-2  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                                    onClick={(e) => handleAddUser(e, '',currentTeam)}
                                                    disabled={memberAlreadyInGroup(`${currentTeam.team_handle}:${currentTeam.organization_handle}`)}
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        checked={Object.keys(selectedMembers).includes(currentTeam.team_organization_handle)} 
                                                        onChange={(e) => handleAddUser(e, '', currentTeam)}  
                                                        className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"
                                                        disabled={memberAlreadyInGroup(`${currentTeam.team_handle}:${currentTeam.organization_handle}`)}
                                                    />
                                                    <div className={`relative`}>
                                                        <Avatar 
                                                          sx={{  
                                                            bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                            width: 32, 
                                                            height: 32, 
                                                          filter: memberAlreadyInGroup(`${currentTeam.team_handle}:${currentTeam.organization_handle}`)? 'grayscale(100%)':'grayscale(0)'}}  
                                                          src={currentTeam.team_image_url || 'brokenLink.jpg'} />
                                                        {currentTeam.is_external &&
                                                            <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                                                              <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                                                  <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                                                              </svg>
                                                          </div>
                                                        }
                                                    </div>
                                                    <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate ${memberAlreadyInGroup(`${currentTeam.team_handle}:${currentTeam.organization_handle}`) && 'opacity-50' }`} >{currentTeam.team_name}</h1>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} ${memberAlreadyInGroup(`${currentTeam.team_handle}:${currentTeam.organization_handle}`) && 'opacity-50' } truncate italic`}>{currentTeam.team_organization_handle}</h1>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    
                                                </button>
                                                ))}
                                            </div>
                                        </motion.div>
                                    }
                                    {searchMemberText && searchMemberText.length > 0  && filteredMemberList && Object.keys(filteredMemberList).length > 0 &&
                                        <motion.div
                                            key='filteredListSection'
                                            initial={{ y: 10, opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            exit={{ y: -10, opacity: 0 }}
                                            transition={{ duration: 0.2 }}
                                        > 
                                          <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2`}>
                                            Users
                                          </div>
                                            <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                                {filteredMemberList.map((member, index) => (
                                                <button 
                                                    key={`${member.user_organization_handle}-filteredMembersList`} 
                                                    className={`flex px-2 py-2  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                                    onClick={(e) => handleAddUser(e, member, '')}
                                                    disabled={memberAlreadyInGroup(`${member.user_username}:${member.organization_handle}`)}
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        // checked={selectedMembers.includes(member.user_organization_handle)} 
                                                        checked={Object.keys(selectedMembers).includes(member.user_organization_handle)} 
                                                        onChange={(e) => handleAddUser(e, member, '')}  
                                                        className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"
                                                        disabled={memberAlreadyInGroup(`${member.user_username}:${member.organization_handle}`)}
                                                    />
                                                    <div className={`relative`}>
                                                        <Avatar 
                                                          sx={{  
                                                            bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                            width: 32, 
                                                            height: 32, 
                                                          filter: memberAlreadyInGroup(`${member.user_username}:${member.organization_handle}`)? 'grayscale(100%)':'grayscale(0)'}}  
                                                          src={member.user_image_url} />
                                                        {member.is_external &&
                                                            <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                                                    <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                                                                </svg>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} ${memberAlreadyInGroup(`${member.user_username}:${member.organization_handle}`) && 'opacity-50' } font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                                            </div>
                                                            {/* <span className={`pl-3 pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic `}>{member.user_organization_handle}</span> */}

                                                        </div>
                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} ${memberAlreadyInGroup(`${member.user_username}:${member.organization_handle}`) && 'opacity-50' } truncate italic`}>{member.user_organization_handle}</h1>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    
                                                </button>
                                                ))}
                                            </div>
                                        </motion.div>
                                    }

                                </AnimatePresence>
                            </div>
                        </div>
                    </motion.div>

                 
                  
                 

                  </AnimatePresence>

                <form >
                    <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                      <button
                        type="button"
                        className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                        onClick={closeModal}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                        onClick={submitAddUserToGroup}
                        disabled={loading || Object.keys(selectedMembers).length === 0}
                      >
                        Add Members
                        {loading && 
                          <div >
                              <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                              </svg>
                          </div>
                          }
                      </button>
                    </div>
                  </form>
                  
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AddUserToChatModal