import React, { useEffect, useState } from 'react'
import { statusOptions } from '../statusOptions';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { formatCalendarTimeWithExactTime, isDatePastDue } from '../../utils/DateFormating';
import { Avatar, AvatarGroup } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

function KanbanCard({workOrder, isMobile, selectedOrganization}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();
    const isDark = useRecoilValue(darkModeState);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState({});
    const [hasRead, setHasRead] = useState(true);

    useEffect(() => {
        if(workOrder && workOrder.workorder_uuid ){
            setHasRead(workOrder.has_read)
            if((workOrder.assigned_to_teams && Object.keys(workOrder.assigned_to_teams).length > 0) || (workOrder.assigned_to_users && Object.keys(workOrder.assigned_to_users).length > 0)){
                
                let members = []
                if(workOrder.assigned_to_teams && Object.keys(workOrder.assigned_to_teams).length > 0){
                    workOrder.assigned_to_teams.map(team => {
                        members.push(team)
                    })
                }
                if(workOrder.assigned_to_users && Object.keys(workOrder.assigned_to_users).length > 0){
                    workOrder.assigned_to_users.map(user => {
                        members.push(user)
                    })
                }

                setSelectedMembers(members)
                // console.log('members', members)
                
            }else{
                setSelectedMembers({})
            }
        }

    },[workOrder])

    useEffect(() => {
        // console.log('workOrder', workOrder)
        if(workOrder && workOrder.workorder_uuid){
            if(workOrder.workorder_status){
                let status = statusOptions.find((status) => status.workorder_status_uuid === workOrder.workorder_status.workorder_status_uuid)
                setSelectedStatus(status)
            }
            if(workOrder.workorder_priority){
                setSelectedPriority(workOrder.workorder_priority)
            }
        }

    },[workOrder])

    const handleGetFirstImage = (workOrder) => {
        if(workOrder.workorder_files && Object.keys(workOrder.workorder_files).length > 0){
            const compressedFile = workOrder.workorder_files.find(obj => obj.file_is_compressed == true);
            if(compressedFile){
                return (
                    <div className='w-full mb-2'>
                        <img 
                            alt={''}
                            src={compressedFile.file_signed_url}
                            // src='https://iotflows-organizations-files.s3.amazonaws.com/file_de4d3846d3cd32c039b7b9885f95ad11?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAT4JNKDY3WT3EFT6V%2F20241223%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241223T204844Z&X-Amz-Expires=86400&X-Amz-Signature=8a2ec5621f6fb8bde0138d749a729e86db56f7993b79a507d7db694185f83a46&X-Amz-SignedHeaders=host'
                            className='object-cover rounded-lg w-full max-h-[150px] shrink-0' 
                        />
                    </div>
                )
            }
            

        }
         
    }


    const handleNavigation = (uuid) => {
        

        let wo_status = searchParams.getAll('wo_status')
        let wo_priority = searchParams.getAll('wo_priority')
        let sort_by = searchParams.getAll('sort_by')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let q = searchParams.getAll('q')
        
        if(isMobile){
            // navigate(`/${selectedOrganization}/work-orders?selected_wo=${workOrder.workorder_uuid}&platform=mobile`)
            setSearchParams({wo_status,wo_priority, sort_by, from, to, q, selected_wo: uuid, platform: 'mobile'})
        }else{
            // navigate(`/${selectedOrganization}/work-orders?selected_wo=${workOrder.workorder_uuid}`)
            setSearchParams({wo_status,wo_priority, sort_by, from, to, q, selected_wo: uuid})
        }

    }

  return (
    <div 
        className="flex flex-col space-y-1" 
        onClick={(e) => {
            handleNavigation(workOrder.workorder_uuid)
        }}
    >
        {handleGetFirstImage(workOrder)}
        <div className='flex justify-between items-center '>
            <div className='flex items-center'>
                <div className={`${!hasRead ? 'text-gray-700 dark:text-gray-100':'text-gray-500 dark:text-gray-400'} truncate`}>#{workOrder?.workorder_number}</div>
                <div className='w-3 ml-1'>
                    {!hasRead ? (
                        <span className="flex rounded-full h-2.5 w-2.5 bg-blue-600 dark:bg-blue-400"></span>
                    ):(
                        // <span className="flex rounded-full h-2.5 w-2.5 bg-blue-600 dark:bg-blue-400"></span>
                        <div/>
                    )}
                </div>
            </div>
            <div>
                {AssignedCell(isDark, selectedMembers)}
            </div>
        </div>
        <div className={`flex w-full text-wrap ${!hasRead ? 'text-gray-800 dark:text-white':'text-gray-600 dark:text-gray-200'}`}>
            <span className=''>{workOrder.workorder_title}</span>
        </div>
        <div className='flex justify-between items-center'>
            <div className={`flex w-fit items-center py-0.5 pl-2 pr-2 border-[0.01em] border-gray-400 dark:border-gray-500 rounded-full relative`}>
                <span className={`mr-1 ${isDark ? `${selectedStatus.icon_only_dark_color}`:`${selectedStatus.icon_only_light_color}`} `}>{selectedStatus.icon}</span>
                <span className='truncate text-sm text-gray-600 dark:text-gray-300'>{selectedStatus.workorder_status_name}</span>
            </div>
            <div className={`ml-auto`}>
                {/* <span className={` w-[4px] ${
                    workOrder.priority === 'High' ? 'bg-red-500 text-red-800' :
                    workOrder.priority === 'Medium' ? 'bg-yellow-500 text-yellow-800' :
                    workOrder.priority === 'Low' ? 'bg-green-500 text-green-800' :
                    'bg-blue-500 text-blue-800'
                }`} />
                <span className={`pl-2 py-0.5 pr-2 text-gray-500 dark:text-gray-400 border-[0.01em] border-l-0 border-transparent dark:border-gray-500 rounded-r`}>{workOrder.priority}</span> */}
                {selectedPriority && (
                    <div className='flex space-x-1 items-center'>
                        <div  className={`${selectedPriority.workorder_priority_icon_color ? selectedPriority.workorder_priority_icon_color : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960"  fill="currentColor" >
                                <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-160q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Z"/>
                            </svg>
                        </div>
                        <span className='text-gray-500 dark:text-gray-400 text-sm'>
                            {selectedPriority.workorder_priority_name}
                        </span>
                    </div>
                )}
            </div>
        </div>
        {workOrder.workorder_due_timestamp && (
            <div className={`flex pt-1 items-center`}>
                {/* <div className={` rounded-full mr-1`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                </div> */}
                <span className={`text-sm text-gray-500 dark:text-gray-400 mr-1`}>
                    Due:
                </span>
                <span className={` truncate text-sm  ${isDatePastDue(workOrder.workorder_due_timestamp) ? 'text-red-600 dark:text-red-200':'text-gray-600 dark:text-gray-300'}`}>
                    {formatCalendarTimeWithExactTime(workOrder.workorder_due_timestamp)}
                </span>
            </div>
        )}
        {workOrder.workorder_recurrence_description && (
            <div className={`flex pt-1 items-center`}>
                <span className={`text-sm text-gray-500 dark:text-gray-400 mr-1`}>
                    Repeat:
                </span>
                <span className={` truncate text-sm text-gray-600 dark:text-gray-300`}>
                    {workOrder.workorder_recurrence_description}
                </span>
            </div>
        )}
        {workOrder.workorder_group && workOrder.workorder_group.group_uuid && (
            <div className={`flex pt-1 items-center`}>
                <span className={`text-sm text-gray-500 dark:text-gray-400 mr-1`}>
                    Group:
                </span>
                <span 
                    className='font-semibold text-sm'
                    style={{color: isDark ? workOrder.workorder_group.group_dark_color_hex : workOrder.workorder_group.group_light_color_hex}}
                >
                    {workOrder.workorder_group.group_name}
                </span>
            </div>
        )}
        <div className='flex justify-between items-center '>
            <div className={`text-gray-500 dark:text-gray-400 truncate text-sm`}>Requested by {workOrder.created_by_user?.user_first_name}</div>
            <button 
                className={`group  items-center  py-2 ${isDark?' text-blue-400 hover:text-blue-500':'text-blue-500 hover:text-blue-700'} border-transparent border`} 
                onClick={(e) => {
                    e.stopPropagation();
                    handleNavigation(workOrder.workorder_uuid)
                }}
            >
                <svg className="relative stroke-2 fill-[none] stroke-current" width="12" height="12" viewBox="0 0 10 10" aria-hidden="true">
                    <g fillRule="evenodd"> 
                        <path className="opacity-0 transition-all group-hover:opacity-100" d="M0 5h7"></path>
                        <path className="transition-all group-hover:translate-x-1" d="M1 1l4 4-4 4"></path>
                    </g>
                </svg>
            </button>
        </div>
        
    </div>
  )
}

export default KanbanCard


const AssignedCell = (isDark, selectedMembers) => {
    let membersList = []
    if (selectedMembers && Object.keys(selectedMembers).length > 0) {
        selectedMembers.map(value => {
            if(value && value.user_username){
                let name = `${value.user_first_name ? value.user_first_name:''} ${value.user_last_name ? value.user_last_name:''}`
                    membersList.push({
                        user_color: value.user_color ? value.user_color:'',
                        name: name,
                        image_url: value.user_image_url ? value.user_image_url : '/broken-image.jpg',
                    })
                }else if(value && value.team_organization_handle){
                    membersList.push({
                        user_color: value.user_color ? value.user_color:'',
                        name: value.team_name ? value.team_name:'',
                        image_url: '/broken-image.jpg',
                    })
                }
        })
    
          return(
            <div className='flex ml-auto items-center'>
                <AvatarGroup max={3} spacing={5} sx={{ '& .MuiAvatar-root': { border: '0px solid rgba(203, 213, 225, 0.1)', width:24, height:24, fontSize:'11px' } }} >
                    {membersList.map((member,index) =>(
                        <Avatar
                            key={`avatar-group-${index}`}
                            sx={{ 
                                bgcolor: member.user_color? member.user_color : '' , 
                                fontSize:'11px',
                                width:24, 
                                height:24,
                            }}
                            style={{
                                background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${member.user_color? member.user_color:''} 100%)`,
                            }}
                            alt={member.name}
                            src={member.image_url ? member.image_url :'/broken-image.jpg'}
                        />
                    ))}
                </AvatarGroup>
    
            </div>
        )
      }


}