// import CollapsibleCard from "../components/CollapsibleCard"
// import AddPartModal from "../components/modals/partModals/AddPartModal"
// import EditPartsListModal from "../components/modals/partModals/EditPartsListModal"
import PartFilterBtn from "../components/filterButtons/PartFilterBtn"
import DepartmentFilterBtn from "../components/filterButtons/DepartmentFilterBtn"
import MachineTypeFilterBtn from "../components/filterButtons/MachineTypeFilterBtn"
import SortByFilterBtn from "../components/filterButtons/SortByFilterBtn"
import { globalSelectedOrganization } from "../atoms/orgStates"
import { useRecoilValue } from "recoil"
import { useEffect, useRef, useState } from "react"
import { getData } from "../ApiCalls/DataApis"
import { apiLink } from "../ApiCalls/ApisVariables"
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import TagFilterBtn from "../components/filterButtons/TagFilterBtn"
import ShiftFilterBtn from "../components/filterButtons/ShiftFilterBtn"
import { refreshOrganizationApis } from "../atoms/refreshStates"
import Switch from '@mui/material/Switch';
import { MemberTooltip } from '../styles/Tooltip.js'
import { Zoom } from '@mui/material';
import { darkModeState } from "../atoms/darkModeState"
import OpsCollapsibleCard from "./OpsCollapsibleCard"
import { motion } from "framer-motion";
import MobileRightDrawer from "../components/MobileRightDrawer.js"
import ResetFiltersBtn from "../components/filterButtons/ResetFiltersBtn.js"

function PartProductionPage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const isDark = useRecoilValue(darkModeState)
    const navigate = useNavigate()
    const isOrganizationApisRefreshed = useRecoilValue(refreshOrganizationApis)
    const [currentShiftData, setCurrentShiftData] = useState([])
    const [loadingData, setLoadingData] = useState(false)
    const [toggleOperationsDropdown, setToggleOperationsDropdown] = useState(true)
    const [toggleAssetsDropdown, setToggleAssetsDropdown] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(false)
    const prevUrlRef = useRef(null);
    const [shiftProduction, setShiftProduction] = useState([])
    const [dateCategories, setDateCategories] = useState([])

    useEffect(() => {
        let operations_expanded = searchParams.get('operations_expanded')
        let assets_expanded = searchParams.get('assets_expanded')

        if(operations_expanded == 'isExpanded'){
            setToggleOperationsDropdown(false)
        }else if(operations_expanded == 'isNotExpanded'){
            setToggleOperationsDropdown(true)
        }
        if(assets_expanded == 'isExpanded'){
            setToggleAssetsDropdown(false)
        }else if(assets_expanded == 'isNotExpanded'){
            setToggleAssetsDropdown(true)
        }

        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }

    },[])

    useEffect(() => {
        try {
            if(selectedOrganization && location && location.pathname){
                const currentUrl = location.pathname + location.search;
                if (prevUrlRef.current !== currentUrl) {
                    // URL path has changed
                    // console.log(time,"URL changed from", prevUrlRef.current, "to", currentUrl);
                    prevUrlRef.current = currentUrl; // Update stored URL
                    getOpsList()
                    // getCurrentShift() 
                    // getHistoricalPartList()
                  }
                
            }
            
        } catch (error) {
          console.log(error.message)
        }
    },[selectedOrganization,location, isOrganizationApisRefreshed])

    useEffect(() => {
        
        let isOpsExpanded = 'isNotExpand'
        
        if(!toggleOperationsDropdown){
            isOpsExpanded = 'isExpanded'
        }
        let isAssetsExpanded = 'isNotExpand'
        if(!toggleAssetsDropdown){
            isAssetsExpanded = 'isExpanded'
        }

        // apply all filters
        let select = searchParams.getAll('select')
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let maximize = searchParams.getAll('maximize')
        let view = searchParams.getAll('view')
        let url_token = searchParams.getAll('url_token')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')

        setSearchParams({select,parts, departments, machine_types, tags, group_by, sort_by,calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, maximize, view, url_token, operations_expanded: isOpsExpanded, assets_expanded: isAssetsExpanded, platform, is_god_view_enabled})

    },[toggleOperationsDropdown, toggleAssetsDropdown])

    


    const getOpsList = () => {
        try {
            if(selectedOrganization && !loadingData){
                setLoadingData(true)
                let currentSearch = location.search
                // New API V3 (2 Layer) that removed parts and just shows operations and machines
                setCurrentShiftData([])
                setShiftProduction([])
                setShiftProduction([])
                // getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/shift_productions' + currentSearch )
                // .then(async response => {
                //     // JSON data parsed by response.json() call
                //     if(response.ok ){
                //     const data = await response.json();
                //         if(data && data.data){
                //             // setCurrentShiftData(data.data)
                //             // setShiftProduction(data.data.operations)

                //             // let timeCategoriesAll = []
                //             // let timeCategoriesTrimmed = []
                //             // if(data.data.operations && data.data.operations[0] && Object.keys(data.data.operations[0]).length > 0 && data.data.operations[0].hourly_operations_made){
                //             //     let lastDataIndex = Object.keys(data.data.operations[0].hourly_operations_made).length -1
                                
                //             //     data.data.operations[0].hourly_operations_made.map((hourCount, index) => {
                //             //         let time = ''
                //             //         if(index === 0) {
                //             //             time = formatAMPM(hourCount.interval_timestamp)
                //             //             timeCategoriesTrimmed.push(time)
                //             //         }else if(index === lastDataIndex){
                //             //             time = formatAMPM(hourCount.interval_timestamp)
                //             //             timeCategoriesTrimmed.push(time)
                //             //         }else{
                //             //             timeCategoriesTrimmed.push(time)
                //             //         }
        
                //             //         let currentTime = formatAMPM(hourCount.interval_timestamp)
                //             //         timeCategoriesAll.push(currentTime)
        
                //             //     })
                //             // }
                //             //     setDateCategories(timeCategoriesAll)
                //             //     setLoadingData(false)
                //         }else{
                //             setLoadingData(false)
                //         }
                //     }
                // })
                getData(apiLink + '/v2/organizations/'+ selectedOrganization + '/shift_productions' + currentSearch )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setCurrentShiftData(data.data)
                            setShiftProduction(data.data.operations)

                            let timeCategoriesAll = []
                            let timeCategoriesTrimmed = []
                            if(data.data.operations && data.data.operations[0] && Object.keys(data.data.operations[0]).length > 0 && data.data.operations[0].hourly_operations_made){
                                let lastDataIndex = Object.keys(data.data.operations[0].hourly_operations_made).length -1
                                
                                data.data.operations[0].hourly_operations_made.map((hourCount, index) => {
                                    let time = ''
                                    if(index === 0) {
                                        time = formatAMPM(hourCount.interval_timestamp)
                                        timeCategoriesTrimmed.push(time)
                                    }else if(index === lastDataIndex){
                                        time = formatAMPM(hourCount.interval_timestamp)
                                        timeCategoriesTrimmed.push(time)
                                    }else{
                                        timeCategoriesTrimmed.push(time)
                                    }
        
                                    let currentTime = formatAMPM(hourCount.interval_timestamp)
                                    timeCategoriesAll.push(currentTime)
        
                                })
                            }
                                setDateCategories(timeCategoriesAll)
                                setLoadingData(false)
                        }else{
                            setLoadingData(false)
                        }
                    }
                })

 

            }
            
        } catch (error) {
          console.log(error.message)
          setLoadingData(false)
        }
    }

    function formatAMPM(date) {
        let event = new Date(date)
        let currentTime = event.toLocaleTimeString('en-US', { hour: '2-digit'})
        currentTime = currentTime.replace(/\s/g, '')
        if(currentTime[0] == '0'){
            currentTime = currentTime.substring(1)
        }

        return currentTime
    }

    const handleChangeOperationsDropdown = (e) => {
        if(e.target.checked){
            setToggleAssetsDropdown(true)
        }
        setToggleOperationsDropdown(e.target.checked)
    }

  return (
    <div className='mt-2 p-1 h-screen scrollbar-hide'>
        {isMobile ? (
            <div className='flex justify-end'>
                <MobileRightDrawer >
                    <div className='flex flex-col space-y-3'>
                            <PartFilterBtn select={'part'} />
                            <DepartmentFilterBtn select={'part'} />
                            <MachineTypeFilterBtn select={'part'} />
                            <TagFilterBtn select={'part'} />
                            <SortByFilterBtn select={'part'} />
                            <ShiftFilterBtn select={'part'} />
                        
                    </div>

                </MobileRightDrawer>
            </div>
        ):(
            <div className={`hidden sm:flex flex-col xl:flex-row items-center mb-4`}>
            {/* <div className={`${isMobile? 'hidden':'hidden sm:flex'}  flex-col xl:flex-row items-center mb-4`}> */}
                <div className='mr-auto flex flex-col lg:flex-row'>
                    <div className='space-x-2 inline-flex'>
                        <PartFilterBtn select={'part'} />
                        <DepartmentFilterBtn select={'part'} />
                        <MachineTypeFilterBtn select={'part'} />
                    </div>
                    <div className='space-x-2 inline-flex mt-2 lg:mt-0 lg:ml-2'>
                        <TagFilterBtn select={'part'} />
                        <SortByFilterBtn select={'part'} />
                        <ShiftFilterBtn select={'part'} />
                        <ResetFiltersBtn ignoreParams={['select', 'platform', 'operations_expanded', 'assets_expanded']} />
                    </div>
                    
                </div>
                

                <div className='flex space-x-2 items-center ml-auto pl-2 py-2 xl:py-0'>
                    <MemberTooltip TransitionComponent={Zoom}
                        title={
                            <div className={`flex flex-col rounded-lg p-2 ${isDark? ' bg-slate-900':' bg-slate-50'}`}>
                            <div className={`w-full text-center py-1 text-sm ${isDark? ' text-gray-400':'text-gray-700'} `}>
                                <span>{toggleOperationsDropdown? 'Expand Operations': 'Collapse Operations'}</span>
                            </div>
                            <div className={`w-full text-center py-1 text-sm  ${isDark? ' text-gray-400':'text-gray-700'} `}>
                                <span>{toggleOperationsDropdown? 'Expand all dropdowns for all your parts to show operations content': 'Collapse all operations and assets to only show part progress'}</span>
                            </div>
                            <div className={`w-full text-center py-1 text-sm italic ${isDark? ' text-gray-400':'text-gray-700'} `}>
                                <span>Purpose: This toggle is useful when wanting to send a URL link to a tv screen. It allows you to decide if you want operations data to be shown</span>
                            </div>
                            </div>
                        }
                    >
                        <div className='flex items-center'>
                            <p className='text-gray-600 dark:text-gray-400 text-sm truncate'>{toggleOperationsDropdown? 'Expand Operations': 'Collapse Operations'} </p>
                            <Switch
                                size="small"
                                color="secondary"
                                checked={toggleOperationsDropdown}
                                onChange={handleChangeOperationsDropdown}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </MemberTooltip>
                    <motion.button  
                        whileHover={{ scale: 1.05 }} 
                        whileTap={{ scale: 0.95 }}  
                        className='btnMain px-4' 
                        onClick={() => navigate(`/${selectedOrganization}/production?select=parts_list`)} 
                    >
                        <span className='btnMainText'>Add/Edit Parts</span>
                    </motion.button>
                    {/* <EditPartsListModal btnType='standardBtn' partsList={partsList} getOpsList={getOpsList} />
                    <AddPartModal btnType='standardBtn' getOpsList={getOpsList} /> */}
                </div>
            </div>
        )}
        <div className='rounded-full'>
            {loadingData &&
                <div>
                    <div className='mt-2 flex-flex-col rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg p-4'>
                    <div className="animate-pulse flex space-x-4">
                        <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-14 w-14"></div>
                        <div className="flex-1 space-y-3 py-1">
                            <div className="h-5 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            <div className="h-5 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            <div className="space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                <div className="h-5 bg-slate-300 dark:bg-slate-700 rounded-xl col-span-2"></div>
                                <div className="h-5 bg-slate-300 dark:bg-slate-700 rounded-xl col-span-1"></div>
                                </div>
                                <div className="h-5 bg-slate-300 dark:bg-slate-700 rounded-xl"></div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            }
            {!loadingData && !shiftProduction && 
                    <div>
                        {/* <LinearProgress color="primary" sx={{height:2}}/> */}
                        <div className='mt-2 flex-flex-col rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg px-4 pt-5 pb-8'>
                            <h6 className='text-gray-600 dark:text-gray-300 text-2lx text-center text-light'>
                                { `(;-;)`}
                            </h6>
                            <h6 className='text-gray-600 dark:text-gray-300 text-center text-light'>
                                There are no parts in production for this shift.
                            </h6>
                            <h6 className='text-gray-600 dark:text-gray-300 text-center text-light'>
                            Please check back later or adjust the shift schedule.
                            </h6>
                        </div>
                    </div>
                }
        </div>
        <div className='space-y-2 mb-8'>
        {!loadingData && shiftProduction && Object.keys(shiftProduction).length > 0 &&
         shiftProduction.map((operation,index) => (
            <div key={index} >
                <OpsCollapsibleCard operation={operation} index={index} currentShiftData={currentShiftData} getOpsList={getOpsList} dateCategories={dateCategories} />
            </div>
        ))}
        </div>


    </div>
  )
}

export default PartProductionPage