import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import KanbanCard from './KanbanCard';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { statusOptions, statusXLOptions } from '../statusOptions';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { putData } from '../../ApiCalls/DataApis';
import toast from 'react-hot-toast';

function GroupKanbanView({workOrdersList, workOrdersDoneList, isManagingColumns, getWorkOrders, isMobile, selectedOrganization, setIsManagingColumns, groupList, prioritiesList}) {
    const isDark = useRecoilValue(darkModeState);
    const [orderedColumns, setOrderedColumns] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [columns, setColumns] = useState({});

    useEffect(() => {
        let work_orders = []
        if(groupList && Object.keys(groupList).length > 0  && workOrdersList && Object.keys(workOrdersList).length > 0){
            let tempCols = {}
            let colOrder = []
            groupList.map((option) => {
                let colName = `col-${option.group_uuid}`
                tempCols[colName] = {
                    title: option.group_name,
                    uuid: option.group_uuid,
                    lightMode: option.group_light_color_hex,
                    darkMode: option.group_dark_color_hex,
                    items:[]
                }
                colOrder.push(colName)

            })
            setOrderedColumns(colOrder)

            workOrdersList.map(section => {
                // console.log('section', section)
                section.workorders.map((workOrder,i) => {
                    let order = workOrder
                    let wo_group_uuid = workOrder.workorder_group?.group_uuid
                    // order.title = workOrder.workorder_group?.group_name
                    // order.lightMode = workOrder.workorder_group?.group_light_color_hex
                    // order.darkMode = workOrder.workorder_group?.group_dark_color_hex
                    // order.title = workOrder.workorder_group?.group_name
                    order.columnId = `col-${wo_group_uuid}`
                    order.id = workOrder.workorder_uuid
                    order.content = workOrder.workorder_title
                    work_orders.push(order)
                })
    
            })

            setColumns(() => {
                const updatedColumns = {... tempCols};
        
                // Loop through the jsonData and group tasks by columnId
                work_orders.forEach((task) => {
                    const { columnId, ...taskDetails } = task;
        
                    // Initialize column if it doesn't exist
                    if (!updatedColumns[columnId]) {
                        updatedColumns[columnId] = { ...columns[columnId], items: [] };
                    }
        
                    // Add task to the corresponding column
                    updatedColumns[columnId].items.push(taskDetails);
                });
        
                return updatedColumns;
            });

        }else if(groupList && Object.keys(groupList).length > 0){
            let tempCols = {}
            let colOrder = []
            groupList.map((option) => {
                let colName = `col-${option.group_uuid}`
                tempCols[colName] = {
                    title: option.group_name,
                    uuid: option.group_uuid,
                    lightMode: option.group_light_color_hex,
                    darkMode: option.group_dark_color_hex,
                    items:[]
                }
                colOrder.push(colName)

            })
            setColumns(tempCols)
            setOrderedColumns(colOrder)

        }

    },[groupList, workOrdersList])

      

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination, type, draggableId } = result;

        if (type === 'column') {
            const newOrder = Array.from(orderedColumns);
            const [removed] = newOrder.splice(source.index, 1);
            newOrder.splice(destination.index, 0, removed);
            setOrderedColumns(newOrder);
            return;
        }

        const sourceCol = columns[source.droppableId];
        const destCol = columns[destination.droppableId];
        
        if (!sourceCol || !destCol) return;
        if(sourceCol.uuid == destCol.uuid) return;

        let destTitle = destCol.title
        let destUuid = destCol.uuid
        let destGroup = groupList.find((option) => option.group_uuid == destUuid)

        const draggedTask = sourceCol.items.find((item) => item.id === draggableId);
        const updatedTask = { ...draggedTask, workorder_group: destGroup };
        // Step 4: Remove the task from the source column and add it to the destination column
        const newSourceItems = [...sourceCol.items];
        newSourceItems.splice(source.index, 1);

        const newDestinationItems = [...destCol.items];
        newDestinationItems.splice(destination.index, 0, updatedTask);

        setColumns((prevColumns) => ({
            ...prevColumns,
            [source.droppableId]: {
              ...sourceCol,
              items: newSourceItems,
            },
            [destination.droppableId]: {
              ...destCol,
              items: newDestinationItems,
            },
          }));

         
        try{
            if(draggableId){
                putData(apiLink + '/v1/workorders/' + draggableId , 
                    {'workorder_group_uuid': destUuid})
                    .then(async response => {
                        const data = await response.json();
                        if(response.ok){
                          getWorkOrders()
                            // setLoadingStatus(false)
                        }
                        else{
                            getWorkOrders()
                            // let status = statusOptions.find((status) => status.workorder_status_uuid === selectedWorkOrder.workorder_status_uuid)
                            // setCurrentStatus(status)
                            // setLoadingStatus(false)
                            try{
                                toast.error(data.message)
                            }catch (e){
                                toast.error(e)
                            }
                        }
                    })
            }
        }catch (e){
            toast.error(e)
        }
    };

    const toggleColumnVisibility = (colId) => {
        if (hiddenColumns.includes(colId)) {
            setHiddenColumns(hiddenColumns.filter(id => id !== colId));
        } else {
            setHiddenColumns([...hiddenColumns, colId]);
        }
    };



if(!columns) return 



  return (
    <div className='overflow-x-auto w-full'>
        <AnimatePresence mode='wait'>
            {isManagingColumns && (
            <motion.div
                key={'managingPriorityContainer'}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                // exit={{ y: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="my-1 px-4 py-2 shadow-iotflows-lg bg-white dark:bg-slate-800/70 rounded"
             >
                <div className='flex justify-between mb-2'>
                    <h3 className="font-semibold  text-gray-600 dark:text-gray-300">Toggle Column Visibility</h3>
                    <motion.button 
                        whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}
                        className='text-gray-600 dark:text-gray-300' 
                        onClick={() => setIsManagingColumns(false)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </motion.button>

                </div>
                <div className="flex gap-2 flex-wrap">
                {orderedColumns.map(colId => (
                    <motion.button
                    whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}
                    key={colId}
                    onClick={() => toggleColumnVisibility(colId)}
                    className={` pl-3 pr-4 py-0.5 rounded-full ${
                        hiddenColumns.includes(colId) 
                        ? `bg-gray-100 text-gray-600 dark:bg-gray-600 dark:text-white opacity-60` 
                        : `bg-sky-50 dark:bg-sky-950 text-gray-600 dark:text-white`
                    }`}
                    >
                        <div style={{color: isDark ? columns[colId].darkMode : columns[colId].lightMode}}>
                            {columns[colId].title}
                        </div>
                    </motion.button>
                ))}
                </div>
            </motion.div>
            )}
        </AnimatePresence>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="board" type="column" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex gap-6 p-2"
              >
                {orderedColumns
                  .filter(colId => !hiddenColumns.includes(colId))
                  .map((colId, index) => (
                    <Draggable key={colId} draggableId={colId} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="flex-shrink-0 w-[400px]  max-h-[calc(100vh-15rem)]"
                        >
                          <div className="bg-white dark:bg-slate-700 rounded-3xl shadow-lg  flex flex-col max-h-[calc(100vh-15rem)]">
                            <div 
                              {...provided.dragHandleProps}
                              className="flex items-center justify-between p-3 bg-sky-50 dark:bg-sky-950 rounded-t-3xl cursor-move"
                            >
                              
                                <div
                                    className='font-semibold text-lg' 
                                    style={{color: isDark ? columns[colId].darkMode : columns[colId].lightMode}} >
                                    {columns[colId] && columns[colId].title ? columns[colId].title : 'No Title'}
                                </div>
                            </div>
                            <Droppable droppableId={colId} type="task">
                              {(provided) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  className="flex-1 px-4 py-3 space-y-3 overflow-y-scroll"
                                >
                                  {columns[colId] && columns[colId].items &&  Object.keys(columns[colId].items).length > 0 && columns[colId].items.map((workOrder, index) => (
                                    <Draggable 
                                      key={`${workOrder.id}`} 
                                      draggableId={`${workOrder.id}`} 
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="py-2 px-2 bg-white dark:bg-slate-900 rounded-xl shadow-iotflows-lg hover:bg-slate-50 dark:hover:bg-slate-950"
                                        >
                                            <KanbanCard workOrder={workOrder} isMobile={isMobile} selectedOrganization={selectedOrganization} />
                                            
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </div>
                      )}
                    </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
    </div>
  )
}

export default GroupKanbanView