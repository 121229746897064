import React, { useCallback, useEffect, useRef, useState } from 'react'
import { apiLink } from '../../ApiCalls/ApisVariables';
import { getData } from '../../ApiCalls/DataApis';
import ExpandImgMediaModal from '../../components/modals/messagingModals/ExpandImgMediaModal';
import { useLocation, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const DEBOUNCE_DELAY = 500; // milliseconds

function MediaPage({participants, chatInfo, userInfo}) {
    const location = useLocation();
    const [isLoadingFile, setIsLoadingFile] = useState(false)
    const [loadingImages, setLoadingImages] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true);
    const [images, setImages] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
    const [hasMoreImages, setHasMoreImages] = useState(false);
    const observer = useRef();
    const loadMoreTimeoutRef = useRef();
    const lastLoadTimeRef = useRef(0);
    
    
    useEffect(() => {
      if(chatInfo && chatInfo.chatroom_uuid){
            getData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + '/messages?only_compressed_images=true&latest_messages=true')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data){
                    // console.log('Media data',data)
                  if(data.data){
                    setImages(data.data)
                    // setInitialLoading(false)
                    lastLoadTimeRef.current = Date.now();
                    setHasMoreImages(data.has_older_messages)
                    setInitialLoading(false)
                  }
                }else{
                    setInitialLoading(false)
                    setHasMoreImages(false)
                }
              }
            })
        }
    }, [location, chatInfo])

    const loadMoreImages = (updatedImages) => {
        try{
          if(!loadingImages && hasMoreImages){
            setLoadingImages(true)
  
            // Convert the object to an array of entries ([key, value] pairs)
            let messageArray = Object.entries(updatedImages);
    
            // Get the last entry (assuming there is at least one message)
            let lastIndex = Object.keys(messageArray).length - 1
            let [lastKey, lastImage] = messageArray[lastIndex];
            let dateEnd = new Date(lastImage.message_created_at);
            let dateEndObject  = dateEnd.toISOString()


            getData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + `/messages?only_compressed_images=true&messages_before_timestamp=${dateEndObject}`)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                    let newMessages = data.data
                    //   console.log('data.data', data.data)
                    
                    setImages((prevMessages) => {
                        return { ...prevMessages, ...newMessages };
                    });
                    lastLoadTimeRef.current = Date.now();
                    setHasMoreImages(data.has_older_messages)
                    setLoadingImages(false)
                }else{
                    setLoadingImages(false)
                }
              }
            })
          }
          
  
        }catch(e){
          toast.error(e.message)
          setLoadingImages(false)
        }
      };

      const loadTriggerRef = useCallback(node => {
        if (loadingImages || initialLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasMoreImages) {
          //   loadMoreImages(images);
            const now = Date.now();
            if (now - lastLoadTimeRef.current > DEBOUNCE_DELAY) {
                loadMoreImages(images);
            } else {
                clearTimeout(loadMoreTimeoutRef.current);
                loadMoreTimeoutRef.current = setTimeout(loadMoreImages(images), DEBOUNCE_DELAY);
            }
          }
        });
        if (node) observer.current.observe(node);
      }, [loadingImages, hasMoreImages, images, initialLoading]);



    const handleDownloadFile = async (message) => {
        setIsLoadingFile(true);
        try {
            getData(apiLink + '/v1/files/' + message.file_uuid + '/download_request' )
            .then(async response => {
                const data = await response.json();
                if(response.ok ){
                if(data && data.data && data.data.signed_url){
                    
                    // console.log('data', data.data.signed_url)
                    let url = data.data.signed_url
                    const response = await fetch(url);
                    const blob = await response.blob();
                    const downloadUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = message.file_name || 'download'; // Set the download filename
                    link.click();
                    window.URL.revokeObjectURL(downloadUrl); // Clean up the object URL
                    
                }
                }
            })
        } catch (error) {
          toast.error('Error downloading file:', error);
        } finally {
            setIsLoadingFile(false);
        }
    };
  return (
    <div className='' style={{overflowAnchor: 'none'}} >
      {!initialLoading && Object.keys(images).length > 0 ? (
            <div className="container mx-auto rounded-xl overflow-y-scroll scrollbar-hide" style={{overflowAnchor: 'auto'}}>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
                    {Object.keys(images).map((image, index) => (
                            <ExpandImgMediaModal key={image}  message={images[image]} imageUrl={images[image].compressed_image_url} participants={participants} handleDownloadFile={handleDownloadFile}  />
                    ))}
                </div>
            </div>
        ):(
            <div/>
        )}
        {/* Load trigger element */}
        
      {!initialLoading && !loadingImages && Object.keys(images).length === 0 ? (
            <div>
                <div className='mt-2 flex flex-col rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg p-4'>
                    <h6 className='text-gray-600 dark:text-gray-300 text-2lx text-center text-light'>
                        { `(;-;)`}
                    </h6>
                    <h6 className='text-gray-600 dark:text-gray-300 text-center text-light'>
                        No images have been shared in this chat.
                    </h6>
                </div>
            </div>
        ):(
            <div/>
        )}
      {initialLoading || loadingImages ? (
            <div>
                <div className='mt-2 flex justify-center p-4'>
                    <div className="flex items-center justify-center text-gray-600 dark:text-gray-300 text-light">
                        Loading images
                        <span className="dot1 animate-bounce ml-0.5" style={{ animationDelay: '0s' }}>.</span>
                        <span className="dot2 animate-bounce ml-0.5" style={{ animationDelay: '0.2s' }}>.</span>
                        <span className="dot3 animate-bounce ml-0.5" style={{ animationDelay: '0.4s' }}>.</span>
                    </div>
                </div>
            </div>
        ):(
            <div/>
        )}
        {(!initialLoading &&  !loadingImages && hasMoreImages ) ? (
            <div
                ref={loadTriggerRef}
                style={{ height: '5px', marginTop: '-10px' }}
            />
        ):(
            <div/>
        )}
    </div>
  )
}

export default MediaPage