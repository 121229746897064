import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import KanbanCard from './KanbanCard';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { statusOptions, statusXLOptions } from '../statusOptions';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { putData } from '../../ApiCalls/DataApis';
import toast from 'react-hot-toast';

function PriorityKanbanView({workOrdersList, workOrdersDoneList, isManagingColumns, getWorkOrders, isMobile, selectedOrganization, setIsManagingColumns, prioritiesList}) {
    const isDark = useRecoilValue(darkModeState);
    const [orderedColumns, setOrderedColumns] = useState(['col-1-none', 'col-2-low', 'col-3-medium', 'col-4-high']);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [columns, setColumns] = useState({
        'col-1-none': {
          title: 'None',
          uuid: "WORKORDER_PRIORITY_NONE",
          items: []
        },
        'col-2-low': {
          title: 'Low',
          uuid: "WORKORDER_PRIORITY_LOW",
          items: []
        },
        'col-3-medium': {
          title: 'Medium',
          uuid: "WORKORDER_PRIORITY_MEDIUM",
          items: []
        },
        'col-4-high': {
          title: 'High',
          uuid: "WORKORDER_PRIORITY_HIGH",
          items: []
        }
      });


    useEffect(() => {
    let work_orders = []
        if(workOrdersList && Object.keys(workOrdersList).length > 0){
        workOrdersList.map(section => {
            // console.log('section', section)
            section.workorders.map((workOrder,i) => {
                let order = workOrder
                order = workOrder
                let wo_priority_uuid = workOrder.workorder_priority?.workorder_priority_uuid
                let wo_priority = workOrder.workorder_priority?.workorder_priority_name
                if(wo_priority_uuid === "WORKORDER_PRIORITY_NONE"){
                    order.columnId = 'col-1-none'
                }else if(wo_priority_uuid === "WORKORDER_PRIORITY_LOW"){
                    order.columnId = 'col-2-low'
                    
                }else if(wo_priority_uuid === "WORKORDER_PRIORITY_MEDIUM"){
                    order.columnId = 'col-3-medium'
                    
                }else if(wo_priority_uuid === "WORKORDER_PRIORITY_HIGH"){
                    order.columnId = 'col-4-high'
                }
                order.id = workOrder.workorder_uuid
                order.content = workOrder.workorder_title
                order.priority = wo_priority
                work_orders.push(order)
            })

        })
        updateMainColumns(work_orders)
    }

    },[workOrdersList])

    // useEffect(() => {
    // if(workOrdersDoneList && Object.keys(workOrdersDoneList).length > 0){
    //     let work_orders = []
    //     workOrdersDoneList.map(section => {
    //         section.workorders.map((workOrder,i) => {
    //             let order = workOrder
    //             order = workOrder
    //             let wo_priority_uuid = workOrder.workorder_priority?.workorder_priority_uuid
    //             let wo_priority = workOrder.workorder_priority?.workorder_priority_name
    //             if(wo_priority_uuid === "WORKORDER_PRIORITY_NONE"){
    //                 order.columnId = 'col-1-none'
    //             }else if(wo_priority_uuid === "WORKORDER_PRIORITY_LOW"){
    //                 order.columnId = 'col-2-low'
                    
    //             }else if(wo_priority_uuid === "WORKORDER_PRIORITY_MEDIUM"){
    //                 order.columnId = 'col-3-medium'
                    
    //             }else if(wo_priority_uuid === "WORKORDER_PRIORITY_HIGH"){
    //                 order.columnId = 'col-4-high'
    //             }
    //             order.id = workOrder.workorder_uuid
    //             order.content = workOrder.workorder_title
    //             order.priority = wo_priority
    //             work_orders.push(order)
    //         })

    //     })  
    //     updateDoneColumn(work_orders)
    //     }
        

    // },[workOrdersDoneList])


    const updateMainColumns = (jsonData) => {
        setColumns((prevColumns) => {
            const updatedColumns = { ...prevColumns };
            
            // Define which columns to update
            const mainColumns = ['col-1-none', 'col-2-low', 'col-3-medium', 'col-4-high'];
            
            // Clear main columns
            mainColumns.forEach(colId => {
                updatedColumns[colId] = {
                    ...updatedColumns[colId],
                    items: []
                };
            });
    
            // Add new items to main columns
            jsonData.forEach((task) => {
                const { columnId, ...taskDetails } = task;
                
                if (mainColumns.includes(columnId)) {
                    updatedColumns[columnId] = {
                        ...updatedColumns[columnId],
                        items: [...updatedColumns[columnId].items, taskDetails]
                    };
                }
            });
    
            return updatedColumns;
        });
    };

    // const updateDoneColumn = (jsonData) => {
    //     setColumns((prevColumns) => {
    //         const updatedColumns = { ...prevColumns };
            
    //         // Clear only the Done column
    //         updatedColumns['col-4-high'] = {
    //             ...updatedColumns['col-4-high'],
    //             items: []
    //         };
    
    //         // Add new items to Done column
    //         jsonData.forEach((task) => {
    //             const { columnId, ...taskDetails } = task;
                
    //             if (columnId === 'col-4-high') {
    //                 updatedColumns['col-4-high'] = {
    //                     ...updatedColumns['col-4-high'],
    //                     items: [...updatedColumns['col-4-high'].items, taskDetails]
    //                 };
    //             }
    //         });
    
    //         return updatedColumns;
    //     });
    // };
    


    // const updateColumns = (jsonData) => {
    //     setColumns((prevColumns) => {
    //       // Create a copy of the current columns
    //       const updatedColumns = { ...prevColumns };

    //       // Get unique columnIds from jsonData
    //       const columnsToUpdate = [...new Set(jsonData.map(task => task.columnId))];
          
    //       // Clear only the columns that will be updated
    //       columnsToUpdate.forEach(colId => {
    //           if (updatedColumns[colId]) {
    //               updatedColumns[colId] = {
    //                   ...updatedColumns[colId],
    //                   items: []
    //               };
    //           }
    //       });
      
    //        // Iterate through the JSON data
    //         jsonData.forEach((task) => {
    //             const { columnId, ...taskDetails } = task; // Destructure columnId and keep the rest of the task properties
        
    //             // Check if the column exists
    //             if (updatedColumns[columnId]) {
    //             // Add the new task with all properties to the column's items array
    //             updatedColumns[columnId] = {
    //                 ...updatedColumns[columnId],
    //                 items: [...updatedColumns[columnId].items, taskDetails]
    //             };
    //             }
    //         });
      
    //       return updatedColumns; // Return the new state
    //     });
    //   };
      
      

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination, type, draggableId } = result;
        
        

        if (type === 'column') {
            const newOrder = Array.from(orderedColumns);
            const [removed] = newOrder.splice(source.index, 1);
            newOrder.splice(destination.index, 0, removed);
            setOrderedColumns(newOrder);
            return;
        }

        const sourceCol = columns[source.droppableId];
        const destCol = columns[destination.droppableId];
        
        if (!sourceCol || !destCol) return;
        if(sourceCol.uuid == destCol.uuid) return;

        // console.log('draggableId', draggableId )
        // console.log('sourceCol', sourceCol )
        // console.log('destCol', destCol )

        // const sourceItems = [...sourceCol.items];
        // const destItems = source.droppableId === destination.droppableId 
        //     ? sourceItems 
        //     : [...destCol.items];
        
        // const [removed] = sourceItems.splice(source.index, 1);
        // destItems.splice(destination.index, 0, removed);

        // setColumns({
        //     ...columns,
        //     [source.droppableId]: {
        //     ...sourceCol,
        //     items: sourceItems
        //     },
        //     [destination.droppableId]: {
        //     ...destCol,
        //     items: destItems
        //     }
        // });

        let destTitle = destCol.title
        let destUuid = destCol.uuid
        let destPriority = prioritiesList.find((priority) => priority.workorder_priority_uuid == destUuid)

        const draggedTask = sourceCol.items.find((item) => item.id === draggableId);
        const updatedTask = { ...draggedTask, workorder_priority: destPriority };
        // Step 4: Remove the task from the source column and add it to the destination column
        const newSourceItems = [...sourceCol.items];
        newSourceItems.splice(source.index, 1);

        const newDestinationItems = [...destCol.items];
        newDestinationItems.splice(destination.index, 0, updatedTask);

        setColumns((prevColumns) => ({
            ...prevColumns,
            [source.droppableId]: {
              ...sourceCol,
              items: newSourceItems,
            },
            [destination.droppableId]: {
              ...destCol,
              items: newDestinationItems,
            },
          }));

         
        try{
            if(draggableId){
                putData(apiLink + '/v1/workorders/' + draggableId , 
                    {'workorder_priority_uuid': destUuid})
                    .then(async response => {
                        const data = await response.json();
                        if(response.ok){
                          getWorkOrders()
                            // setLoadingStatus(false)
                        }
                        else{
                            getWorkOrders()
                            // let status = statusOptions.find((status) => status.workorder_status_uuid === selectedWorkOrder.workorder_status_uuid)
                            // setCurrentStatus(status)
                            // setLoadingStatus(false)
                            try{
                                toast.error(data.message)
                            }catch (e){
                                toast.error(e)
                            }
                        }
                    })
            }
        }catch (e){
            toast.error(e)
        }
    };

    const toggleColumnVisibility = (colId) => {
        if (hiddenColumns.includes(colId)) {
            setHiddenColumns(hiddenColumns.filter(id => id !== colId));
        } else {
            setHiddenColumns([...hiddenColumns, colId]);
        }
    };

    const deleteColumn = (colId) => {
        const newColumns = { ...columns };
        delete newColumns[colId];
        setColumns(newColumns);
        setOrderedColumns(orderedColumns.filter(id => id !== colId));
        setHiddenColumns(hiddenColumns.filter(id => id !== colId));
      };

    const matchColumnNames = (colTitle, size, prioritiesList) => {
        if(prioritiesList && Object.keys(prioritiesList).length > 0){
            let matchingStatus = []
            if(size === 'xl'){
                matchingStatus = prioritiesList.find(
                    (status) => status.workorder_priority_name === colTitle
                );
                if (matchingStatus) {
                    const { workorder_priority_name: name } = matchingStatus;
    
                    return (
                        <div className={`font-semibold text-gray-600 dark:text-gray-200 flex items-center items-center`}>
                            <div  className={`${ matchingStatus.workorder_priority_icon_color }`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor" >
                                    <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-160q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Z"/>
                                </svg>
                            </div>
                            <span className='ml-1 text-lg'>{name}</span>
                        </div>
                    );
                }else{
                    return(
                        <h3 className="font-semibold text-gray-600 dark:text-gray-200 ">{colTitle}</h3>
                    )
                }
            }else{
                    
                matchingStatus = prioritiesList.find(
                    (status) => status.workorder_priority_name === colTitle
                );
                if (matchingStatus) {
                    const { workorder_priority_name: name } = matchingStatus;
    
                    return (
                        <div className={`font-normal flex items-center items-center`}>
                            <div  className={`${ matchingStatus.workorder_priority_icon_color }`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960"  fill="currentColor" >
                                    <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-160q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Z"/>
                                </svg>
                            </div>
                            <span className='ml-1'>{name}</span>
                        </div>
                    );
                }else{
                    return(
                        <h3 className="font-normal text-sm">{colTitle}</h3>
                    )
                }
            }

        }
    }


  return (
    <div>
        <AnimatePresence mode='wait'>
            {isManagingColumns && (
            <motion.div
                key={'managingPriorityContainer'}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                // exit={{ y: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="my-1 px-4 py-2 shadow-iotflows-lg bg-white dark:bg-slate-800/70 rounded"
             >
                <div className='flex justify-between mb-2'>
                    <h3 className="font-semibold  text-gray-600 dark:text-gray-300">Toggle Column Visibility</h3>
                    <motion.button 
                        whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}
                        className='text-gray-600 dark:text-gray-300' 
                        onClick={() => setIsManagingColumns(false)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </motion.button>

                </div>
                <div className="flex gap-2 flex-wrap">
                {orderedColumns.map(colId => (
                    <motion.button
                    whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}
                    key={colId}
                    onClick={() => toggleColumnVisibility(colId)}
                    className={` pl-3 pr-4 py-0.5 rounded-full ${
                        hiddenColumns.includes(colId) 
                        ? `bg-gray-100 text-gray-600 dark:bg-gray-600 dark:text-white opacity-60` 
                        : `bg-sky-50 dark:bg-sky-950 text-gray-600 dark:text-white`
                    }`}
                    >
                    {matchColumnNames(columns[colId].title, 'md', prioritiesList)}
                    </motion.button>
                ))}
                </div>
            </motion.div>
            )}
        </AnimatePresence>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="board" type="column" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex-1 flex gap-6  overflow-x-auto p-2"
              >
                {orderedColumns
                  .filter(colId => !hiddenColumns.includes(colId))
                  .map((colId, index) => (
                    <Draggable key={colId} draggableId={colId} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="flex-shrink-0 w-[400px]  max-h-[calc(100vh-15rem)]"
                        >
                          <div className="bg-white dark:bg-slate-700 rounded-3xl shadow-lg  flex flex-col  max-h-[calc(100vh-15rem)]">
                            <div 
                              {...provided.dragHandleProps}
                              className="flex items-center justify-between p-3 bg-sky-50 dark:bg-sky-950 rounded-t-3xl cursor-move"
                            >
                              {matchColumnNames(columns[colId].title,'xl', prioritiesList)}
                              {(colId !== 'col-1-none' && colId !== 'col-2-low' && colId !== 'col-3-medium' && colId !== 'col-4-high') ? (
                                <motion.button
                                    whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}
                                  onClick={() => deleteColumn(colId)}
                                  className="p-1 text-gray-600 dark:text-gray-300 hover:bg-gray-100 hover:text-gray-800 rounded-full"
                                >
                                  <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                </motion.button>
                              ):(
                                <div/>
                              )}
                            </div>
                            <Droppable droppableId={colId} type="task">
                              {(provided) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  className="flex-1 px-4 py-3 space-y-3 overflow-y-scroll"
                                >
                                  {columns[colId].items.map((workOrder, index) => (
                                    <Draggable 
                                      key={`${workOrder.id}`} 
                                      draggableId={`${workOrder.id}`} 
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="py-2 px-2 bg-white dark:bg-slate-900 rounded-xl shadow-iotflows-lg hover:bg-slate-50 dark:hover:bg-slate-950"
                                        >
                                            <KanbanCard workOrder={workOrder} isMobile={isMobile} selectedOrganization={selectedOrganization} />
                                            
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </div>
                      )}
                    </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
    </div>
  )
}

export default PriorityKanbanView