import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getData, putData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import LogsTables from '../MyLogsPage/LogsTables';
import { AnimatePresence, motion } from 'framer-motion';
import MessagingPage from './woMessagingComponents.js/MessagingPage';
import SelectedWODetailsPage from './SelectedWODetailsPage';
import RightDrawerSelectedWODetailsPage from './RightDrawerSelectedWODetailsPage';


const subNav = [
    {
        name:'Details',
        url:'details'
    },
    {
        name:'Messages',
        url:'messages'
    },
    {
        name:'Activity Logs',
        url:'activity_logs' 
    },
]

function RightDrawerSelectedWOPage({mqttMessage, userInfo}) {
    const navigate = useNavigate();
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)
    const [selectedWorkOrderUuid, setSelectedWorkOrderUuid] = useState('')
    const [selectedWorkOrder, setSelectedWorkOrder] = useState([])
    const [workOrderLogs, setWorkOrderLogs] = useState([])
    const [loadingTable, setLoadingTable] = useState(false)
    const location = useLocation()

    const [selectedSubNav, setSelectedSubNav] = useState('details')
    const [selectedTab, setSelectedTab] = useState(subNav[0])



    useEffect(() =>{
        setSelectedSubNav(selectedTab.url)
    },[selectedTab])


    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      },[])

    useEffect(() => {
        let selectedWorkOrder = searchParams.get('selected_wo')
        setSelectedWorkOrderUuid(selectedWorkOrder)
        // setting logs to false before trying to load again since its taking so long 
        setLoadingTable(false)
        
      },[location])


    useEffect(() => {
        if(selectedOrganization && selectedWorkOrderUuid){
            getSelectedWorkOrder()
            getWorkOrderLogs()
        }
    }, [selectedOrganization, selectedWorkOrderUuid])

    const getSelectedWorkOrder = () => {
        if(selectedWorkOrderUuid){
            getData(apiLink + '/v1/workorders/'+ selectedWorkOrderUuid )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            // console.log('Selected WO ', data.data[0])
                            setSelectedWorkOrder(data.data[0])
                            
                        }else{
                            setSelectedWorkOrder([])
                        }
                    }else{
                        setSelectedWorkOrder([])
                    }
                })

        }

    }
    const getWorkOrderLogs = () => {
        if(selectedOrganization && selectedWorkOrderUuid){
            if(!loadingTable){
                setWorkOrderLogs([])
                setLoadingTable(true)
                getData(apiLink + `/v1/organizations/${selectedOrganization}/logs?text=${selectedWorkOrderUuid}`)
                    .then(async response => {                        
                        const data = await response.json();
                        if(response.ok ){
                            setLoadingTable(false)
                            if(data && data.data){
                                setWorkOrderLogs(data.data)
                                
                            }
                        }else{
                            setLoadingTable(false)
                        }
                    })
            }
        }

    }



    // const handleDescriptionUpdate = (event) => {
    //     const newTitle = event.target.value;
    
    //     // If no value, reset to original title
    //     if (!newTitle) {
    //         setWOTitle(selectedWorkOrder.workorder_description);
    //         setIsEditingWODescription(false);
    //         return;
    //     }

    //     if (loadingTitle) return;
    
    //     try {
    //         setLoadingDescription(true);
    //         setIsEditingWODescription(false);
    
    //         putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid, 
    //         {'workorder_description': event.target.value })
    //         .then(async response => {
    //             console.log('response', response)
    //             const data = await response.json();
    //             if(response.ok){
    //                 // toast.success('Description Change')
    //                 setLoadingDescription(false)
    //                 setWODescription(event.target.value)
    //             }
    //             else{
    //                 setWODescription(selectedWorkOrder.workorder_description)
    //                 setLoadingDescription(false)
    //                 try{
    //                     toast.error(data.message)
    //                 }catch (e){
    //                     toast.error(e)
    //                 }
    //             }
    //         })

    //     } catch (e) {
    //         setWODescription(selectedWorkOrder.workorder_description);
    //         toast.error(e.toString());
    //     }
    // };

    

    const handleBackNavigation = () => {
        

        let wo_status = searchParams.getAll('wo_status')
        let wo_priority = searchParams.getAll('wo_priority')
        let sort_by = searchParams.getAll('sort_by')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let q = searchParams.getAll('q')
        let platform = searchParams.getAll('platform')
        setSearchParams({wo_status,wo_priority, sort_by, from, to, q, platform})

    }
    
  

  return (
    <div className={`flex `}>
        <div className='grow'>
        {/* <div className={`${isMobile ? 'max-h-[calc(100vh)]':'min-h-[calc(100vh-5rem)] max-h-[calc(100vh-5rem)]'} overflow-scroll scrollbar-hide pb-10`}> */}
            {isMobile ? (
                <nav className='mb-2 flex items-center'>
                    <ol className='hidden sm:flex'>
                        <li className='breadcrumbsNav' onClick={handleBackNavigation}>Work Orders</li>
                        <li className='breadcrumbsSpacer'>/</li>
                        <li className='breadcrumbsCurrent'>{selectedWorkOrder.workorder_number  ? selectedWorkOrder.workorder_number :selectedWorkOrder.workorder_title}</li>
                    </ol>
                    <ol className='flex sm:hidden'>
                        <li className='breadcrumbsNav flex text-lg items-center' onClick={handleBackNavigation}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                </svg>
                            </div>
                            <span>Work Orders</span>
                        </li>
                    </ol>
                </nav>
            ): (
                <nav className='mb-2 flex items-center'>
                    <ol className='hidden sm:flex'>
                        <li className='breadcrumbsNav' onClick={handleBackNavigation}>Work Orders</li>
                        <li className='breadcrumbsSpacer'>/</li>
                        <li className='breadcrumbsCurrent'>{selectedWorkOrder.workorder_number  ? selectedWorkOrder.workorder_number :selectedWorkOrder.workorder_title}</li>
                    </ol>
                    <ol className='flex sm:hidden'>
                        <li className='breadcrumbsNav flex text-lg items-center' onClick={handleBackNavigation}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                </svg>
                            </div>
                            <span>Work Orders</span>
                        </li>
                    </ol>
                    <motion.button
                        whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.90 }}  
                        className='text-gray-600 dark:text-gray-300 ml-auto'
                        onClick={handleBackNavigation}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5'  viewBox="0 -960 960 960" fill="currentColor">
                            <path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z"/>
                        </svg>
                    </motion.button>
                </nav>
            )}
           
            <div className='flex flex-col-reverse sm:flex-row w-full mt-2 px-2'>
                <nav>
                    <div className='flex overflow-x-scroll scrollbar-hide space-x-2 md:space-x-3 border-b border-blue-600 w-fit'>
                    {subNav.map((item) => (
                        <div
                        key={item.url}
                        className='relative pt-0.5 pb-1.5'
                        onClick={() => setSelectedTab(item)}
                        >
                        <span className={`${item.url === selectedTab.url ? 'font-medium':' font-base'} mr-1 pb-1 text-gray-600 dark:text-gray-100  hover:text-gray-900 dark:hover:text-gray-300  outline-none text-base cursor-pointer bg-transparent whitespace-nowrap`}>{`${item.name}`}</span>
                        {item === selectedTab ? (
                            <motion.div className="underline" layoutId="underline" />
                        ) : null}
                        </div>
                    ))}
                    </div>
                </nav>
                
            </div>
            <div className=''>
                {selectedSubNav && selectedWorkOrder && Object.keys(selectedWorkOrder).length > 0 && 
                    subLinkPage(selectedSubNav, selectedWorkOrder, loadingTable, workOrderLogs, mqttMessage, userInfo, selectedOrganization, getSelectedWorkOrder)
                }
            </div>
            
        </div>
    </div>
  )
}

export default RightDrawerSelectedWOPage


const subLinkPage = (link, selectedWorkOrder, loadingTable, workOrderLogs, mqttMessage, userInfo, selectedOrganization, getSelectedWorkOrder ) => {
    const allLinks = {
        details: { link: "details", content: <RightDrawerSelectedWODetailsPage selectedWorkOrder={selectedWorkOrder} selectedOrganization={selectedOrganization} getSelectedWorkOrder={getSelectedWorkOrder} />},
        messages: { link: "messages", content: <MessagingPage chatRoomId={selectedWorkOrder.chatroom_uuid} selectedWorkOrder={selectedWorkOrder} mqttMessage={mqttMessage} userInfo={userInfo} />},
        activity_logs: { link: "activity_logs", content: (
            <div className='w-full mt-4 p-4 bg-slate-50 dark:bg-slate-800/50 rounded-xl mb-4 overflow-scroll '>
                <LogsTables data={workOrderLogs} rowsPerPage={10} loadingTable={loadingTable} />
            </div>
        )
        },
    };

      return (
        <AnimatePresence mode='wait'>
        <motion.div
        key={allLinks[link].link}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {allLinks[link].content}
        </motion.div>
      </AnimatePresence>
    )           
}