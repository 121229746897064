
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import { userInfo } from "../../../atoms/userStates";
import { useRecoilState } from "recoil";
import { putData } from "../../../ApiCalls/DataApis";
import { apiLink } from "../../../ApiCalls/ApisVariables";
import toast from "react-hot-toast";

const views = [
  { 
    name: "To Do View", 
    icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
            <path xmlns="http://www.w3.org/2000/svg" d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm120-80v-560H200v560h120Zm80 0h360v-560H400v560Zm-80 0H200h120Z"/>
        </svg>, 
    id: "todo_view" },
  { 
    name: "Roadmap View", 
    icon: 
    <svg fill="none" viewBox="0 0 17 18" aria-hidden="true" className="w-[17px] h-[17px]">
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7.667 12.333H4.333m6.45-6.666h-6.45M12.667 9h-6.42M1 12.5v-7c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093C2.9 1.5 3.6 1.5 5 1.5h7c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.092 1.093C16 3.4 16 4.1 16 5.5v7c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C14.1 16.5 13.4 16.5 12 16.5H5c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C1 14.6 1 13.9 1 12.5Z"></path>
    </svg>, 
    // icon: 
    //     <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5 -rotate-90' viewBox="0 -960 960 960"  fill="currentColor">
    //         <path xmlns="http://www.w3.org/2000/svg" d="M180-160q-25 0-42.5-17.5T120-220v-120q0-25 17.5-42.5T180-400q25 0 42.5 17.5T240-340v120q0 25-17.5 42.5T180-160Zm200-240q-25 0-42.5-17.5T320-460v-80q0-25 17.5-42.5T380-600q25 0 42.5 17.5T440-540v80q0 25-17.5 42.5T380-400Zm200-200q-25 0-42.5-17.5T520-660v-80q0-25 17.5-42.5T580-800q25 0 42.5 17.5T640-740v80q0 25-17.5 42.5T580-600Zm200 440q-25 0-42.5-17.5T720-220v-520q0-25 17.5-42.5T780-800q25 0 42.5 17.5T840-740v520q0 25-17.5 42.5T780-160Z"/>
    //     </svg>, 
    id: "table_view" },
  // { 
  //   name: "Table View", 
  //   icon: 
  //       <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
  //           <path d="M320-600q-17 0-28.5-11.5T280-640q0-17 11.5-28.5T320-680h480q17 0 28.5 11.5T840-640q0 17-11.5 28.5T800-600H320Zm0 160q-17 0-28.5-11.5T280-480q0-17 11.5-28.5T320-520h480q17 0 28.5 11.5T840-480q0 17-11.5 28.5T800-440H320Zm0 160q-17 0-28.5-11.5T280-320q0-17 11.5-28.5T320-360h480q17 0 28.5 11.5T840-320q0 17-11.5 28.5T800-280H320ZM160-600q-17 0-28.5-11.5T120-640q0-17 11.5-28.5T160-680q17 0 28.5 11.5T200-640q0 17-11.5 28.5T160-600Zm0 160q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440Zm0 160q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360q17 0 28.5 11.5T200-320q0 17-11.5 28.5T160-280Z"/>
  //       </svg>, 
  //   id: "table_view" },
  { 
    name: "Calendar View", 
    icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
            <path xmlns="http://www.w3.org/2000/svg" d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-40q0-17 11.5-28.5T280-880q17 0 28.5 11.5T320-840v40h320v-40q0-17 11.5-28.5T680-880q17 0 28.5 11.5T720-840v40h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z"/>
        </svg>, 
    id: "calendar_view" },
  { 
    name: "Kanban View", 
    icon: 
        <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
            <path xmlns="http://www.w3.org/2000/svg" d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Zm120 80q-17 0-28.5 11.5T280-640v320q0 17 11.5 28.5T320-280q17 0 28.5-11.5T360-320v-320q0-17-11.5-28.5T320-680Zm320 0q-17 0-28.5 11.5T600-640v240q0 17 11.5 28.5T640-360q17 0 28.5-11.5T680-400v-240q0-17-11.5-28.5T640-680Zm-160 0q-17 0-28.5 11.5T440-640v120q0 17 11.5 28.5T480-480q17 0 28.5-11.5T520-520v-120q0-17-11.5-28.5T480-680Z"/>
        </svg>,  
    id: "kanban_view" 
  },
  // { 
  //   name: "Workload View", 
  //   icon: 
  //       <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor">
  //           <path xmlns="http://www.w3.org/2000/svg" d="M418-340q24 24 62 23.5t56-27.5l169-253q9-14-2.5-25.5T677-625L424-456q-27 18-28.5 55t22.5 61Zm62-460q36 0 71 6t68 19q16 6 34 22.5t10 31.5q-8 15-36 20t-45-1q-25-9-50.5-13.5T480-720q-133 0-226.5 93.5T160-400q0 42 11.5 83t32.5 77h552q23-38 33.5-79t10.5-85q0-26-4.5-51T782-504q-6-17-2-33t18-27q13-10 28.5-6t21.5 18q15 35 23 71.5t9 74.5q1 57-13 109t-41 99q-11 18-30 28t-40 10H204q-21 0-40-10t-30-28q-26-45-40-95.5T80-400q0-83 31.5-155.5t86-127Q252-737 325-768.5T480-800Zm7 313Z"/>
  //       </svg>, 
  //   id: "workload_view" },
];

const WorkOrderViewSelector = ({setCurrentView}) => {
  const [selectedView, setSelectedView] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams()
  const [user, setUser] = useRecoilState(userInfo)

  useEffect(() => {
    if(user ){
      if(user.workorder_view_name){
        const findView = (viewId) => views.find(view => view.id === viewId);
        const currentSelectedView = findView(user.workorder_view_name);
        setSelectedView(currentSelectedView)
        setCurrentView(currentSelectedView.id)
      }else{
        setSelectedView(views[0])
        setCurrentView(views[0].id)
      }
    }
  },[user])

  



  const handleSelect = (view) => {
    setSelectedView(view);
    // setSearchParams({})
    setCurrentView(view.id)
    setIsOpen(false);

      try{
        putData(apiLink + '/v1/users/workorder_view', 
        {'workorder_view_name': view.id})
          .then(async response => {
            const data = await response.json();
            if(response.ok){
                if(data && data.data){
                    // console.log('User Info?',data.data[0])
                    setUser(data.data[0])
                }
            }
            else{
              try{
                toast.error(data.message)
              }catch (e){
                toast.error(e)
              }
            }
          })

    }catch (e) {
        toast.error(e)
    }

  };

  

  return (
    <div className='flex '>
      {selectedView && selectedView.id && (
        views.map((view, i) => (
          <button key={view.id} className={`group text-blue-600 border border-gray-200 dark:border-gray-700  hover:bg-gray-100 dark:hover:bg-gray-700 h-7 w-7 flex justify-center items-center ${selectedView.id == view.id  && 'bg-gray-200/80 dark:bg-gray-700/60'} ${i === 0 && 'rounded-l-lg rounded-r-none'} ${i === 3 && 'rounded-r-lg rounded-l-none'} ${i != 0 && i != 3 &&  'border-l-0 border-r-0 rounded-none'}`} onClick={() => handleSelect(view)}>
            <div className='transition ease-out delay-100 group-hover:-translate-y-0.5'>
              {view.icon}
            </div>
          </button>
          ))
      )}
      </div>
  );
};

export default WorkOrderViewSelector;
